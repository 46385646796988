import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../stores/store';
import { TwoColumnContainer } from '../../components/appDataContainer/twoColumnContainer';
import { AppDataContainer } from '../../components/appDataContainer';
import {
  exportTransactionReceipt,
  useTransactionDetailQuery,
} from '../../api/endpoints/transactions';
import { useParams } from 'react-router-dom';
import { Box, Card, Typography, useTheme } from '@mui/material';
import { Transaction } from '../../types/commonTypes';
import { formatDateToDay } from '../../utils/funcs/formatDate';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import { theme } from 'theme';
import Button from '@mui/material/Button';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { CustomerStatus } from 'types/enumTypes';
import { TranslateFunc } from 'components/appDataContainer/utility';

const customRenderFunctionsFirst = {
  id: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  payment_window_id: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  token_id: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  // TODO: customer name se mi nedarilo propsat, pada to na ERRORU:  Objects are not valid as a React child
  customer: (value: string, data: Record<string, any>) => {
    return (
      <div>
        <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
          {data.customer?.name}
        </Typography>
      </div>
    );
  },
  date_server: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {formatDateToDay(value, 'YYYY-MM-DD / hh:mm:ss')}
    </Typography>
  ),
};

const customRenderFunctionsSecond = (translateFunction: TranslateFunc) => ({
  amountCurrency: (value: string, data: Record<string, any>) => {
    const amount = data.amount as Transaction['amount'];
    const currency = data.currency as Transaction['currency'];
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center', gap: 1 }}>
        <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
          {amount}
        </Typography>
        <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
          {currency}
        </Typography>
      </Box>
    );
  },
  card_issuer: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  type: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  response_code: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  approval_code: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  status: (value: string) => (
    <Typography fontWeight={'bold'}>
      <AppStatusHighlight displayCircle={false} status={value as CustomerStatus}>
        {translateFunction(`customerStatus.${value}`)}
      </AppStatusHighlight>
    </Typography>
  ),
  rrn: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
  pan: (value: string) => (
    <Typography fontWeight={'bold'} color={theme.palette.text.darkGrey.main}>
      {value}
    </Typography>
  ),
});

export const TransactionDetailPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.transactionDetailPage' });

  const { t: eNums } = useTranslation('common', { keyPrefix: 'enums' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { id: transactionId } = useParams();
  const { data } = useTransactionDetailQuery(ptoId, transactionId || '');
  const theme = useTheme();
  const { spacing } = theme;
  const dateWithoutTime = data?.date_server.slice(0, -8);
  const timeWithoutDate = data?.date_server.substring(10);

  return (
    <>
      <AppBreadcrumbs />
      <Box sx={{ marginTop: spacing(3) }}>
        <AppDataContainer
          titleRow={{
            title: t('pageTitle'),
          }}
        >
          <TwoColumnContainer
            renderData={customRenderFunctionsFirst}
            columns={[
              ['id', 'payment_window_id', 'token_id'],
              ['customer', 'date_server', 'date_server'],
            ]}
            dataObj={data ?? {}}
            translateFunc={(k: string): string => {
              return t(k);
            }}
            isBgGrey
            isBorderBottom
          />
          <TwoColumnContainer
            renderData={customRenderFunctionsSecond(eNums)}
            columns={[
              ['amountCurrency', 'card_issuer', 'type', 'response_code'],
              ['approval_code', 'status', 'rrn', 'pan'],
            ]}
            dataObj={data ?? {}}
            translateFunc={(k: string): string => {
              return t(k);
            }}
          />
        </AppDataContainer>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
          <Card sx={{ padding: 5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <DirectionsBusIcon
                sx={{ color: theme.palette.primary.main, width: '15%', height: '100%' }}
              />
              <Box>
                <Box sx={{ marginBottom: spacing(4) }}>
                  <Typography color={theme.palette.secondary.main}>
                    {dateWithoutTime}
                  </Typography>
                  <Typography
                    fontWeight={'bold'}
                    color={theme.palette.secondary.main}
                    fontSize={40}
                  >
                    {timeWithoutDate}
                  </Typography>
                </Box>
                <Box>
                  <Typography color={theme.palette.text.lightGrey.main}>
                    {t('token_id')}
                  </Typography>
                  <Typography
                    fontWeight={'bold'}
                    color={theme.palette.text.darkGrey.main}
                  >
                    {data?.token_id}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
          <div style={{ marginRight: 'auto', marginLeft: 'auto' }}>
            {/*TODO: TLACITKO NEMA FUNKCI PRO STAZENI*/}
            <Button
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.common.white,
                padding: theme.spacing(2, 8),
              }}
              variant="contained"
              onClick={async () =>
                await exportTransactionReceipt(ptoId, transactionId ?? '')
              }
            >
              <FileDownloadOutlinedIcon sx={{ marginRight: 2 }} />
              {t('downloadReceipt')}
            </Button>
          </div>
        </Box>
      </Box>
    </>
  );
};
