import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { AppTable, AppTableProps } from 'components/appTable';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppDataContainer } from '../../components/appDataContainer';
import { Box, MenuItem, Typography } from '@mui/material';
import { OneColumnContainer } from '../../components/appDataContainer/oneColumnContainer';
import AppButton from '../../components/appButton';
import { NETWORK_URL, TERMINALS_URL } from '../appRoutes/appRoutesConst';
import { useAppSelector } from '../../stores/store';
import { useNavigate, useParams } from 'react-router-dom';
import { useFleetDetailQuery } from '../../api/endpoints/fleet';
import { Fleet } from '../../types/commonTypes';
import { TransportIcon } from '../../components/transportIcon';
import { SimpleAppSelect } from '../../components/form/select/simpleAppSelect';
import { DAYS_OPTIONS } from '../../utils/const/enums';
import { DataGraphContainer } from '../../components/appDataContainer/dataGraphContainer';
import { TapsOverview } from 'components/tapsOverview';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';

const customRenderFunctions = {
  vehicleId: (value: string, data: Record<string, any>) => {
    const vehicleId = data.id as Fleet['id'];
    return <Typography>{vehicleId}</Typography>;
  },
  type: (value: string, data: Record<string, any>) => {
    const type = data.type as Fleet['type'];
    return (
      <Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
          <TransportIcon type={type} />
          <Typography>{type}</Typography>
        </Box>
      </Typography>
    );
  },
  lines: (value: string, data: Record<string, any>) => {
    const lineId = data.line_id as Fleet['line_id'];
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
        <Typography>{lineId}</Typography>
      </Box>
    );
  },
  lineId: (value: string, data: Record<string, any>) => {
    const lineId = data.line_id as Fleet['line'];
    return <Typography>{lineId}</Typography>;
  },
};

export const VehicleDetailPage: React.FC = () => {
  const [terminalsIds, setTerminalsIds] = useState<number[]>([]);
  const { t } = useTranslation('common', { keyPrefix: 'pages.vehicleDetailPage' });
  const { t: tEnums } = useTranslation('common');
  const { ptoId } = useAppSelector((state) => state.user);
  const { id: vehicleId } = useParams();
  const { data } = useFleetDetailQuery(ptoId, vehicleId || '');
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.terminals && data?.terminals.length > 0) {
      const ids = data?.terminals.map((terminal, index) => terminal.id);
      setTerminalsIds(ids || []);
    }
  }, [data?.terminals]);

  const JOURNEY_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('terminalsBox.table.id'),
      },
      {
        field: 'status',
        headerName: t('terminalsBox.table.status'),
        renderCell: (params: GridRenderCellParams<Fleet>) => {
          const { row } = params;

          return (
            <div>
              <AppStatusHighlight status={row.status}>
                <Typography
                  color={row.status === 'active' ? 'success.main' : 'error.main'}
                >
                  {tEnums(`enums.terminalStatus.${row.status}`)}
                </Typography>
              </AppStatusHighlight>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <AppBreadcrumbs />
      </Box>
      <Box display={'flex'} flexDirection={'column'} gap={5}>
        <Box display="flex" gap={5}>
          <AppDataContainer
            titleRow={{
              title: t('infoBox.title'),
              // NO NEED RIGHT NOW
              // buttons: [
              //   <AppButton
              //     startIcon={<DeleteIcon />}
              //     key="delete"
              //     variant="contained"
              //     size="small"
              //     color="error"
              //   >
              //     {t('infoBox.delete')}
              //   </AppButton>,
              //   <AppButton
              //     startIcon={<EditIcon />}
              //     key="add"
              //     variant="contained"
              //     size="small"
              //     color="secondary"
              //   >
              //     {t('infoBox.add')}
              //   </AppButton>,
              // ],
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              {data && (
                <OneColumnContainer
                  renderData={customRenderFunctions}
                  columns={[['vehicleId', 'type', 'lines']]}
                  dataObj={data ?? {}}
                  translateFunc={(k: string): string => {
                    return t(k);
                  }}
                />
              )}
            </Box>
          </AppDataContainer>
          <AppDataContainer
            titleRow={{
              title: t('terminalsBox.title'),
              buttons: [
                <AppButton
                  variant="contained"
                  size="small"
                  color="secondary"
                  key="toTerminalsRoute"
                  onClick={() => navigate(`/${NETWORK_URL}/${TERMINALS_URL}`)}
                >
                  {t('terminalsBox.details')}
                </AppButton>,
              ],
            }}
          >
            {data && (
              <AppTable
                showSettings={false}
                showExportButton={false}
                rows={data?.terminals ?? []}
                columns={JOURNEY_COLUMNS}
                appTitle={''}
              />
            )}
          </AppDataContainer>
        </Box>

        <TapsOverview terminalId={terminalsIds} />
      </Box>
    </>
  );
};
