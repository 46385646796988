import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  exportLinesData,
  LinesFilter,
  useLinesCollectionsQuery,
} from 'api/endpoints/lines';
import AppLink from 'components/appLink/appLink';
import { AppTableProps, AppTable } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LineInterface } from 'types/commonTypes';
import useFilters from 'utils/hooks/filters/useFilters';
import CircleIcon from '@mui/icons-material/Circle';
import { TransportIcon } from 'components/transportIcon';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { LINE_DETAIL_URL } from './appRoutes/appRoutesConst';
import { useAppSelector } from 'stores/store';

const LINE_DETAIL_URLWithoutId = LINE_DETAIL_URL.replace(/\/:id$/, '');

export const LinesPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.linesPage' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { filters, filterValues } = useFilters<LinesFilter>({
    filters: ['VEHICLE_TYPE', 'LINE'],
    namePairs: [
      { filterName: 'VEHICLE_TYPE', queryname: 'vehicle_type' },
      { filterName: 'LINE', queryname: 'line' },
    ],
  });
  const { data } = useLinesCollectionsQuery(ptoId, filterValues);

  const LINE_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      { field: 'id', headerName: t('table.id'), flex: 1 },
      {
        field: 'color',
        headerName: t('table.color'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<LineInterface, LineInterface>) => (
          <CircleIcon sx={{ color: params.row.color }} />
        ),
      },
      {
        field: 'typeAndName',
        headerName: t('table.typeAndName'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<LineInterface, LineInterface>) => {
          const { row } = params;
          return (
            <>
              <TransportIcon type={row.type} />
              <AppLink to={`/fleet/${row.id}`}>{row.name}</AppLink>
            </>
          );
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<LineInterface>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/${LINE_DETAIL_URLWithoutId}/${row.id}`} isGreyLink>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <AppBreadcrumbs />
      {filters}

      <AppTable
        rows={data?.data ?? []}
        columns={LINE_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        exportMenuItems={[
          {
            onClick: async () => await exportLinesData(ptoId, 'xlxs'),
            label: 'XLXS',
          },
          {
            onClick: async () => await exportLinesData(ptoId, 'csv'),
            label: 'CSV',
          },
        ]}
      />
    </div>
  );
};
