import { Navigate, useLocation } from 'react-router';
import { useAppSelector } from 'stores/store';

const ProtectedRoute: React.FC = ({ children }) => {
  const location = useLocation();

  const authenticated = useAppSelector((state) => state.user.isAuthenticated);

  if (authenticated) return <>{children}</>;

  // TODO: For development purposes
  if (window.location.host === 'localhost:3000') {
    return <>{children}</>;
  }

  return <Navigate to="/login" replace state={{ path: location.pathname }} />;
};

export default ProtectedRoute;
