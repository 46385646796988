import React, { useMemo } from 'react';
import { HeatmapLayer, useJsApiLoader } from '@react-google-maps/api';
import Map from '../index';

const googleApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';

type HeatMapPoint = { lat: number; lng: number; weight?: number };
interface HeatMapProps {
  heatPoints: HeatMapPoint[];
}

// [
//   { location: new google.maps.LatLng(37.782, -122.447), weight: 1 },
//   { location: new google.maps.LatLng(37.782, -122.445), weight: 5 },
//   { location: new google.maps.LatLng(37.782, -122.443), weight: 3 },
// ];

const HeatMap: React.FC<HeatMapProps> = ({ heatPoints }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey,
    libraries: ['visualization'],
  });

  const data = useMemo(() => {
    if (isLoaded && heatPoints.length > 0) {
      return heatPoints?.map((point) => ({
        location: new google.maps.LatLng(point.lat, point.lng),
        weight: point.weight || 1,
      }));
    }
  }, [isLoaded, heatPoints]);

  return isLoaded ? (
    <Map>
      {isLoaded ? <HeatmapLayer data={data || []} options={{ radius: 50 }} /> : <></>}
    </Map>
  ) : (
    <></>
  );
};

export default React.memo(HeatMap);
