import {
  Container,
  IconButton,
  Box,
  useTheme,
  styled,
  Drawer,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { Outlet } from 'react-router';
import useLanguageSelect from 'utils/hooks/useLanguageSelect';
import switchioLogo from 'assets/images/switchio_blue_small.svg';
import masterCardLogo from 'assets/images/masterCardLogo.svg';
import masterCardLogoCollapsed from 'assets/images/masterCardLogoCollapsed.svg';
import AppLink from 'components/appLink/appLink';
import useMediaQuery from '@mui/material/useMediaQuery';
import { StandardNavigation } from './standardNavigation/standardNavigation';
import { UserAvatar } from './userAvatar';
import { SearchBox } from './searchBox';
import { NotificationBell } from './notificationBell';
import { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import InfoIcon from '@mui/icons-material/Info';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import { useTranslation } from 'react-i18next';
import { SimpleAppSelect } from '../form/select/simpleAppSelect';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../stores/store';
import { setUserPto } from '../../stores/slices/userSlice';
import { PTO } from '../../types/commonTypes';

const DRAWER_WIDTH = 430;
const DRAWER_WIDTH_COLLAPSED = 104;

export interface NavbarField {
  icon?: any;
  url?: string;
  label: string;
  children?: NavbarField[];
  isCollapsed?: boolean;
}

const NavBar = styled('div')<{ isCollapsed: boolean }>(
  ({ theme: { spacing, palette }, isCollapsed }) => {
    const DRAWER_WIDTH_MAIN = isCollapsed ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH;
    return {
      position: 'sticky',
      top: 0,
      padding: spacing(2, 0),
      backgroundColor: palette.common.white,
      zIndex: 10,
      width: `calc(100% - ${DRAWER_WIDTH_MAIN}px)`,
      transition: `all .2s ease-in-out`,
      marginLeft: `${DRAWER_WIDTH_MAIN}px`,
    };
  },
);

const AppWrapper = styled('div')(({ theme: { palette } }) => ({
  minHeight: '100vh',
  backgroundColor: palette.background.lightGrey.main,
  display: 'flex',
  flexDirection: 'column',
}));

const HelpAndFeedback = styled('div')<{ isCollapsed: boolean }>(
  ({ theme: { spacing, typography }, isCollapsed }) => ({
    display: 'flex',
    justifyContent: isCollapsed ? 'center' : 'start',
    gap: spacing(2),
    marginTop: 'auto',
    margin: isCollapsed ? spacing('auto', 'auto', 3) : spacing('auto', 0, 3),
    fontWeight: typography.fontWeightMedium,
    height: spacing(3),
    padding: isCollapsed ? spacing(0, 4, 0, 5) : spacing(0, 4),
    transition: 'all 0.35s ease-in-out',
  }),
);

const HelpAndFeedbackText = styled('div')<{ isCollapsed: boolean }>(
  ({ theme: { palette }, isCollapsed }) => ({
    color: isCollapsed ? 'transparent' : palette.text.black.main,
    whiteSpace: 'nowrap',
  }),
);

const DrawerFooter = styled('div')(({ theme: { spacing, palette } }) => ({
  width: '100%',
  backgroundColor: palette.primary.dark,
  padding: spacing(4.25),
  display: 'flex',
  flexDirection: 'column',
  maxHeight: spacing(12.5),
  columnGap: spacing(1.25),
  alignItems: 'start',
}));

const DrawerFooterHeading = styled('div')(
  ({ theme: { spacing, palette, typography } }) => ({
    color: palette.secondary.main,
    fontWeight: typography.fontWeightBold,
    fontSize: spacing(4),
  }),
);

const DrawerFooterContentWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: spacing(2.5),
}));

const ConfirmationNumberIconWrapper = styled('div')(
  ({ theme: { spacing, palette } }) => ({
    backgroundColor: palette.terniary.main,
    padding: spacing(0.625),
    borderRadius: spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }),
);

const StyledIconButton = styled(IconButton)(({ theme: { spacing, palette } }) => ({
  width: spacing(4),
  height: spacing(4),
  color: palette.common.white,
  backgroundColor: palette.text.lightGrey.main,
  '&:hover': {
    backgroundColor: palette.text.lightGrey.dark,
  },
}));

const StyledConfirmationNumberOutlinedIcon = styled(ConfirmationNumberOutlinedIcon)(
  ({ theme: { palette } }) => ({
    color: palette.common.white,
  }),
);
const DUMMY_SELECT_ITEMS: PTO[] = [
  { id: 0, label: 'VGF Frankfurt a. M.' },
  { id: 1, label: 'PTO1' },
  { id: 2, label: 'PTO2' },
  { id: 3, label: 'PTO3' },
  { id: 4, label: 'PTO4' },
  { id: 5, label: 'PTO5' },
  { id: 6, label: 'PTO6' },
  { id: 7, label: 'PTO7' },
];

const AuthenticatedLayout: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const languageSelect = useLanguageSelect({ isMobile });
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { t } = useTranslation('common', { keyPrefix: '' });

  const handleToggleCollapsed = () => {
    setIsCollapsed((prev) => !prev);
  };

  const { ptoId } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const handleChange = (e: SelectChangeEvent<unknown>) => {
    dispatch(setUserPto(e.target.value as number));
  };
  return (
    <AppWrapper>
      <Drawer
        sx={{
          width: DRAWER_WIDTH,
          flexShrink: 0,
          position: 'relative',
          '& .MuiDrawer-paper': {
            width: isCollapsed ? DRAWER_WIDTH_COLLAPSED : DRAWER_WIDTH,
            transition: `width .2s ease-in-out`,
            boxSizing: 'border-box',
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Container>
          <AppLink
            style={{
              display: 'flex',
              justifyContent: 'center',
              padding: isCollapsed ? 20 : 0,
            }}
            to="/"
          >
            <img src={isCollapsed ? masterCardLogoCollapsed : masterCardLogo} />
          </AppLink>
          <StandardNavigation
            handleToggleCollapsed={handleToggleCollapsed}
            languageSelect={languageSelect}
            isCollapsed={isCollapsed}
          />
        </Container>

        <HelpAndFeedback isCollapsed={isCollapsed}>
          <InfoIcon />{' '}
          <HelpAndFeedbackText isCollapsed={isCollapsed}>
            {t('components.avatar.helpAndFeedback')}
          </HelpAndFeedbackText>
        </HelpAndFeedback>

        <DrawerFooter>
          {!isCollapsed ? (
            <>
              <img width={82} height={14} src={switchioLogo} />
              <DrawerFooterContentWrapper>
                <DrawerFooterHeading>
                  {t('navigation.transportPortal')}
                </DrawerFooterHeading>
                <StyledIconButton
                  onClick={() => {
                    setIsCollapsed(!isCollapsed);
                  }}
                >
                  <ExpandLessIcon />
                </StyledIconButton>
              </DrawerFooterContentWrapper>
            </>
          ) : (
            <ConfirmationNumberIconWrapper>
              <StyledConfirmationNumberOutlinedIcon />
            </ConfirmationNumberIconWrapper>
          )}
        </DrawerFooter>
      </Drawer>
      <NavBar isCollapsed={isCollapsed}>
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media screen and (min-width: 1200px)': {
              maxWidth: '100%',
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SearchBox />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2.5 }}>
            <SimpleAppSelect value={ptoId} onChange={handleChange}>
              {DUMMY_SELECT_ITEMS?.map((selectItem, index) => (
                <MenuItem value={selectItem.id} key={index}>
                  {selectItem.label}
                </MenuItem>
              ))}
            </SimpleAppSelect>
            <NotificationBell />
            {languageSelect}
            <UserAvatar />
          </Box>
        </Container>
      </NavBar>
      <Container
        sx={{
          marginTop: 5,
          height: '100%',
          marginLeft: `${DRAWER_WIDTH}px`,
          width: `calc(100% - ${DRAWER_WIDTH}px)`,
        }}
      >
        <Outlet />
      </Container>
    </AppWrapper>
  );
};

export default AuthenticatedLayout;
