import { api } from '../index';
import { ClearingSummary, Transaction } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';

const getClearingCollection = async (ptoId: number) => {
  const response = await api.get<ApiResponse<Transaction[]>>(`/pto/${ptoId}/paywindow`);
  return response.data;
};

export const useClearingCollectionsQuery = (ptoId: number) =>
  useQuery(['getPaymentWindows', ptoId], () => getClearingCollection(ptoId));

const getClearingSummary = async (ptoId: number, date: string) => {
  const response = await api.get<ClearingSummary>(
    `/pto/${ptoId}/paywindow/clearing/${date}`,
  );
  return response.data;
};

export const useClearingSummaryQuery = (ptoId: number, date: string) =>
  useQuery(['getPaymentWindows', ptoId, date], () => getClearingSummary(ptoId, date));
