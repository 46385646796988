import { api } from '../index';
import { LineInterface } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from 'utils/funcs/downloadFile';

export interface LinesFilter {
  vehicle_type: string;
  line: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

const getLinesCollection = async (ptoId: number, params: Partial<LinesFilter>) => {
  const response = await api.get<ApiResponse<LineInterface[]>>(`/pto/${ptoId}/line`, {
    params,
  });
  return response.data;
};

export const useLinesCollectionsQuery = (ptoId: number, filterParams = {}) =>
  useQuery(['getLines', ptoId, filterParams], () =>
    getLinesCollection(ptoId, filterParams),
  );

const getLineDetail = async (ptoId: number, lineId: string) => {
  const response = await api.get<LineInterface>(`/pto/${ptoId}/line/${lineId}`);
  return response.data;
};

export const useLineDetailQuery = (ptoId: number, lineId: string) =>
  useQuery(['getLineDetail', ptoId, lineId], () => getLineDetail(ptoId, lineId));

export const exportLinesData = async (
  ptoId: number,
  type: FileType,
  filterParams = {},
) => {
  return await getFile(`/pto/${ptoId}/line/export/${type}`, type, 'Lines', filterParams);
};
