import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  exportStopsData,
  StopsFilter,
  useStopsCollectionsQuery,
} from 'api/endpoints/stops';
import AppLink from 'components/appLink/appLink';
import { AppTableProps, AppTable } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stop } from 'types/commonTypes';
import useFilters from 'utils/hooks/filters/useFilters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TransportIcon } from 'components/transportIcon';
import { useAppSelector } from 'stores/store';

export const StopsPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.stopsPage' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { filters, filterValues } = useFilters<StopsFilter>({
    filters: ['VEHICLE_TYPE', 'LINE'],
    namePairs: [
      { filterName: 'VEHICLE_TYPE', queryname: 'vehicle_type' },
      { filterName: 'LINE', queryname: 'line' },
    ],
  });
  const { data } = useStopsCollectionsQuery(ptoId, filterValues);

  const STOPS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      { field: 'id', headerName: t('table.id'), flex: 1 },
      {
        field: 'name',
        headerName: t('table.name'),
        flex: 1,
      },
      {
        field: 'lines',
        headerName: t('table.lines'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Stop, Stop>) => {
          const { row } = params;
          return row.lines.map((l, index) => {
            if (index > 2) return null;
            if (index > 1) return <Typography>{`+${row.lines.length - 2}`}</Typography>;
            return (
              <Typography
                key={index}
                sx={{ marginRight: 1, display: 'flex', alignContent: 'center' }}
              >
                <TransportIcon type={l.type} />
                {/* TODO: ADD FLEET DETAIL URL */}
                <AppLink isGreyLink={true} to={``}>
                  {l.name}
                </AppLink>
              </Typography>
            );
          });
        },
      },
      {
        field: 'location',
        headerName: t('table.location'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Stop, Stop>) => {
          const { row } = params;
          return (
            <Typography component="div">
              {`${row.name} ${row.address} `}{' '}
              <Typography component="div">{`${row.gps.lat}, ${row.gps.lng}`}</Typography>
            </Typography>
          );
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<Stop>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink isGreyLink to={`/stop/${row.id}`}>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <AppBreadcrumbs />
      {filters}
      <AppTable
        rows={data?.data ?? []}
        columns={STOPS_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        exportMenuItems={[
          {
            onClick: async () => await exportStopsData(ptoId, 'xlxs'),
            label: 'XLXS',
          },
          {
            onClick: async () => await exportStopsData(ptoId, 'csv'),
            label: 'CSV',
          },
        ]}
      />
    </div>
  );
};
