import { removeFromLocalStorage } from 'localStorage';
// import { useAppDispatch } from 'stores/store';

const useAuthentication = () => {
  // const dispatch = useAppDispatch();

  const logOut = async () => {
    // dispatch(changeAuthenticatedState(false));
    removeFromLocalStorage({ key: 'token' });
    // await logout();
  };
  return [logOut];
};

export default useAuthentication;
