import React, { PropsWithChildren } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';

const containerStyle = {
  height: '400px',
  width: '800px',
};

const googleApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY || '';

const Map: React.FC<
  PropsWithChildren<{
    center?: { lat: number; lng: number };
    children: any;
  }>
> = ({
  children,
  center = {
    lat: -3.745,
    lng: -38.523,
  },
}) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey,
    libraries: ['visualization'],
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={10}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {children}
    </GoogleMap>
  ) : (
    <></>
  );
};

export default React.memo(Map);
