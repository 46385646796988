import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  exportFleetsData,
  FleetFilter,
  useFleetsCollectionsQuery,
} from 'api/endpoints/fleet';
import AppLink from 'components/appLink/appLink';
import { AppTableProps, AppTable } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Fleet } from 'types/commonTypes';
import useFilters, { FilterTypes } from 'utils/hooks/filters/useFilters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TransportIcon } from 'components/transportIcon';
import { Typography } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

const FILTERS: FilterTypes[] = ['TERMINAL_ID', 'VEHICLE_TYPE'];
import { VEHICLE_DETAIL_URL } from './appRoutes/appRoutesConst';
import { useAppSelector } from 'stores/store';

export const FleetPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.fleetsPage' });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { filters, filterValues } = useFilters<FleetFilter>({
    filters: FILTERS,
    namePairs: [
      { filterName: 'VEHICLE_TYPE', queryname: 'vehicle_type' },
      { filterName: 'TERMINAL_ID', queryname: 'terminal_id' },
    ],
  });

  const VEHICLE_DETAIL_URLWithoutId = VEHICLE_DETAIL_URL.replace(/\/:id$/, '');

  const { data } = useFleetsCollectionsQuery(ptoId, filterValues);

  const FLEETS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      { field: 'id', headerName: t('table.id'), flex: 1 },
      {
        field: 'type',
        headerName: t('table.type'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Fleet, Fleet>) => {
          const { row } = params;
          return (
            <>
              {' '}
              <TransportIcon type={row.type} />
              {tEnums(`transportType.${row.type}`)}
            </>
          );
        },
      },
      {
        field: 'line',
        headerName: t('table.line'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Fleet, Fleet>) => {
          const { row } = params;
          if (row.line.name.length < 1) return '-';
          return <AppLink to={`/line/${row.line.id}`}>{row.line.name}</AppLink>;
        },
      },
      {
        field: 'terminal',
        headerName: t('table.terminal'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Fleet, Fleet>) => {
          const { row } = params;
          if (row.terminals?.length < 1)
            return (
              <Typography
                color="error"
                component="div"
                sx={{ display: 'flex', alignContent: 'center' }}
              >
                <WarningIcon />{' '}
                <Typography sx={{ marginLeft: 1 }}>{t('table.notAssigned')}</Typography>
              </Typography>
            );

          return (
            <>
              {row.terminals?.map((term, index) => (
                <AppLink key={index} to={`/terminal/${term.id}`}>
                  {term.id}
                </AppLink>
              ))}
            </>
          );
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<Fleet>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/${VEHICLE_DETAIL_URLWithoutId}/${row.id}`}>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <AppBreadcrumbs />
      {filters}
      <AppTable
        rows={data?.data ?? []}
        columns={FLEETS_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        exportMenuItems={[
          {
            onClick: async () => await exportFleetsData(ptoId, 'xlxs', filterValues),
            label: 'XLXS',
          },
          {
            onClick: async () => await exportFleetsData(ptoId, 'csv', filterValues),
            label: 'CSV',
          },
        ]}
      />
    </div>
  );
};
