import AppRoutes from 'pages/appRoutes/appRoutes';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'theme';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import { store } from 'stores/store';
import useDomEvents from 'utils/hooks/useDomEvents';
import { useEffect } from 'react';
import useAuthentication from 'utils/hooks/useAuthentication';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const TestComponent: React.FC = () => {
  const [logout] = useAuthentication();
  useEffect(() => {
    return () => {
      logout();
    };
  }, []);
  return <></>;
};

function App() {
  const eventHandler = useDomEvents();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <>
      <CssBaseline />
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <TestComponent />
            <AppRoutes />
          </ThemeProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Provider>
    </>
  );
}

export default App;
