/* eslint-disable react/react-in-jsx-scope */
import { Typography, useTheme } from '@mui/material';
import { useRoutesObject } from 'pages/appRoutes/appRoutes';
import { NAVBAR_LABELS } from 'pages/appRoutes/appRoutesConst';
import React from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbData } from 'use-react-router-breadcrumbs';

interface BreadCrumbsProps {
  customBreadCrumbs?: { index: number; breadCrumbLabel: string; route: string }[];
}

const returnCorrectBreadCrumb = (
  customBreadCrumbs: BreadCrumbsProps['customBreadCrumbs'],
  breadCrumbIndex: number,
  breadCrumbItem: BreadcrumbData<string>,
  isLastBreadCrumb: boolean,
) => {
  const { breadcrumb } = breadCrumbItem;
  const foundCustomBreadCrumb = customBreadCrumbs?.find(
    (customBread) => customBread.index === breadCrumbIndex,
  );

  if (isLastBreadCrumb) {
    return (
      <Typography variant="h4" component="span">
        {foundCustomBreadCrumb ? foundCustomBreadCrumb.breadCrumbLabel : breadcrumb}
      </Typography>
    );
  } else {
    return (
      <>
        <Link
          style={{ textDecoration: 'none' }}
          to={foundCustomBreadCrumb ? foundCustomBreadCrumb.route : breadCrumbItem.key}
        >
          {' '}
          <Typography color="text.lightGrey.main" variant="h4" component="span">
            {foundCustomBreadCrumb ? foundCustomBreadCrumb.breadCrumbLabel : breadcrumb}
          </Typography>
        </Link>{' '}
        <Typography sx={{ mx: 1 }} color="primary.main" variant="h4" component="span">
          /
        </Typography>
      </>
    );
  }
};

export const AppBreadcrumbs: React.FC<BreadCrumbsProps> = ({
  customBreadCrumbs = [],
}) => {
  const { PEOPLE_LABEL, ACTIVITY_LABEL, PAYMENTS_LABEL } = NAVBAR_LABELS;
  const routesObjec = useRoutesObject();
  const breadcrumbs = useBreadcrumbs(routesObjec, {
    excludePaths: ['/', PEOPLE_LABEL, ACTIVITY_LABEL, PAYMENTS_LABEL],
  });

  return (
    <>
      {breadcrumbs.map((breadCrumbItem, index) => {
        const { breadcrumb } = breadCrumbItem;

        return (
          <React.Fragment key={index}>
            {returnCorrectBreadCrumb(
              customBreadCrumbs,
              index,
              breadCrumbItem,
              index === breadcrumbs.length - 1,
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
