import AppButton from 'components/appButton';
import { AppDataContainer } from 'components/appDataContainer';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { TwoColumnContainer } from 'components/appDataContainer/twoColumnContainer';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateToDay } from 'utils/funcs/formatDate';
import { useNavigate, useParams } from 'react-router-dom';
import { clearToken, useTokenDetailQuery } from 'api/endpoints/tokens';
import HeatMap from 'components/maps/HeatMap';
import { Box, Typography, useTheme } from '@mui/material';
import { AppTable, AppTableProps } from '../../components/appTable';
import { useAppSelector } from '../../stores/store';
import { usePaymentWindowsCollectionsQuery } from '../../api/endpoints/paymentWindows';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { PaymentWindow } from '../../types/commonTypes';
import { PaymentWindowStatus } from '../../components/paymentWindowStatus';
import AppLink from '../../components/appLink/appLink';
import { PAYMENT_WINDOW_DETAIL_URL, TOKEN_DETAIL_URL } from '../appRoutes/appRoutesConst';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  ACTIVITY_URL,
  TAPS_URL,
  JOURNEYS_URL,
  PAYMENTS_URL,
  PAYMENT_WINDOWS_URL,
} from '../appRoutes/appRoutesConst';

const customeRenderFunctions = {
  last_tap: (key: string) => formatDateToDay(key),
  reg_date: (key: string) => formatDateToDay(key),
};

const LAST_X_DAYS = 30;

export const TokenDetailPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.tokenDetailPage' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { id: tokenId } = useParams();
  const { data } = useTokenDetailQuery(ptoId, tokenId || '');
  const paymentData = usePaymentWindowsCollectionsQuery(ptoId, tokenId || '');
  const [showWarning, setShowWarning] = useState(data?.debt?.in_debt ?? false);
  const { palette } = useTheme();
  const navigate = useNavigate();

  const handleClearDebt = async () => {
    const response = await clearToken(ptoId, tokenId ?? '');
    if (response.status > 199 && response.status < 300) setShowWarning(false);
  };

  const PAYMENT_WINDOWS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'amount',
        headerName: t('table.amountAndCurrency'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          return (
            <Typography
              fontWeight={'bold'}
              color={palette.text.darkGrey.main}
            >{`${params.row.amount} ${params.row.currency}`}</Typography>
          );
        },
      },
      {
        field: 'status',
        headerName: t('table.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          const { row } = params;
          return <PaymentWindowStatus status={row.status} />;
        },
      },
      {
        field: 'tokenID',
        headerName: t('table.id'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          return (
            <AppLink to={`/${TOKEN_DETAIL_URL.replace(/\/:id$/, '')}/${params.row.id}`}>
              {params.row.id}
            </AppLink>
          );
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink
                  to={`/${PAYMENT_WINDOW_DETAIL_URL.replace(/\/:id$/, '')}/${row.id}`}
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <Box sx={{ marginBottom: 2 }}>
        <AppBreadcrumbs />
      </Box>
      <AppDataContainer
        collapseContent={{
          checked: showWarning,
          content: [
            t('tokenDebt'),
            `${data?.debt?.total || ''} ${data?.debt?.currency || ''}`,
          ],
          button: { label: t('payDebt'), onClick: () => handleClearDebt() },
        }}
        titleRow={{
          title: t('pageTitle'),
        }}
      >
        <TwoColumnContainer
          renderData={customeRenderFunctions}
          columns={[
            ['id'],
            { key: 'customer', keysArray: ['name', 'status', 'last_tap', 'reg_date'] },
          ]}
          dataObj={data ?? {}}
          translateFunc={(k: string): string => {
            return t(k);
          }}
        />
      </AppDataContainer>
      <Box
        sx={{
          marginTop: 8,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          gap: 5,
        }}
      >
        <AppDataContainer
          sx={{ flexBasis: '40%', flexGrow: 1 }}
          titleRow={{
            title: t('tapsCard.title'),
            slimTitle: t('tapsCard.historyRange', { days: LAST_X_DAYS }),
            buttons: [
              <AppButton
                variant="contained"
                color="secondary"
                size="small"
                key={0}
                onClick={() => navigate(`/${ACTIVITY_URL}/${TAPS_URL}`)}
              >
                {t('tapsCard.details')}
              </AppButton>,
            ],
          }}
        >
          {/* TODO: change to received data */}
          <HeatMap heatPoints={[{ lat: 37.782, lng: -122.443, weight: 2 }]} />
        </AppDataContainer>
        <AppDataContainer
          sx={{ flexBasis: '40%', flexGrow: 1 }}
          titleRow={{
            title: t('journeysCard.title'),
            slimTitle: t('journeysCard.historyRange', { days: LAST_X_DAYS }),
            buttons: [
              <AppButton
                variant="contained"
                color="secondary"
                size="small"
                key={0}
                onClick={() => navigate(`/${ACTIVITY_URL}/${JOURNEYS_URL}`)}
              >
                {t('journeysCard.details')}
              </AppButton>,
            ],
          }}
        ></AppDataContainer>
        <AppDataContainer
          sx={{ flexBasis: '40%', flexGrow: 1 }}
          collapseContent={{
            checked: showWarning,
            content: [
              t('tokenDebt'),
              `${data?.debt?.total || ''} ${data?.debt?.currency || ''}`,
            ],
            button: { label: t('payDebt'), onClick: () => handleClearDebt() },
          }}
          titleRow={{
            title: t('paymentWindowCard.title'),
            buttons: [
              <AppButton
                variant="contained"
                color="secondary"
                size="small"
                key={0}
                onClick={() => navigate(`/${PAYMENTS_URL}/${PAYMENT_WINDOWS_URL}`)}
              >
                {t('paymentWindowCard.details')}
              </AppButton>,
            ],
          }}
        >
          <AppTable
            showSettings={false}
            rows={paymentData?.data?.data ?? []}
            columns={PAYMENT_WINDOWS_COLUMNS}
            appTitle={t('tableTitle')}
          />
        </AppDataContainer>
      </Box>
      {/* {filters}

      <AppTable
        rows={data?.data ?? []}
        columns={CUSTOMER_COLUMNS}
        appTitle={t('tableTitle')}
      /> */}
    </div>
  );
};
