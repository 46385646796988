import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from 'recharts';

export interface BarChartData {
  value: number;
  name: string;
}

export interface BarChartProps {
  data: BarChartData[];
  customYpsilonTickFomatter?: (value: any, index: number) => string;
  barAreaWidth?: number;
  barAreaHeight?: number;
  xAxisLabel?: string;
  xAxisUnit?: string;
  yAxisLabel?: string;
  yAxisUnit?: string;
}

const RenderGradientBar: React.FC = (props: any) => {
  const pixel = 10;
  const key = 'Gradient1';
  const { x, y, width, height } = props;

  return (
    <svg x={x} y={y} fill="none" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <linearGradient id={key} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor="#EC4233" />
          <stop offset="100%" stopColor={'#001145'} />
        </linearGradient>
      </defs>
      <path
        // Start at x,y
        // move down by set amount of pixels
        // create rounded corner
        // move horizontaly of width amount minus amount of pixels
        // create rounded corner
        // move verticaly for height minus set amount of pixels
        // move back horizontaly for width
        // connect current point to start point
        d={`M ${0},${0}
        m0,${pixel}
        q0,-${pixel} ${pixel},-${pixel}
        h${width - 2 * pixel}
        q${pixel},0 ${pixel},${pixel}
        v${height - pixel} h-${width} z`}
        fill={`url(#${key})`}
      />
    </svg>
  );
};

export const AppBarChart: React.FC<BarChartProps> = ({
  barAreaHeight = 300,
  barAreaWidth = 730,
  data,
  customYpsilonTickFomatter,
  xAxisLabel,
  xAxisUnit,
  yAxisLabel,
  yAxisUnit,
}) => {
  return (
    <>
      <BarChart width={barAreaWidth} height={barAreaHeight} data={data}>
        <XAxis dataKey="name" label={xAxisLabel} unit={xAxisUnit} />
        <YAxis
          label={yAxisLabel}
          unit={yAxisUnit}
          dataKey="value"
          tickFormatter={(value, index) => {
            if (customYpsilonTickFomatter) return customYpsilonTickFomatter(value, index);
            return value;
          }}
        />
        <CartesianGrid strokeDasharray="10" vertical={false} />
        <Bar
          dataKey={'value'}
          shape={<RenderGradientBar />}
          isAnimationActive={true}
          animationDuration={3000}
        />
      </BarChart>
    </>
  );
};
