import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  exportTerminalData,
  TerminalsFilter,
  useTerminalsCollectionsQuery,
} from 'api/endpoints/terminals';
import AppLink from 'components/appLink/appLink';
import { AppTableProps, AppTable } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Terminal } from 'types/commonTypes';
import useFilters from 'utils/hooks/filters/useFilters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAppSelector } from 'stores/store';

export const TerminalsPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.terminalsPage' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { filters, filterValues } = useFilters<TerminalsFilter>({
    filters: ['TERMINAL_ID', 'LOCATION', 'STATUS'],
    namePairs: [
      { filterName: 'TERMINAL_ID', queryname: 'terminal_id' },
      { filterName: 'LOCATION', queryname: 'location' },
      { filterName: 'STATUS', queryname: 'terminal_status' },
    ],
  });
  const { data } = useTerminalsCollectionsQuery(ptoId, filterValues);

  const TOKENS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      { field: 'id', headerName: t('table.id'), flex: 1 },
      { field: 'address', headerName: t('table.address'), flex: 1 },
      { field: 'status', headerName: t('table.status'), flex: 1 },
      { field: 'type', headerName: t('table.type'), flex: 1 },
      { field: 'description', headerName: t('table.description'), flex: 1 },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<Terminal>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/terminal/${row.id}`} isGreyLink>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <AppBreadcrumbs />
      {filters}
      <AppTable
        rows={data?.data ?? []}
        columns={TOKENS_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        exportMenuItems={[
          {
            onClick: async () => await exportTerminalData(ptoId, 'xlxs'),
            label: 'XLXS',
          },
          {
            onClick: async () => await exportTerminalData(ptoId, 'csv'),
            label: 'CSV',
          },
        ]}
      />
    </div>
  );
};
