import AuthenticatedLayout from 'components/layouts/authenticatedLayout';
import NotAuthenticatedLayout from 'components/layouts/notAuthenticatedLayout';
import ProtectedRoute from 'components/protectedRoute';
import { ClearingPage } from 'pages/clearingPage';
import { CustomerDetailPage } from 'pages/detailPages/customerDetailPage';
import { CustomersPage } from 'pages/customersPage';
import DashboardPage from 'pages/dashBoardPage';
import { DenyListPage } from 'pages/denyListPage';
import { FaresPage } from 'pages/faresPage';
import { FleetPage } from 'pages/fleetPage';
import { GeneralPage } from 'pages/generalPage';
import { JourneysPage } from 'pages/journeysPage';
import { LinesPage } from 'pages/linesPage';
import { PaymentWindowsPage } from 'pages/paymentWindowsPage';
import { StopsPage } from 'pages/stopsPage';
import { TapsPage } from 'pages/tapsPage';
import { TerminalsPage } from 'pages/terminalsPage';
import { TokensPage } from 'pages/tokensPage';
import { TransactionsPage } from 'pages/transactionsPage';
import { TransactionsSettingsPage } from 'pages/transactionsSettingsPage';
import { useTranslation } from 'react-i18next';
import { useRoutes } from 'react-router';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import {
  ACTIVITY_URL,
  CLEARING_URL,
  CUSTOMERS_URL,
  DENY_LIST_URL,
  FARES_URL,
  FLEET_URL,
  GENERAL_URL,
  JOURNEYS_URL,
  LINES_URL,
  NAVBAR_LABELS,
  NETWORK_URL,
  NOTIFICATION_URL,
  PAYMENTS_URL,
  PAYMENT_WINDOWS_URL,
  PEOPLE_URL,
  ROOT_URL,
  SETTINGS_URL,
  STOPS_URL,
  TAPS_URL,
  TERMINALS_URL,
  TOKENS_URL,
  TRANSACTIONS_SETTINGS_URL,
  TRANSACTIONS_URL,
  CUSTOMER_DETAIL_URL,
  TOKEN_DETAIL_URL,
  TERMINAL_DETAIL_URL,
  STOP_DETAIL_URL,
  VEHICLE_DETAIL_URL,
  PAYMENT_WINDOW_DETAIL_URL,
  LINE_DETAIL_URL,
  TAP_DETAIL_URL,
  TRANSACTION_DETAIL_URL,
  JOURNEY_DETAIL_URL,
} from './appRoutesConst';
import { TokenDetailPage } from 'pages/detailPages/tokenDetailPage';
import { TerminalDetailPage } from 'pages/detailPages/terminalDetailPage';
import { StopsDetailPage } from 'pages/detailPages/stopsDetailPage';
import { NotificationsPage } from '../notificationsPage';
import { VehicleDetailPage } from '../detailPages/vehicleDetailPage';
import { PaymentWindowDetailPage } from 'pages/detailPages/paymentWindowDetailPage';
import { LineDetailPage } from 'pages/detailPages/lineDetailPage';
import { TapDetailPage } from 'pages/detailPages/tapDetailPage';
import { TransactionDetailPage } from '../detailPages/transactionDetailPage';
import { JourneyDetailPage } from 'pages/detailPages/journeyDetailPage';

export const useRoutesObject = () => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' });

  const {
    PEOPLE_LABEL,
    CUSTOMERS_LABEL,
    ACTIVITY_LABEL,
    TOKENS_LABEL,
    DENY_LIST_LABEL,
    JOURNEYS_LABEL,
    TAPS_LABEL,
    PAYMENTS_LABEL,
    TRANSACTIONS_LABEL,
    PAYMENT_WINDOWS_LABEL,
    CLEARING_LABEL,
    NETWORK_LABEL,
    TERMINALS_LABEL,
    FLEET_LABEL,
    STOPS_LABEL,
    LINES_LABEL,
    SETTINGS_LABEL,
    FARES_LABEL,
    TRANSACTIONS_SETTINGS_LABEL,
    GENERAL_LABEL,
    NOTIFICATION_LABEL,
  } = NAVBAR_LABELS;

  const customerDetail = {
    path: `${CUSTOMER_DETAIL_URL}`,
    breadcrumb: t(CUSTOMER_DETAIL_URL),
    element: (
      <ProtectedRoute>
        <CustomerDetailPage />
      </ProtectedRoute>
    ),
  };
  const tokenDetail = {
    path: `${TOKEN_DETAIL_URL}`,
    breadcrumb: t('breadcrumbs.tokenDetail'),
    element: (
      <ProtectedRoute>
        <TokenDetailPage />
      </ProtectedRoute>
    ),
  };
  const terminalDetail = {
    path: `${TERMINAL_DETAIL_URL}`,
    breadcrumb: t('breadcrumbs.terminalDetail'),
    element: (
      <ProtectedRoute>
        <TerminalDetailPage />
      </ProtectedRoute>
    ),
  };
  const stopDetail = {
    path: `${STOP_DETAIL_URL}`,
    element: (
      <ProtectedRoute>
        <StopsDetailPage />
      </ProtectedRoute>
    ),
  };
  const vehicleDetail = {
    path: `${VEHICLE_DETAIL_URL}`,
    element: (
      <ProtectedRoute>
        <VehicleDetailPage />
      </ProtectedRoute>
    ),
  };
  const paymentWindowDetail = {
    path: `${PAYMENT_WINDOW_DETAIL_URL}`,
    breadcrumb: t('breadcrumbs.paymentWindowDetail'),
    element: (
      <ProtectedRoute>
        <PaymentWindowDetailPage />
      </ProtectedRoute>
    ),
  };
  const lineDetail = {
    path: `${LINE_DETAIL_URL}`,
    element: (
      <ProtectedRoute>
        <LineDetailPage />
      </ProtectedRoute>
    ),
  };
  const tapDetail = {
    path: `${TAP_DETAIL_URL}`,
    element: (
      <ProtectedRoute>
        <TapDetailPage />
      </ProtectedRoute>
    ),
  };
  const transactionDetail = {
    path: `${TRANSACTION_DETAIL_URL}`,
    breadcrumb: t('breadcrumbs.transactionDetail'),
    element: (
      <ProtectedRoute>
        <TransactionDetailPage />
      </ProtectedRoute>
    ),
  };
  const journeyDetail = {
    path: `${JOURNEY_DETAIL_URL}`,
    breadcrumb: t('breadcrumbs.journeyDetail'),
    element: (
      <ProtectedRoute>
        <JourneyDetailPage />
      </ProtectedRoute>
    ),
  };

  const peopleRoutes = {
    path: `${PEOPLE_URL}/`,
    breadcrumb: t(PEOPLE_LABEL),
    children: [
      {
        path: CUSTOMERS_URL,
        breadcrumb: t(CUSTOMERS_LABEL),
        element: (
          <ProtectedRoute>
            <CustomersPage />
          </ProtectedRoute>
        ),
      },
      {
        path: TOKENS_URL,
        breadcrumb: t(TOKENS_LABEL),
        element: (
          <ProtectedRoute>
            <TokensPage />
          </ProtectedRoute>
        ),
      },
      {
        path: DENY_LIST_URL,
        breadcrumb: t(DENY_LIST_LABEL),
        element: (
          <ProtectedRoute>
            <DenyListPage />
          </ProtectedRoute>
        ),
      },
    ],
  };

  const activityRoutes = {
    path: `${ACTIVITY_URL}/`,
    breadcrumb: t(ACTIVITY_LABEL),
    children: [
      {
        path: JOURNEYS_URL,
        breadcrumb: t(JOURNEYS_LABEL),
        element: (
          <ProtectedRoute>
            <JourneysPage />
          </ProtectedRoute>
        ),
      },
      {
        path: TAPS_URL,
        breadcrumb: t(TAPS_LABEL),
        element: (
          <ProtectedRoute>
            <TapsPage />
          </ProtectedRoute>
        ),
      },
    ],
  };

  const paymentRoutes = {
    path: `${PAYMENTS_URL}/`,
    breadcrumb: t(PAYMENTS_LABEL),
    children: [
      {
        path: TRANSACTIONS_URL,
        breadcrumb: t(TRANSACTIONS_LABEL),
        element: (
          <ProtectedRoute>
            <TransactionsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: PAYMENT_WINDOWS_URL,
        breadcrumb: t(PAYMENT_WINDOWS_LABEL),
        element: (
          <ProtectedRoute>
            <PaymentWindowsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: CLEARING_URL,
        breadcrumb: t(CLEARING_LABEL),
        element: (
          <ProtectedRoute>
            <ClearingPage />
          </ProtectedRoute>
        ),
      },
    ],
  };

  const networkRoutes = {
    path: `${NETWORK_URL}/`,
    breadcrumb: t(NETWORK_LABEL),
    children: [
      {
        path: TERMINALS_URL,
        breadcrumb: t(TERMINALS_LABEL),
        element: (
          <ProtectedRoute>
            <TerminalsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: FLEET_URL,
        breadcrumb: t(FLEET_LABEL),
        element: (
          <ProtectedRoute>
            <FleetPage />
          </ProtectedRoute>
        ),
      },
      {
        path: STOPS_URL,
        breadcrumb: t(STOPS_LABEL),
        element: (
          <ProtectedRoute>
            <StopsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: LINES_URL,
        breadcrumb: t(LINES_LABEL),
        element: (
          <ProtectedRoute>
            <LinesPage />
          </ProtectedRoute>
        ),
      },
    ],
  };

  const notificationRoutes = {
    path: `${NOTIFICATION_URL}`,
    breadcrumb: t(NOTIFICATION_LABEL),
    element: (
      <ProtectedRoute>
        <NotificationsPage />
      </ProtectedRoute>
    ),
  };

  const settingsRoutes = {
    path: `${SETTINGS_URL}/`,
    breadcrumb: t(SETTINGS_LABEL),
    children: [
      {
        path: FARES_URL,
        breadcrumb: t(FARES_LABEL),
        element: (
          <ProtectedRoute>
            <FaresPage />
          </ProtectedRoute>
        ),
      },
      {
        path: TRANSACTIONS_SETTINGS_URL,
        breadcrumb: t(TRANSACTIONS_SETTINGS_LABEL),
        element: (
          <ProtectedRoute>
            <TransactionsSettingsPage />
          </ProtectedRoute>
        ),
      },
      {
        path: GENERAL_URL,
        breadcrumb: t(GENERAL_LABEL),
        element: (
          <ProtectedRoute>
            <GeneralPage />
          </ProtectedRoute>
        ),
      },
    ],
  };

  const routesObject: BreadcrumbsRoute[] = [
    {
      path: ROOT_URL,
      element: <AuthenticatedLayout />,
      children: [
        {
          breadcrumb: 'Dashboard',
          index: true,
          element: (
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          ),
        },
        peopleRoutes,
        activityRoutes,
        paymentRoutes,
        networkRoutes,
        // settingsRoutes,
        customerDetail,
        tokenDetail,
        terminalDetail,
        notificationRoutes,
        stopDetail,
        vehicleDetail,
        paymentWindowDetail,
        lineDetail,
        tapDetail,
        transactionDetail,
        journeyDetail,
      ],
    },
  ];
  return routesObject;
};

const AppRoutes = () => {
  const routesObject = useRoutesObject();
  const element = useRoutes(routesObject);
  return (
    <>{element}</>
    // <Routes>
    //   {/* AUTHENTICATED ROUTES */}
    //   <Route element={<AuthenticatedLayout />}>
    //     <Route
    //       path={ROOT_URL}
    //       element={
    //         <ProtectedRoute>
    //           <Dashboard />
    //         </ProtectedRoute>
    //       }
    //     />
    //   </Route>
    //   {/* NOT AUTHENTICATED ROUTES */}
    //   <Route element={<NotAuthenticatedLayout />}></Route>
    // </Routes>
  );
};

export default AppRoutes;
