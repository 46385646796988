import { MenuItem, Box, Typography, styled, useTheme } from '@mui/material';
import { useClearingSummaryQuery } from 'api/endpoints/clearing';
import { TimePeriod } from 'api/endpoints/taps';
import { AppDataContainer } from 'components/appDataContainer';
import { DataGraphContainer } from 'components/appDataContainer/dataGraphContainer';
import AppSelect from 'components/form/select';
import { SimpleAppSelect } from 'components/form/select/simpleAppSelect';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'stores/store';
import { DAYS_OPTIONS } from 'utils/const/enums';

const ImportantDataBox = styled('div')(({ theme: { spacing, palette } }) => ({
  backgroundColor: palette.primary.light,
  padding: spacing(2, 3),
  borderRadius: '8px',
}));

const ImportantDataBoxLargeText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.h5,
}));

const ImportantDataBoxSmallText = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
}));

const SmallText = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
}));

const LargeText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.h3,
}));

interface TapsOverviewProps {
  date: string;
}

const currencies = [{ value: 1, label: 'EUR' }];

export const ClearingOverview: React.FC<TapsOverviewProps> = ({ date }) => {
  const ptoId = useAppSelector((state) => state.user.ptoId);
  const [days, setDays] = useState(DAYS_OPTIONS[DAYS_OPTIONS.length - 1].value);
  const [selectedCurrency, setSelectedCurrency] = useState(1);
  const { t } = useTranslation('common', { keyPrefix: 'components.clearingOverview' });
  const { t: tEnums } = useTranslation('common');
  const theme = useTheme();
  const { spacing } = theme;
  const { data } = useClearingSummaryQuery(
    ptoId,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    date,
  );

  const selectedCurrencyLabel = useMemo(() => {
    return currencies.find((curr) => curr.value === selectedCurrency)?.label;
  }, [selectedCurrency]);

  return (
    <AppDataContainer
      titleRow={{
        title: t('title'),
        // buttons: [
        //   <AppSelect selectVariant="asButton" value={selectedCurrency} key="1">
        //     {currencies.map((currency, index) => (
        //       <MenuItem key={index} value={currency.value}>
        //         {currency.label}
        //       </MenuItem>
        //     ))}
        //   </AppSelect>,
        // ],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: 3,
          justifyContent: 'space-between',
        }}
      >
        {data ? (
          <>
            {' '}
            <Box
              sx={{
                maxWidth: '50%',
                marginY: 'auto',
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box sx={{ marginBottom: spacing(2), marginLeft: spacing(2) }}>
                <LargeText>{`${data.day_summary.total_amount} ${selectedCurrencyLabel}`}</LargeText>
                <SmallText> {t('revenue')}</SmallText>
              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gridGap: spacing(3),
                }}
              >
                <ImportantDataBox>
                  <ImportantDataBoxLargeText>
                    {data.day_summary.total_amount}
                  </ImportantDataBoxLargeText>
                  <ImportantDataBoxSmallText>
                    {t('paymentWindows')}
                  </ImportantDataBoxSmallText>
                </ImportantDataBox>
                <ImportantDataBox>
                  <ImportantDataBoxLargeText>
                    {`${data.day_summary.average_amount} ${selectedCurrencyLabel}`}
                  </ImportantDataBoxLargeText>
                  <ImportantDataBoxSmallText>
                    {t('averageAmount')}
                  </ImportantDataBoxSmallText>
                </ImportantDataBox>
                <ImportantDataBox>
                  <ImportantDataBoxLargeText>
                    {data.day_summary.closed}
                  </ImportantDataBoxLargeText>
                  <ImportantDataBoxSmallText>
                    {t('closedWindows')}
                  </ImportantDataBoxSmallText>
                </ImportantDataBox>
                <ImportantDataBox>
                  <ImportantDataBoxLargeText>
                    {data.day_summary.in_debt}
                  </ImportantDataBoxLargeText>
                  <ImportantDataBoxSmallText>
                    {t('inDebtWindows')}
                  </ImportantDataBoxSmallText>
                </ImportantDataBox>
              </Box>
            </Box>
            <Box sx={{ width: '400px' }}>
              {data?.month_graph && data?.month_graph.length > 0 && (
                <DataGraphContainer
                  dataBoxes={data.month_graph.map((graphItem) => ({
                    value: parseInt(graphItem.amount),
                    // label: formatDateToDay(graphItem.day, 'DD.MM.YYYY'),
                    label: `${graphItem.day}`,
                  }))}
                />
              )}
            </Box>{' '}
          </>
        ) : (
          <Typography sx={{ margin: 'auto' }} component="div">
            {t('noData')}
          </Typography>
        )}
      </Box>
    </AppDataContainer>
  );
};
