import { GridRenderCellParams } from '@mui/x-data-grid-pro';

import { AppTable, AppTableProps } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentWindowDetail, PaymentWindowsStatus } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import { TapInterface } from 'types/commonTypes';
import { Typography, useTheme } from '@mui/material';
import AppLink from 'components/appLink/appLink';
import { TapStatus } from 'components/tapStatus';
import { TransportIcon } from 'components/transportIcon';
import { VEHICLE_DETAIL_URL } from 'pages/appRoutes/appRoutesConst';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { exportTapsData, useTapsCollectionsQuery } from 'api/endpoints/taps';
import { useAppSelector } from 'stores/store';
import { AppDataContainer } from 'components/appDataContainer';
import { TwoColumnContainer } from 'components/appDataContainer/twoColumnContainer';
import { TranslateFunc } from 'components/appDataContainer/utility';
import { usePaymentWindowDetailQuery } from 'api/endpoints/paymentWindows';
import { useParams } from 'react-router';
import { PaymentWindowStatus } from 'components/paymentWindowStatus';

const customeRenderFunctions = (translateFunc: TranslateFunc) => {
  return {
    date_opened: (value: string) =>
      value ? formatDateToDay(value, 'YYYY-MM-DD / hh:mm:ss') : ' -',
    date_closed: (value: string) =>
      value ? formatDateToDay(value, 'YYYY-MM-DD / hh:mm:ss') : ' -',
    status: (value: string) =>
      value ? (
        <PaymentWindowStatus showCircle={false} status={value as PaymentWindowsStatus} />
      ) : (
        ' -'
      ),
    customer: (value: string, data: Record<string, any>) => {
      const dataWithType = data as PaymentWindowDetail;
      return dataWithType?.customer?.name ? dataWithType.customer.name : ' -';
    },
  };
};

export const PaymentWindowDetailPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.paymentWindowsDetailPage' });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { id: paymentWindowId } = useParams();
  const { data } = usePaymentWindowDetailQuery(ptoId, paymentWindowId || '');
  const { data: tapsData } = useTapsCollectionsQuery(ptoId);
  const { spacing } = useTheme();

  const TAPS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'time',
        headerName: t('table.time'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <div>
              <Typography component="div">
                {formatDateToDay(row.time, 'DD.MM.YYYY')}
              </Typography>
              <Typography component="div">
                {formatDateToDay(row.time, 'hh.mm.ss')}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'type',
        headerName: t('table.type'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return <TapStatus label={tEnums(`tapStatus.${row.type}`)} status={row.type} />;
        },
      },
      {
        field: 'vehicle',
        headerName: t('table.vehicle'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;
          return (
            <Typography
              sx={{
                gap: 1,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
              }}
            >
              <TransportIcon type={row.fleet.type} />
              <AppLink
                to={`/${VEHICLE_DETAIL_URL.replace(/\/:id$/, '')}/${row.fleet.id}`}
              >
                {row.fleet.id}
              </AppLink>
            </Typography>
          );
        },
      },
      {
        field: 'line',
        headerName: t('table.line'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <Typography
              sx={{
                gap: 1,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
              }}
            >
              <CircleIcon sx={{ color: params.row.line.color, marginRight: 1 }} />
              <AppLink to={`/line/${row.line.id}`}>{row.line.name}</AppLink>
            </Typography>
          );
        },
      },
      {
        field: 'terminal',
        headerName: t('table.terminal'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;
          return (
            <AppLink to={`/terminal/${row.terminal_detail_id}`}>
              {row.terminal_detail_id}
            </AppLink>
          );
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/tap/${row.id}`}>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <AppBreadcrumbs />
      <AppDataContainer
        titleRow={{
          title: t('pageTitle'),
        }}
        sx={{ marginY: spacing(4) }}
      >
        <TwoColumnContainer
          renderData={customeRenderFunctions(tEnums)}
          columns={[
            ['date_opened', 'date_closed', 'customer', 'status'],
            ['id', 'token_id'],
          ]}
          dataObj={data ?? {}}
          translateFunc={(k: string): string => {
            return t(k);
          }}
        />
      </AppDataContainer>
      <AppTable
        rows={tapsData?.data ?? []}
        columns={TAPS_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        exportMenuItems={[
          {
            onClick: async () => await exportTapsData(ptoId, 'xlxs'),
            label: 'XLXS',
          },
          {
            onClick: async () => await exportTapsData(ptoId, 'csv'),
            label: 'CSV',
          },
        ]}
      />
    </>
  );
};
