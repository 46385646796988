import axios from 'axios';
import { loadFromLocalStorage } from 'localStorage';

const url = `${process.env.REACT_APP_API_KEY}/api/${process.env.REACT_APP_API_VERSION}`;

const apiConfig = {
  baseURL: url,
};

const api = axios.create({ ...apiConfig });
const notAthenticatedApi = axios.create({ ...apiConfig });

api.interceptors.request.use(
  (config) => {
    const token = loadFromLocalStorage({ key: 'token' });
    if (token) {
      config.headers = { ...config.headers, 'AUTH-TOKEN': token };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

export { api, notAthenticatedApi };
