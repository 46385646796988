import {
  DialogTitle,
  Typography,
  IconButton,
  Box,
  useTheme,
  alpha,
  MenuItem,
} from '@mui/material';
import AppButton from 'components/appButton';
import AppTextField from 'components/form/textField';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { WidgetState } from '..';
import { useAppSelector } from 'stores/store';
import { deleteWidget, updateWidget } from 'api/endpoints/widgets';
import AppSelect from 'components/form/select';
import { TimePeriod } from 'api/endpoints/taps';
import { WidgetTypes } from 'types/enumTypes';

interface UpdateWidgetProps {
  handleClose: () => void;
  setWidgetState: React.Dispatch<
    React.SetStateAction<WidgetState<{ time_period?: TimePeriod }>>
  >;
  widgetState: WidgetState<Record<string, any>>;
  updateCallback?: () => void;
}

export const UpdateWidget: React.FC<UpdateWidgetProps> = ({
  handleClose,
  setWidgetState,
  widgetState,
  updateCallback,
}) => {
  const { ptoId } = useAppSelector((state) => state.user);
  const { t } = useTranslation('common', { keyPrefix: 'components.updateWidget' });
  const { t: tEnum } = useTranslation('common', { keyPrefix: 'enums' });
  const theme = useTheme();
  const { spacing } = theme;

  const deleteW = async (ptoId: number, widgetId: number) => {
    const response = await deleteWidget(ptoId, widgetId);
    if (response.status > 199 && response.status < 300) {
      if (updateCallback) updateCallback();
      handleClose();
    }
  };

  const update = async (
    ptoId: number,
    widgetState: WidgetState<{ time_period?: TimePeriod }>,
  ) => {
    const id = widgetState.id;

    const response = await updateWidget(ptoId, id, {
      type: widgetState.type,
      position: widgetState.position,
      name: widgetState.name,
      settings: widgetState.settings,
    });
    if (response.status > 199 && response.status < 300) {
      if (updateCallback) updateCallback();
      handleClose();
    }
  };
  return (
    <>
      {' '}
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="h5">{t('widgetTitle')}</Typography>
        <IconButton key="Settings" onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box sx={{ padding: spacing(3), display: 'flex', flexDirection: 'column', gap: 2 }}>
        <AppTextField
          label={t('customName')}
          value={widgetState.name}
          onChange={(e) => setWidgetState({ ...widgetState, name: e.target.value })}
        />
        {widgetState.type === WidgetTypes['NETWORK_LOAD'] && (
          <AppSelect
            fullWidth
            label={t('chooseTimePeriod')}
            onChange={(e) =>
              setWidgetState({
                ...widgetState,
                settings: {
                  ...widgetState.settings,
                  time_period: e.target.value as TimePeriod,
                },
              })
            }
            value={widgetState.settings.time_period}
          >
            {Object.entries(TimePeriod).map((entry, index) => (
              <MenuItem key={index} value={entry[1]}>
                {tEnum(`dayOptions.${entry[1].toLowerCase()}`)}
              </MenuItem>
            ))}
          </AppSelect>
        )}
        <AppButton
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => deleteW(ptoId, widgetState.id)}
          startIcon={<DeleteIcon />}
        >
          {t('delete')}
        </AppButton>
        <Box sx={{ margin: 'auto' }}>
          <AppButton
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              padding: theme.spacing(2, 8),
              ':hover': {
                backgroundColor: alpha(theme.palette.secondary.main, 0.8),
              },
            }}
            onClick={() => update(ptoId, widgetState)}
            variant="contained"
          >
            {t('confirm')}
          </AppButton>
        </Box>
      </Box>
    </>
  );
};
