import { styled, Typography } from '@mui/material';
import { ReactElement } from 'react';

export type RenderData = Record<
  string,
  // Change typings to use generics => You pass data obj type
  (k: string, data: Record<string, any>) => string | ReactElement | ReactElement[]
>;

export type ColumnConfig = string[] | { key: string; keysArray: string[] };

export type DataObj = Record<string, any>;

export type TranslateFunc = (k: string) => string;

export const ValueKeyWrapper = styled(Typography)(({ theme: { spacing } }) => ({
  margin: spacing(1, 0),
  wordBreak: 'break-all',
})) as typeof Typography;

export const renderKey = (column: ColumnConfig, translateFunc: TranslateFunc) => {
  if (Array.isArray(column)) {
    return column.map((key, index) => (
      <ValueKeyWrapper component="div" key={index}>
        {translateFunc(key)}
      </ValueKeyWrapper>
    ));
  } else {
    return column.keysArray.map((key, index) => (
      <ValueKeyWrapper component="div" key={index}>
        {translateFunc(`${column.key}.${key}`)}
      </ValueKeyWrapper>
    ));
  }
};

export const renderValue = (
  column: ColumnConfig,
  dataObj: DataObj,
  renderData?: RenderData,
) => {
  if (Array.isArray(column)) {
    return column.map((key, index) => (
      <ValueKeyWrapper component="div" key={index}>
        {renderData && renderData[key] ? (
          renderData[key](dataObj[key], dataObj)
        ) : dataObj[key] === undefined ? (
          <div>&nbsp;</div>
        ) : (
          dataObj[key]
        )}
      </ValueKeyWrapper>
    ));
  } else {
    const innerObj = { ...dataObj[column.key] };
    return column.keysArray.map((key, index) => (
      <ValueKeyWrapper component="div" key={index}>
        {renderData && renderData[key] ? (
          renderData[key](innerObj[key], dataObj)
        ) : innerObj[key] === undefined ? (
          <div>&nbsp;</div>
        ) : (
          innerObj[key]
        )}
      </ValueKeyWrapper>
    ));
  }
};
