import { api } from '../index';
import { Terminal } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from 'utils/funcs/downloadFile';

export interface TerminalsFilter {
  pto_id: number;
  terminal_id: string;
  location: string;
  terminal_status: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

const getTerminalsCollection = async (
  ptoId: number,
  params: Partial<TerminalsFilter>,
) => {
  const response = await api.get<ApiResponse<Terminal[]>>(`/pto/${ptoId}/terminal`, {
    params,
  });
  return response.data;
};

export const useTerminalsCollectionsQuery = (ptoId: number, filterParams = {}) =>
  useQuery(['getTerminals', ptoId, filterParams], () =>
    getTerminalsCollection(ptoId, filterParams),
  );

const getTerminalDetail = async (ptoId: number, tokenId: string) => {
  const response = await api.get<Terminal>(`/pto/${ptoId}/terminal/${tokenId}`);
  return response.data;
};

export const useTerminalDetailQuery = (ptoId: number, terminalId: string) =>
  useQuery(['getTerminalDetail', ptoId, terminalId], () =>
    getTerminalDetail(ptoId, terminalId),
  );

export const exportTerminalData = async (
  ptoId: number,
  type: FileType,
  filterParams = {},
) => {
  return await getFile(
    `/pto/${ptoId}/terminal/export/${type}`,
    type,
    'Terminals',
    filterParams,
  );
};
