import { ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps } from '@mui/material';
import FormField from '../formField';

interface ToggleButtonProps<T> extends ToggleButtonGroupProps {
  value: T;
  onChange: (...event: any[]) => void;
  label: string;
  errorText: string;
  options: { label: string; value: T }[];
}

export const AppToggleButton = <T,>({
  value,
  onChange,
  label,
  errorText,
  options,
}: ToggleButtonProps<T>) => {
  return (
    <FormField label={label} required={undefined} error={undefined} errorText={errorText}>
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={onChange}
        aria-label="text alignment"
      >
        {options.map((option, index) => (
          <ToggleButton key={index} value={option.value} aria-label="left aligned">
            {option.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </FormField>
  );
};
