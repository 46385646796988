import { api } from '../index';
import { Transaction } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from 'utils/funcs/downloadFile';

export interface TransactionsFilter {
  approval_code: string;
  pan: string;
  amount_from: number;
  amount_to: number;
  curency: string;
  date_from: string;
  date_to: string;
  use_date_terminal: boolean;
  location: string;
  terminal: string;
  status: string;
  transaction_id: string;
  transaction_type: string;
  card_issuer: string;
  card_schema: string;
  card_origin: string;
  card_product: string;
  payment_type: string;
  payment_device: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

const getTransactionsCollection = async (
  ptoId: number,
  params: Partial<TransactionsFilter>,
) => {
  const response = await api.get<ApiResponse<Transaction[]>>(
    `/pto/${ptoId}/transaction`,
    { params },
  );
  return response.data;
};

export const useTransactionsCollectionsQuery = (ptoId: number, filterParams = {}) =>
  useQuery(['getTransactions', ptoId, filterParams], () =>
    getTransactionsCollection(ptoId, filterParams),
  );

const getTransactionDetail = async (ptoId: number, transactionId: string) => {
  const response = await api.get<Transaction>(
    `/pto/${ptoId}/transaction/${transactionId}`,
  );
  return response.data;
};

export const useTransactionDetailQuery = (ptoId: number, transactionId: string) =>
  useQuery(['getTransactionDetail', ptoId, transactionId], () =>
    getTransactionDetail(ptoId, transactionId),
  );

export const exportTransactionReceipt = async (
  ptoId: number,
  transactionId: string,
  filterParams = {},
) => {
  return await getFile(
    `/pto/${ptoId}/transaction/${transactionId}/receipt`,
    'pdf',
    'Transaction',
    filterParams,
  );
};
