import { api } from '../index';
import { Stop } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from 'utils/funcs/downloadFile';

export interface StopsFilter {
  vehicle_type: string;
  line: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

const getStopssCollection = async (ptoId: number, params: Partial<StopsFilter>) => {
  const response = await api.get<ApiResponse<Stop[]>>(`/pto/${ptoId}/stop`, { params });
  return response.data;
};

export const useStopsCollectionsQuery = (ptoId: number, filterParams = {}) =>
  useQuery(['getStops', ptoId, filterParams], () =>
    getStopssCollection(ptoId, filterParams),
  );

const getStopsDetail = async (
  ptoId: number,
  stopId: string,
  params: Partial<StopsFilter>,
) => {
  const response = await api.get<Stop>(`/pto/${ptoId}/stop/${stopId}`, {
    params,
  });
  return response.data;
};

export const useStopDetailQuery = (ptoId: number, stopId: string, filterParams = {}) =>
  useQuery(['getStopDetail', ptoId, stopId, filterParams], () =>
    getStopsDetail(ptoId, stopId, filterParams),
  );

export const exportStopsData = async (
  ptoId: number,
  type: FileType,
  filterParams = {},
) => {
  return await getFile(`/pto/${ptoId}/stop/export/${type}`, type, 'Stops', filterParams);
};
