import { Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CircleIcon from '@mui/icons-material/Circle';
import { useEffect, useState } from 'react';
import { PaymentWindowsStatus } from 'types/commonTypes';

interface PaymentWindowsStatusProps {
  status: PaymentWindowsStatus;
  showCircle?: boolean;
}

export const PaymentWindowStatus: React.FC<PaymentWindowsStatusProps> = ({
  status,
  showCircle = true,
}) => {
  const [color, setColor] = useState('error');
  const { t } = useTranslation('common', {
    keyPrefix: 'enums.paymentWindowsStatus',
  });
  const theme = useTheme();
  const { palette } = theme;

  useEffect(() => {
    switch (status) {
      case 'Closed':
        setColor(palette.text.darkGrey.main);
        break;
      case 'Open':
        setColor(palette.text.yellow.main);
        break;
      default:
        setColor(palette.error.main);
        break;
    }
  }, [status]);

  return (
    <Typography
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      color={color}
    >
      {showCircle && <CircleIcon sx={{ marginRight: 1, color }} />}
      <Typography component={'span'} sx={{ color }}>
        {t(status)}
      </Typography>
    </Typography>
  );
};
