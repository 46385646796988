import { Typography, useTheme } from '@mui/material';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

interface TapStatusProps {
  label: string;
  status: 'tap-in' | 'tap-out';
}

export const TapStatus: React.FC<TapStatusProps> = ({ label, status }) => {
  const theme = useTheme();
  const color = status.toLowerCase() === 'tap-in' ? theme.palette.success.main : 'error';
  const icon =
    status.toLowerCase() === 'tap-in' ? (
      <ArrowLeftIcon fontSize="large" />
    ) : (
      <ArrowRightIcon fontSize="large" />
    );
  return (
    <Typography
      sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
      color={color}
    >
      {icon}
      {label}
    </Typography>
  );
};
