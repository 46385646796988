import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  JourneysFilter,
  useJourneysCollectionsQuery,
  exportJourneysData,
} from 'api/endpoints/journeys';
import AppLink from 'components/appLink/appLink';
import { AppTable, AppTableProps } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { TransportIcon } from 'components/transportIcon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { JourneyInterface } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import useFilters from 'utils/hooks/filters/useFilters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useAppSelector } from 'stores/store';

export const JourneysPage: React.FC = () => {
  const { ptoId } = useAppSelector((state) => state.user);
  const { t } = useTranslation('common', { keyPrefix: 'pages.journeysPage' });
  const { filters, filterValues } = useFilters<JourneysFilter>({
    filters: ['DATE_RANGE', 'VEHICLE_TYPE', 'LINE'],
    namePairs: [
      { filterName: 'DATE_RANGE', queryname: ['date_from', 'date_to'] },
      { filterName: 'VEHICLE_TYPE', queryname: 'vehicle_type' },
      { filterName: 'LINE', queryname: 'line' },
    ],
  });

  const { data } = useJourneysCollectionsQuery(ptoId, {
    ...filterValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    date_from: filterValues.date_from
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatDateToDay(filterValues.date_from, 'YYYY-MM-DD')
      : '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    date_to: filterValues.date_to
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatDateToDay(filterValues.date_to, 'YYYY-MM-DD')
      : '',
  });

  const JOURNEY_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'start_date',
        headerName: t('table.startDate'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<JourneyInterface>) => {
          const { row } = params;

          return (
            <div>
              <Typography component="div">
                {formatDateToDay(row.start_date, 'DD.MM.YYYY')}
              </Typography>
              {formatDateToDay(row.start_date, 'hh:mm:ss')}
            </div>
          );
        },
      },
      {
        field: 'end_date',
        headerName: t('table.endDate'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<JourneyInterface>) => {
          const { row } = params;

          return (
            <div>
              <Typography component="div">
                {formatDateToDay(row.end_date, 'DD.MM.YYYY')}
              </Typography>
              {formatDateToDay(row.end_date, 'hh:mm:ss')}
            </div>
          );
        },
      },
      {
        field: 'lines',
        headerName: t('table.lines'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<JourneyInterface>) => {
          const { row } = params;

          return (
            <>
              {row.lines.map((line: JourneyInterface['lines'][0], index: number) => (
                <Typography
                  sx={{ marginRight: 1, display: 'flex', alignContent: 'center' }}
                  key={index}
                >
                  <TransportIcon type={line.type} />
                  <AppLink to={`/fleet/${line.id}`}>{line.name}</AppLink>
                </Typography>
              ))}
            </>
          );
        },
      },
      {
        field: 'token_id',
        headerName: t('table.tokenId'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<JourneyInterface>) => {
          const { row } = params;

          return <AppLink to={`/token/${row.token_id}`}>{row.token_id}</AppLink>;
        },
      },
      {
        field: 'customer',
        headerName: t('table.customer'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<JourneyInterface>) => {
          const { row } = params;

          return (
            <AppLink to={`/customer/${row.customer.id}`}>{row.customer.name}</AppLink>
          );
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<JourneyInterface>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/journey/${row.id}`} isGreyLink>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );
  return (
    <div>
      <AppBreadcrumbs />
      {filters}
      <AppTable
        rows={data?.data ?? []}
        columns={JOURNEY_COLUMNS}
        showSettings={false}
        exportMenuItems={[
          {
            onClick: async () => await exportJourneysData(ptoId, 'xlxs', filterValues),
            label: 'XLXS',
          },
          {
            onClick: async () => await exportJourneysData(ptoId, 'csv', filterValues),
            label: 'CSV',
          },
        ]}
        appTitle={t('tableTitle')}
      />
    </div>
  );
};
