import { Box, Typography } from '@mui/material';
import { useTapDetailQuery } from 'api/endpoints/taps';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useAppSelector } from 'stores/store';
import { AppDataContainer } from 'components/appDataContainer';
import { OneColumnContainer } from 'components/appDataContainer/oneColumnContainer';
import { TwoColumnContainer } from 'components/appDataContainer/twoColumnContainer';
import { formatDateToDay } from 'utils/funcs/formatDate';
import AppLink from 'components/appLink/appLink';
import {
  CUSTOMER_DETAIL_URL,
  TERMINAL_DETAIL_URL,
  TOKEN_DETAIL_URL,
} from 'pages/appRoutes/appRoutesConst';
import { TapInterface } from 'types/commonTypes';

const customRenderFunctions = {
  time: (value: string, data: Record<string, any>) => {
    return <Typography>{formatDateToDay(value, 'DD.MM.YYYY hh:mm')}</Typography>;
  },
  id: (value: string, data: Record<string, any>) => {
    return (
      <AppLink replace={true} to={`/${TOKEN_DETAIL_URL}/${value}`}>
        {value}
      </AppLink>
    );
  },
  terminal_detail_id: (value: string, data: Record<string, any>) => {
    return (
      <AppLink replace={true} to={`/${TERMINAL_DETAIL_URL}/${value}`}>
        {value}
      </AppLink>
    );
  },
  // name: (value: string, data: Record<string, any>) => {
  //   const typedData = data as TapInterface;
  //   console.log(typedData, 'Typed data');
  //   return (
  //     <AppLink replace={true} to={`/${CUSTOMER_DETAIL_URL}/${typedData?.customer?.id}`}>
  //       {typedData.customer.name}
  //     </AppLink>
  //   );
  // },
};

export const TapDetailPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.tapDetailPage' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { id: tapId } = useParams();
  const { data } = useTapDetailQuery(ptoId, tapId || '');

  return (
    <Box display="flex" gap={5}>
      <AppDataContainer
        titleRow={{
          title: t('title'),
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {data && (
            <TwoColumnContainer
              renderData={customRenderFunctions}
              columns={[
                ['time', 'token_id', 'terminal_detail_id'],
                [],
                // ['name']
              ]}
              dataObj={data ?? {}}
              translateFunc={(k: string): string => {
                return t(k);
              }}
            />
          )}
        </Box>
      </AppDataContainer>
    </Box>
  );
};
