import { Button as MuiButton, ButtonProps, styled } from '@mui/material';

const StyledButton = styled(MuiButton)(({ theme: { typography } }) => ({
  ...typography.button,
  textTransform: 'none',
  whiteSpace: 'nowrap',
}));

const AppButton: React.FC<ButtonProps> = ({ children, ...rest }) => {
  return <StyledButton {...rest}>{children}</StyledButton>;
};

export default AppButton;
