import React, { useEffect, useState } from 'react';
import { Marker } from '@react-google-maps/api';
import Map from '../index';

interface MapWithMarkersProps {
  markers: {
    lat: number;
    lng: number;
  }[];
  center?: {
    lat: number;
    lng: number;
  };
}

const MapWithMarkers: React.FC<MapWithMarkersProps> = ({ markers, ...rest }) => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => setIsMounted(true), []);

  return isMounted && markers.length > 0 ? (
    <Map {...rest}>
      {markers.map((marker, index) => (
        <Marker key={index} position={marker} />
      ))}
    </Map>
  ) : (
    <></>
  );
};

export default React.memo(MapWithMarkers);
