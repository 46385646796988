// AUTHENTICATED ROUTES
export const ROOT_URL = '/';

export const CUSTOMERS_URL = 'customers';
export const TOKENS_URL = 'tokens';
export const DENY_LIST_URL = 'deny';
export const JOURNEYS_URL = 'journeys';
export const TAPS_URL = 'taps';
export const TRANSACTIONS_URL = 'transactions';
export const PAYMENT_WINDOWS_URL = 'payment-windows';
export const CLEARING_URL = 'clearing';
export const TERMINALS_URL = 'terminals';
export const FLEET_URL = 'fleet';
export const STOPS_URL = 'stops';
export const LINES_URL = 'lines';
export const FARES_URL = 'fares';
export const TRANSACTIONS_SETTINGS_URL = 'transaction-settings';
export const GENERAL_URL = 'general';
export const NOTIFICATION_URL = 'notifications';

// DETAIL URL
export const CUSTOMER_DETAIL_URL = 'customer/:id';
export const TOKEN_DETAIL_URL = 'token/:id';
export const JOURNEY_DETAIL_URL = 'journey/:id';
export const LINE_DETAIL_URL = 'line/:id';
export const TAP_DETAIL_URL = 'tap/:id';
export const PAYMENT_WINDOW_DETAIL_URL = 'paymentWindow/:id';
export const TRANSACTION_DETAIL_URL = 'transaction/:id';
export const VEHICLE_DETAIL_URL = 'vehicle/:id';
export const TERMINAL_DETAIL_URL = 'terminal/:id';
export const STOP_DETAIL_URL = 'stop/:id';

// CATEGORY URL
export const PEOPLE_URL = 'people';
export const ACTIVITY_URL = 'activity';
export const PAYMENTS_URL = 'payments';
export const NETWORK_URL = 'network';
export const SETTINGS_URL = 'settings';

// NOT AUTHENTICATED ROUTES

// NAVIGATION ROUTES AND BREACRUMB LABELS
export const NAVBAR_LABELS = {
  PEOPLE_LABEL: 'people',
  CUSTOMERS_LABEL: 'customers',
  TOKENS_LABEL: 'tokens',
  DENY_LIST_LABEL: 'denyList',
  ACTIVITY_LABEL: 'activity',
  JOURNEYS_LABEL: 'journeys',
  TAPS_LABEL: 'taps',
  PAYMENTS_LABEL: 'payments',
  TRANSACTIONS_LABEL: 'transactions',
  PAYMENT_WINDOWS_LABEL: 'paymentWindows',
  CLEARING_LABEL: 'clearing',
  NETWORK_LABEL: 'network',
  TERMINALS_LABEL: 'terminals',
  FLEET_LABEL: 'fleet',
  STOPS_LABEL: 'stops',
  LINES_LABEL: 'lines',
  SETTINGS_LABEL: 'settings',
  FARES_LABEL: 'fares',
  TRANSACTIONS_SETTINGS_LABEL: 'transactionsSettings',
  GENERAL_LABEL: 'general',
  NOTIFICATION_LABEL: 'notification',
};
