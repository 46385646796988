import { Select, MenuItem, useTheme, alpha, darken } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ArrowDropDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs from 'dayjs';
import czechLocale from 'dayjs/locale/cs';
import germanLocale from 'dayjs/locale/de';

interface UseLanguageSelectProps {
  color?: 'whiteText';
  isMobile?: boolean;
}

const CZECH = 'cs';
const ENGLISH = 'en';
const GERMAN = 'de';

const LANGUAGES = [
  { value: ENGLISH, label: 'english' },
  { value: CZECH, label: 'czech' },
];

const useLanguageSelect = ({
  color = undefined,
  isMobile,
}: UseLanguageSelectProps = {}) => {
  const { t, i18n } = useTranslation('common', {
    keyPrefix: 'navigation.languages',
  });
  const [language, setLanguage] = useState('en');
  const theme = useTheme();
  const { palette } = theme;

  useEffect(() => {
    if (language === CZECH) {
      dayjs.locale(czechLocale);
    } else if (language === GERMAN) {
      dayjs.locale(germanLocale);
    } else {
      dayjs.locale(ENGLISH);
    }

    i18n.changeLanguage(language);
  }, [language]);

  const variableColor = useMemo(
    () => (color === 'whiteText' ? palette.text.white.main : palette.text.black.main),
    [color],
  );

  return (
    <Select
      value={language}
      onChange={(e) => setLanguage(e.target.value)}
      MenuProps={{
        sx: {
          '&& .MuiPopover-paper': {
            backgroundColor:
              color === 'whiteText'
                ? alpha(palette.common.black, 0.3)
                : palette.text.white.main,
            '& .Mui-selected': {
              backgroundColor:
                color === 'whiteText' ? darken(palette.common.black, 0.3) : 'initial',
            },
            ' .MuiMenuItem-root:hover': {
              backgroundColor:
                color === 'whiteText'
                  ? alpha(palette.text.white.main, 0.3)
                  : palette.primary.main,
            },
            ' .MuiMenuItem-root': {
              backgroundColor: color === 'whiteText' ? 'transparent' : 'initial',
            },
          },
        },
      }}
      sx={{
        color: variableColor,
        '&.MuiOutlinedInput-root .MuiSelect-icon': {
          color: variableColor,
        },
        '&.MuiOutlinedInput-root': {
          width: isMobile ? '100%' : 'initial',
        },
        '& .MuiOutlinedInput-input': {
          padding: 0,
        },
        '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: 'transparent',
        },
      }}
      IconComponent={ArrowDropDownIcon}
    >
      {LANGUAGES.map((lang, index) => (
        <MenuItem sx={{ color: variableColor }} key={index} value={lang.value}>
          {t(lang.label)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default useLanguageSelect;
