import { api } from '../index';
import { useQuery } from 'react-query';
import { Notification } from 'types/commonTypes';

export interface NotificationFilter {
  read: number;
}

const getNotificationCollection = async () => {
  return await api.get<Notification[]>(`/notification`);
};

export const useNotificationCollectionsQuery = (notificationTrigger: number) => {
  return useQuery(['getNotifications', notificationTrigger], () =>
    getNotificationCollection(),
  );
};

export const readSingleNotification = async (notificationId: number) => {
  return await api.patch<Notification[]>(`/notification/${notificationId}/read`);
};

export const readAllNotificationsFromUser = async () => {
  return await api.patch<Notification[]>(`/notification/read-all`);
};
