import React from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomerStatus } from 'types/enumTypes';

interface StatusHiglightProps {
  status: CustomerStatus;
  displayCircle?: boolean;
}

type ColorType =
  | 'inherit'
  | 'action'
  | 'disabled'
  | 'primary'
  | 'secondary'
  | 'error'
  | 'info'
  | 'success'
  | 'warning';

const formatStatus = (status: CustomerStatus) => {
  let formatedStatus: ColorType = 'success';
  switch (status) {
    case CustomerStatus['DENY']:
      formatedStatus = 'error';
      break;
    case CustomerStatus['DENY_LIST']:
      formatedStatus = 'error';
      break;
    case CustomerStatus['ACTIVE']:
      formatedStatus = 'success';
      break;
    case CustomerStatus['INACTIVE']:
      formatedStatus = 'disabled';
      break;
    default:
      break;
  }
  return formatedStatus;
};

export const AppStatusHighlight: React.FC<StatusHiglightProps> = ({
  status,
  displayCircle = true,
  children,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'enums.customerStatus' });
  return (
    <Box sx={{ display: 'flex' }}>
      {displayCircle && (
        <CircleIcon sx={{ marginRight: 1 }} color={formatStatus(status)} />
      )}
      <Typography
        sx={{ textTransform: 'capitalize' }}
        component="span"
        color={formatStatus(status) === 'success' ? 'success.main' : formatStatus(status)}
      >
        {children}
      </Typography>
    </Box>
  );
};
