import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { PaymentWindowFilter } from 'api/endpoints/paymentWindows';
import { usePaymentWindowsCollectionsQuery } from 'api/endpoints/paymentWindows';
import AppLink from 'components/appLink/appLink';
import { AppTableProps, AppTable } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'stores/store';
import { PaymentWindow } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import useFilters from 'utils/hooks/filters/useFilters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  CUSTOMER_DETAIL_URL,
  PAYMENT_WINDOW_DETAIL_URL,
  TOKEN_DETAIL_URL,
} from './appRoutes/appRoutesConst';
import { Box, Typography, useTheme } from '@mui/material';
import { PaymentWindowStatus } from 'components/paymentWindowStatus';
import { ClearingOverview } from 'components/paymentSummary';

export const ClearingPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.clearingPage' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { palette } = useTheme();
  const { filters, filterValues } = useFilters<PaymentWindowFilter>({
    filters: ['DATE', 'PAYMENT_WINDOW_STATUS'],
    namePairs: [
      { filterName: 'DATE', queryname: 'date_cleared' },
      { filterName: 'PAYMENT_WINDOW_STATUS', queryname: 'payment_window_status' },
    ],
  });
  const theme = useTheme();
  const { spacing } = theme;

  const { data } = usePaymentWindowsCollectionsQuery(ptoId, {
    ...filterValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    date_cleared: filterValues.date_cleared
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatDateToDay(filterValues.date_cleared, 'YYYY-MM-DD', 'DD/MM/YYYY')
      : '',
  });

  const CLEARING_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'date',
        headerName: t('table.openDate'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          return (
            <>
              {params.row.date_opened ? (
                <div>
                  <Typography component="div">
                    {formatDateToDay(params.row.date_opened, 'DD.M.YYYY')}
                  </Typography>
                  {formatDateToDay(params.row.date_opened, 'hh:mm:ss')}
                </div>
              ) : (
                '-'
              )}
            </>
          );
        },
      },
      {
        field: 'closed',
        headerName: t('table.closed'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          return (
            <>
              {params.row.date_closed ? (
                <div>
                  <Typography component="div">
                    {formatDateToDay(params.row.date_closed, 'DD.M.YYYY')}
                  </Typography>
                  {formatDateToDay(params.row.date_closed, 'hh:mm:ss')}
                </div>
              ) : (
                '-'
              )}
            </>
          );
        },
      },
      {
        field: 'amount',
        headerName: t('table.amountAndCurrency'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          return (
            <Typography
              fontWeight={'bold'}
              color={palette.text.darkGrey.main}
            >{`${params.row.amount} ${params.row.currency}`}</Typography>
          );
        },
      },
      {
        field: 'status',
        headerName: t('table.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          const { row } = params;
          return <PaymentWindowStatus status={row.status} />;
        },
      },
      {
        field: 'tokenID',
        headerName: t('table.id'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          return (
            <AppLink to={`/${TOKEN_DETAIL_URL.replace(/\/:id$/, '')}/${params.row.id}`}>
              {params.row.id}
            </AppLink>
          );
        },
      },
      {
        field: 'customer',
        headerName: t('table.customerName'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          return (
            <AppLink
              to={`/${CUSTOMER_DETAIL_URL.replace(/\/:id$/, '')}/${
                params.row.customer.id
              }`}
            >
              {params.row.customer.name}
            </AppLink>
          );
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink
                  to={`/${PAYMENT_WINDOW_DETAIL_URL.replace(/\/:id$/, '')}/${row.id}`}
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <AppBreadcrumbs />
      {filters}
      <Box sx={{ marginTop: spacing(2), marginBottom: spacing(3) }}>
        <ClearingOverview
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          date={formatDateToDay(filterValues.date_cleared, 'YYYY-MM-DD', 'DD/MM/YYYY')}
        />
      </Box>
      <AppTable
        rows={data?.data ?? []}
        columns={CLEARING_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
      />
    </div>
  );
};
