import { api } from '../index';
import { ClearResponse, Token } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from 'utils/funcs/downloadFile';

export interface TokensFilter {
  pto_id: number;
  token_id: string;
  customer_name: string;
  token_status: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

const getTokensCollection = async (ptoId: number, params: Partial<TokensFilter>) => {
  const response = await api.get<ApiResponse<Token[]>>(`/pto/${ptoId}/token`, { params });
  return response.data;
};

export const useTokensCollectionsQuery = (ptoId: number, filterParams = {}) =>
  useQuery(['getCustomers', ptoId, filterParams], () =>
    getTokensCollection(ptoId, filterParams),
  );

const getTokenDetail = async (ptoId: number, tokenId: string) => {
  const response = await api.get<Token>(`/pto/${ptoId}/token/${tokenId}`);
  return response.data;
};

export const useTokenDetailQuery = (ptoId: number, tokenId: string) =>
  useQuery(['getTokenDetail', ptoId, tokenId], () => getTokenDetail(ptoId, tokenId));

export const exportTokensData = async (
  ptoId: number,
  type: FileType,
  filterParams = {},
) => {
  return await getFile(
    `/pto/${ptoId}/token/export/${type}`,
    type,
    'Tokens',
    filterParams,
  );
};

export const clearToken = async (ptoId: number, tokenId: string) => {
  const response = await api.patch<ClearResponse>(`/pto/${ptoId}/token/clear/${tokenId}`);
  return response;
};
