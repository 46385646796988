import {
  ClickAwayListener,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Box,
  styled,
} from '@mui/material';
import AppTextField from 'components/form/textField';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer, SearchGroup, Token, Transaction } from '../../../types/commonTypes';
import { NavLink } from 'react-router-dom';
import { useSearchTermsQuery } from '../../../api/endpoints/searchBox';
import AppLink from '../../appLink/appLink';
import {
  CUSTOMER_DETAIL_URL,
  CUSTOMERS_URL,
  PAYMENTS_URL,
  PEOPLE_URL,
  TOKEN_DETAIL_URL,
  TOKENS_URL,
  TRANSACTION_DETAIL_URL,
  TRANSACTIONS_URL,
} from '../../../pages/appRoutes/appRoutesConst';

const checkIsCustomer = (object: unknown): object is Customer => {
  return (
    Object.prototype.hasOwnProperty.call(object, 'name') &&
    Object.prototype.hasOwnProperty.call(object, 'birthdate')
  );
};

const SearchGroupsWrap = styled(MenuList)(({ theme: { spacing } }) => ({
  '& > li': {
    padding: 0,
    '&.Mui-disabled': {
      marginTop: spacing(2),
      opacity: 1,
      '&:first-of-type': {
        marginTop: 0,
      },
    },
    '& > h4, & > a, & > div > span > a': {
      padding: spacing(0.7, 2),
      display: 'block',
    },
  },
}));

const GroupTitle = styled('h4')(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
  fontWeight: 700,
  fontSize: '1rem',
  margin: 0,
}));

const ItemTitle = styled(ListItemText)(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
  '> span': {
    fontWeight: 400,
    fontSize: '0.875rem',
    '> a': {
      textDecoration: 'none',
    },
  },
}));

const SeeAllLink = styled(NavLink)(({ theme: { palette } }) => ({
  color: palette.secondary.light,
  fontWeight: 400,
  fontSize: '0.875rem',
  textDecoration: 'none',
  flexGrow: '1',
}));

const NoResults = styled('div')(({ theme: { spacing, palette } }) => ({
  color: palette.text.lightGrey.main,
  fontWeight: 400,
  fontSize: '0.875rem',
  padding: spacing(0.7, 2),
}));

const SearchGroupSection: React.FC<{
  items: Customer[] | Token[] | Transaction[];
  title: string;
  seeAllText: string;
  detailUrl: string;
  seeAllUrl: string;
  onTermClickHandler: () => void;
}> = ({ items, title, seeAllText, detailUrl, seeAllUrl, onTermClickHandler }) => {
  const isCustomer = checkIsCustomer(items[0]);

  return (
    <>
      <MenuItem disabled>
        <GroupTitle>{title}</GroupTitle>
      </MenuItem>
      {items.slice(0, 3).map((item, index) => (
        <MenuItem key={index}>
          <ItemTitle>
            <AppLink to={`${detailUrl}/${item.id}`} onClick={onTermClickHandler}>
              {isCustomer ? 'name' in item && item.name : item.id}
            </AppLink>
          </ItemTitle>
        </MenuItem>
      ))}
      {items.length > 3 && (
        <MenuItem>
          <SeeAllLink to={seeAllUrl}>{seeAllText}</SeeAllLink>
        </MenuItem>
      )}
    </>
  );
};

const SearchBoxGroup: React.FC<{
  results: SearchGroup;
  onTermClickHandler: () => void;
}> = ({ results, onTermClickHandler }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.searchBox' });
  const { customers, tokens, transactions } = results;

  return (
    <>
      {/* People */}
      {customers.length > 0 && (
        <SearchGroupSection
          items={customers}
          title={t('customersTitle')}
          seeAllText={t('seeAllCustomers')}
          detailUrl={CUSTOMER_DETAIL_URL.split('/')[0]}
          seeAllUrl={`/${PEOPLE_URL}/${CUSTOMERS_URL}`}
          onTermClickHandler={onTermClickHandler}
        />
      )}
      {/* Tokens */}
      {tokens.length > 0 && (
        <SearchGroupSection
          items={tokens}
          title={t('tokensTitle')}
          seeAllText={t('seeAllTokens')}
          detailUrl={TOKEN_DETAIL_URL.split('/')[0]}
          seeAllUrl={`/${PEOPLE_URL}/${TOKENS_URL}`}
          onTermClickHandler={onTermClickHandler}
        />
      )}
      {/* Transactions */}
      {transactions.length > 0 && (
        <SearchGroupSection
          items={transactions}
          title={t('transactionsTitle')}
          seeAllText={t('seeAllTransaction')}
          detailUrl={TRANSACTION_DETAIL_URL.split('/')[0]}
          seeAllUrl={`/${PAYMENTS_URL}/${TRANSACTIONS_URL}`}
          onTermClickHandler={onTermClickHandler}
        />
      )}
      {customers.length === 0 && tokens.length === 0 && transactions.length === 0 && (
        <MenuItem disabled>
          <NoResults>{t('noResults')}</NoResults>
        </MenuItem>
      )}
    </>
  );
};

const SearchInput = styled(AppTextField)(() => ({
  '.MuiOutlinedInput-input': {
    fontSize: '1rem',
    fontWeight: 'normal',
  },
}));

export const SearchBox = () => {
  const { t } = useTranslation('common', { keyPrefix: 'components.searchBox' });
  const ptoId = 1;
  const [searchedText, setSearchedText] = useState('');
  const [canBeSendQuery, setCanBeSendQuery] = useState<boolean>(false);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState(false);
  const [isClearBtnVisible, setIsClearBtnVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data } = useSearchTermsQuery(ptoId, searchedText, canBeSendQuery);

  const handleClearSearchBox = () => {
    setSearchedText('');
    setIsClearBtnVisible(false);
    setIsSearchBoxOpen(false);
  };

  const searchedTermClickHandler = () => {
    setIsSearchBoxOpen(false);
  };

  const handleIsFocused = () => {
    if (searchedText.length >= 3) {
      setIsSearchBoxOpen(true);
    }
  };

  const handleChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCanBeSendQuery(false);
    setSearchedText(event.target.value);
    setAnchorEl(event.currentTarget.parentElement);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchedText.length >= 3) {
        setIsSearchBoxOpen(true);
        setCanBeSendQuery(true);
      }
    }, 500);
    if (searchedText.length < 3) setIsSearchBoxOpen(false);
    setIsClearBtnVisible(searchedText.length > 0);
    return () => clearTimeout(timer);
  }, [searchedText]);

  return (
    <>
      <ClickAwayListener
        onClickAway={() => {
          setIsSearchBoxOpen(false);
        }}
      >
        <Box sx={{ width: 370 }}>
          <SearchInput
            placeholder={t('search')}
            onChange={handleChangeSearchValue}
            onFocus={handleIsFocused}
            value={searchedText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" sx={{ width: 35 }}>
                  {isClearBtnVisible && (
                    <IconButton onClick={handleClearSearchBox} edge="end">
                      <CloseIcon />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Popper
            style={{ zIndex: 100 }}
            open={isSearchBoxOpen}
            placement="bottom-start"
            anchorEl={anchorEl}
          >
            <Paper
              sx={{
                width: 370,
                maxWidth: '100%',
                marginTop: '0.5rem',
              }}
            >
              {data && (
                <SearchGroupsWrap>
                  <SearchBoxGroup
                    results={data}
                    onTermClickHandler={searchedTermClickHandler}
                  />
                </SearchGroupsWrap>
              )}
            </Paper>
          </Popper>
        </Box>
      </ClickAwayListener>
    </>
  );
};
