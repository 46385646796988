import { Box, TextField } from '@mui/material';
import FormField from '../formField';

interface AmountFieldProps {
  value: [number, number];
  onChange: (...event: any[]) => void;
  label: string;
  currency: string;
}

export const AmountField: React.FC<AmountFieldProps> = ({
  value,
  onChange,
  label,
  currency,
}) => {
  return (
    <FormField label={label} error={false} errorText={''} required={false}>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <TextField
          value={value[0]}
          sx={{ marginRight: 1 }}
          onChange={(newValue) => {
            const v = [...value];
            v[0] = parseInt(newValue.target.value);
            if (v[0] >= 0) onChange(v);
          }}
          InputProps={{ endAdornment: `${currency}`, type: 'number' }}
        />
        {' - '}
        <TextField
          value={value[1]}
          sx={{ marginLeft: 1 }}
          onChange={(newValue) => {
            const v = [...value];
            v[1] = parseInt(newValue.target.value);
            if (v[1] >= 0) onChange(v);
          }}
          InputProps={{ endAdornment: `${currency}`, type: 'number' }}
        />
      </Box>
    </FormField>
  );
};
