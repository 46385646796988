import { Box, Typography } from '@mui/material';
import { useStopDetailQuery } from 'api/endpoints/stops';
import AppButton from 'components/appButton';
import { AppDataContainer } from 'components/appDataContainer';
import { OneColumnContainer } from 'components/appDataContainer/oneColumnContainer';
import { TransportIcon } from 'components/transportIcon';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'stores/store';
import { Stop } from 'types/commonTypes';
import { AppTable, AppTableProps } from 'components/appTable';
import { useMemo } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import {
  LINE_DETAIL_URL,
  NETWORK_URL,
  TERMINALS_URL,
} from 'pages/appRoutes/appRoutesConst';
import AppLink from 'components/appLink/appLink';
import { TapsOverview } from 'components/tapsOverview';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';

const lineDetailUrlWithoutId = LINE_DETAIL_URL.slice(0, -4);

const customRenderFunctions = {
  gps: (value: string, data: Record<string, any>) => {
    const gps = data.gps as Stop['gps'];
    return (
      <Typography>
        {`${gps.lat} 
        ${gps.lng}`}
      </Typography>
    );
  },
  lines: (value: string, data: Record<string, any>) => {
    const lines = data.lines as Stop['lines'];
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
        {lines.map((line, index) => (
          <Typography key={index}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
              <TransportIcon type={line.type} />
              <AppLink to={`/${lineDetailUrlWithoutId}/${line.id}`}>{line.name}</AppLink>
            </Box>
          </Typography>
        ))}
      </Box>
    );
  },
};

export const StopsDetailPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.stopsDetailPage' });
  const { t: tEnums } = useTranslation('common');
  const { ptoId } = useAppSelector((state) => state.user);
  const { id: stopId } = useParams();
  const { data } = useStopDetailQuery(ptoId, stopId || '');
  const navigate = useNavigate();

  const terminalIds = useMemo(() => {
    if (data?.terminals && data?.terminals.length > 0) {
      return data?.terminals.map((t) => t.id);
    } else {
      return [];
    }
  }, [data?.terminals]);

  const STOPS_DETAIL_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('terminalsBox.table.id'),
        flex: 1,
      },
      {
        field: 'status',
        headerName: t('terminalsBox.table.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Stop>) => {
          const { row } = params;

          return (
            <div>
              <AppStatusHighlight status={row.status}>
                <Typography
                  color={row.status === 'active' ? 'success.main' : 'error.main'}
                >
                  {tEnums(`enums.terminalStatus.${row.status}`)}
                </Typography>
              </AppStatusHighlight>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>
        <AppBreadcrumbs
          customBreadCrumbs={[
            { index: 1, breadCrumbLabel: `${data?.name ?? ''}`, route: '/' },
          ]}
        />
      </Box>
      <Box display="flex" gap={5} marginBottom={5}>
        <AppDataContainer
          titleRow={{
            title: t('infoBox.title'),
            // Not needed right now
            // buttons: [
            //   <AppButton
            //     startIcon={<DeleteIcon />}
            //     key="delete"
            //     variant="contained"
            //     size="small"
            //     color="error"
            //   >
            //     {t('infoBox.delete')}
            //   </AppButton>,
            //   <AppButton
            //     startIcon={<EditIcon />}
            //     key="add"
            //     variant="contained"
            //     size="small"
            //     color="secondary"
            //   >
            //     {t('infoBox.add')}
            //   </AppButton>,
            // ],
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            {data && (
              <OneColumnContainer
                renderData={customRenderFunctions}
                columns={[['name', 'gps', 'address', 'lines']]}
                dataObj={data ?? {}}
                translateFunc={(k: string): string => {
                  return t(k);
                }}
              />
            )}
          </Box>
        </AppDataContainer>
        <AppDataContainer
          titleRow={{
            title: t('terminalsBox.title'),
            buttons: [
              <AppButton
                variant="contained"
                size="small"
                color="secondary"
                key="toTerminalsRoute"
                onClick={() => navigate(`/${NETWORK_URL}/${TERMINALS_URL}`)}
              >
                {t('terminalsBox.details')}
              </AppButton>,
            ],
          }}
        >
          {data && (
            <AppTable
              showSettings={false}
              showExportButton={false}
              rows={data?.terminals ?? []}
              columns={STOPS_DETAIL_COLUMNS}
              appTitle={''}
            />
          )}
        </AppDataContainer>
      </Box>
      <TapsOverview terminalId={terminalIds} />
    </>
  );
};
