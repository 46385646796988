import { Box, MenuItem, useTheme } from '@mui/material';
import { AppBarChart } from 'components/charts/barChart';
import AppSelect from 'components/form/select';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NetworkLoadSettings, Widget } from 'types/commonTypes';
import { DAYS_OPTIONS } from 'utils/const/enums';

interface NetworkDataProps {
  data: Record<string, number>[];
  settings: Widget<NetworkLoadSettings>['settings'];
}

export const NetworkLoad: React.FC<NetworkDataProps> = ({ data, settings }) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.networkLoad' });
  const { t: tEnums } = useTranslation('common');
  const theme = useTheme();
  const { spacing } = theme;
  const transformedData = useMemo(() => {
    return Object.entries(data).map((entry, index) => ({
      value: entry[1] as unknown as number,
      name: entry[0],
    }));
  }, [data]);

  return (
    <Box sx={{ padding: spacing(2) }}>
      <AppBarChart yAxisUnit={` ${t('taps')}`} data={transformedData} />
      <Box sx={{ maxWidth: '50%' }}>
        <AppSelect value={settings}>
          {DAYS_OPTIONS.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {tEnums(`${option.label}`)}
            </MenuItem>
          ))}
        </AppSelect>
      </Box>
    </Box>
  );
};
