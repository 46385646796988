import { api } from '../index';
import { useQuery } from 'react-query';
import { Widget } from 'types/commonTypes';
import { TimePeriod } from './taps';

const getWidgets = async (ptoId: number) => {
  const response = await api.get<Widget[]>(`/pto/${ptoId}/widget`);
  return response.data;
};

export const useWidgetsQuery = (ptoId: number, trigger: number) =>
  useQuery(['getTransactionDetail', ptoId, trigger], () => getWidgets(ptoId));

export const getWidget = async (
  ptoId: number,
  widgetId: number,
  time_period?: TimePeriod,
) => {
  const response = await api.get<Widget>(`/pto/${ptoId}/widget/${widgetId}`, {
    params: { time_period },
  });
  return response;
};

export const deleteWidget = async (ptoId: number, widgetId: number) => {
  const response = await api.delete<Omit<Widget, 'data'>>(
    `/pto/${ptoId}/widget/${widgetId}`,
  );
  return response;
};

export const updateWidget = async (
  ptoId: number,
  widgetId: number,
  widgetData: Omit<Widget, 'id' | 'data'>,
) => {
  const response = await api.put<Widget>(`/pto/${ptoId}/widget/${widgetId}`, {
    ...widgetData,
  });
  return response;
};

export const createWidget = async (
  ptoId: number,
  widgetData: Omit<Widget, 'id' | 'data'>,
) => {
  const response = await api.post<Widget>(`/pto/${ptoId}/widget`, {
    ...widgetData,
  });
  return response;
};
