import { api } from '../index';

export interface FilterObject {
  id: number;
  name: string;
  filter: Record<string, string>;
}

export const saveFilters = async (
  filterValues: Record<string, any>,
  filterName: string,
) => {
  const response = await api.post(`/filter/transaction`, {
    name: filterName,
    filter: { ...filterValues },
  });
  return response;
};

export const getFilters = async () => {
  const response = await api.get<FilterObject[]>(`/filter/transaction`);
  return response.data;
};

export const deleteFilter = async (filterId: number) => {
  const response = await api.delete<FilterObject>(`/filter/transaction/${filterId}`);
  return response;
};
