import { Dialog, IconButton, MenuItem } from '@mui/material';
import { AppDataContainer } from 'components/appDataContainer';
import { useMemo, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { UpdateWidget } from './dialogs/updateWidget';
import { LatestTransaction } from './widgets/latestTransaction';
import { NetworkLoadSettings, Widget } from 'types/commonTypes';
import { LatestTaps } from './widgets/latestTaps';
import { NetworkLoad } from './widgets/networkLoad';
import { CardsWidget } from './widgets/cardsWidget';
import { StatesOfTransactionWidget } from './widgets/statesOfTransactions';
import AppSelect from 'components/form/select';
import { WidgetTypes } from 'types/enumTypes';
import { WIDGET_DAYS_OPTIONS } from 'utils/const/enums';
import { useTranslation } from 'react-i18next';
import { TimePeriod } from 'api/endpoints/taps';

interface WidgetProps extends Widget {
  updateCallback?: () => void;
  onTimeSelectChange?: (time: TimePeriod) => void;
}

export type WidgetState<T> = Omit<Widget<T>, 'data'>;

const showTimeSelect = (type: WidgetTypes) => {
  return (
    type === WidgetTypes['CARDS'] ||
    type === WidgetTypes['NETWORK_LOAD'] ||
    type === WidgetTypes['STATES_OF_TRANSACTIONS']
  );
};

export const WidgetContainer: React.FC<WidgetProps> = ({
  type,
  data,
  id,
  name,
  position,
  settings,
  updateCallback,
  onTimeSelectChange,
}) => {
  const [widgetState, setWidgetState] = useState<
    WidgetState<{ time_period?: TimePeriod }>
  >({
    type,
    id,
    position,
    name,
    settings,
  });
  const { t } = useTranslation('common');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [timeValue, setTimeValue] = useState(widgetState.settings.time_period);
  const handleClose = () => setIsModalOpen(false);
  const handleOpen = () => setIsModalOpen(true);

  const widget = useMemo(() => {
    switch (type) {
      case 'latest_taps':
        return <LatestTaps data={data} />;

      case 'cards':
        return <CardsWidget data={data} />;

      // case 'debt':
      //   return <DebtsWidget data={data} />;

      case 'states_of_transactions':
        return <StatesOfTransactionWidget data={data} />;

      case 'latest_transactions':
        return <LatestTransaction data={data} />;

      case 'network_load':
        return (
          <NetworkLoad
            settings={widgetState.settings as NetworkLoadSettings}
            data={data}
          />
        );
    }
  }, [type, data]);
  return (
    <>
      <AppDataContainer
        titleRow={{
          title: name,
          slimTitle: undefined,
          buttons: [
            showTimeSelect(widgetState.type) ? (
              <AppSelect
                onChange={(e) => {
                  setTimeValue(e.target.value as TimePeriod);
                  if (onTimeSelectChange) {
                    onTimeSelectChange(e.target.value as TimePeriod);
                  }
                }}
                displayEmpty
                value={timeValue}
                key="TimeSelect"
              >
                {WIDGET_DAYS_OPTIONS.map((dayOption, index) => (
                  <MenuItem key={index} value={dayOption.value}>
                    {t(dayOption.label)}
                  </MenuItem>
                ))}
              </AppSelect>
            ) : (
              <></>
            ),
            <IconButton key="Settings" onClick={() => handleOpen()}>
              <SettingsIcon />
            </IconButton>,
          ],
        }}
      >
        {widget}
      </AppDataContainer>
      <Dialog onClose={handleClose} open={isModalOpen}>
        <UpdateWidget
          handleClose={handleClose}
          setWidgetState={setWidgetState}
          widgetState={widgetState}
          updateCallback={updateCallback}
        />
      </Dialog>
    </>
  );
};
