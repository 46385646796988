import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import AppLink from 'components/appLink/appLink';
import { AppTable, AppTableProps } from 'components/appTable';
import { TapStatus } from 'components/tapStatus';
import { TransportIcon } from 'components/transportIcon';
import { t } from 'i18next';
import { VEHICLE_DETAIL_URL } from 'pages/appRoutes/appRoutesConst';
import { useMemo } from 'react';
import { LatestTap, TapInterface } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import CircleIcon from '@mui/icons-material/Circle';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface LatestTapsProps {
  data: LatestTap[];
}

export const LatestTaps: React.FC<LatestTapsProps> = ({ data }) => {
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });
  const { t } = useTranslation('common', { keyPrefix: 'pages.tapsPage' });

  const LATEST_TAPS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'time',
        headerName: t('table.time'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <div>
              <Typography component="div">
                {formatDateToDay(row.time, 'DD.MM.YYYY')}
              </Typography>
              <Typography component="div">
                {formatDateToDay(row.time, 'hh.mm.ss')}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'type',
        headerName: t('table.type'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return <TapStatus label={tEnums(`tapStatus.${row.type}`)} status={row.type} />;
        },
      },
      // {
      //   field: 'vehicle',
      //   headerName: t('table.vehicle'),
      //   flex: 1,
      //   renderCell: (params: GridRenderCellParams<TapInterface>) => {
      //     const { row } = params;
      //     return (
      //       <Typography
      //         sx={{
      //           gap: 1,
      //           display: 'flex',
      //           flexDirection: 'row',
      //           alignContent: 'center',
      //         }}
      //       >
      //         <TransportIcon type={row.fleet?.type} />
      //         <AppLink
      //           to={`/${VEHICLE_DETAIL_URL.replace(/\/:id$/, '')}/${row.fleet.id}`}
      //         >
      //           {row.fleet.id}
      //         </AppLink>
      //       </Typography>
      //     );
      //   },
      // },
      // {
      //   field: 'line',
      //   headerName: t('table.line'),
      //   flex: 1,
      //   renderCell: (params: GridRenderCellParams<TapInterface>) => {
      //     const { row } = params;

      //     return (
      //       <Typography
      //         sx={{
      //           gap: 1,
      //           display: 'flex',
      //           flexDirection: 'row',
      //           alignContent: 'center',
      //         }}
      //       >
      //         <CircleIcon sx={{ color: params.row.line.color, marginRight: 1 }} />
      //         <AppLink to={`/line/${row.line_id}`}>{row.line.name}</AppLink>
      //       </Typography>
      //     );
      //   },
      // },
      {
        field: 'terminal',
        headerName: t('table.terminal'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;
          return (
            <AppLink to={`/terminal/${row.terminal_detail_id}`}>
              {row.terminal_detail_id}
            </AppLink>
          );
        },
      },
      {
        field: 'token_id',
        headerName: t('table.tokenId'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return <AppLink to={`/token/${row.token_id}`}>{row.token_id}</AppLink>;
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/tap/${row.id}`}>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );
  return (
    <>
      <AppTable
        rows={data ?? []}
        columns={LATEST_TAPS_COLUMNS}
        appTitle={t('tableTitle')}
        hideHeaderRow
      />
    </>
  );
};
