import * as React from 'react';
import FormField from '../formField';
import { Box } from '@mui/material';
import {
  DateRange,
  DateRangePicker,
  LocalizationProvider,
} from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AppTextField from '../textField';

interface DateRangeSelectProps {
  value: DateRange<Date>;
  onChange: (...event: any[]) => void;
  label: string;
}

export const AppDateRangeSelect: React.FC<DateRangeSelectProps> = ({
  value,
  onChange,
  label,
}) => {
  return (
    <FormField label={label} error={false} errorText={''} required={false}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        sx={{
          BackgroundColor: 'red',
        }}
      >
        <DateRangePicker
          startText=""
          endText=""
          value={value}
          onChange={(newValue: any) => {
            onChange(newValue);
          }}
          renderInput={(startProps: any, endProps: any) => (
            <Box sx={{ display: 'flex', gap: 1 }}>
              <AppTextField {...startProps} />
              <AppTextField {...endProps} />
            </Box>
          )}
        />
      </LocalizationProvider>
    </FormField>
  );
};
