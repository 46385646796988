import { api } from '../index';
import { useQuery } from 'react-query';

// CUSTOMER_STATUS
export const getCustomerFilterOptions = async (ptoId: number) => {
  const response = await api.get(`/pto/${ptoId}/enum/customer_status`);
  return Object.keys(response.data);
};
export const useCustomerFiltersQuery = (ptoId: number) =>
  useQuery(['getCustomerFilterOption'], () => getCustomerFilterOptions(ptoId));

//   VEHICLE
export const getVehicleFilterOptions = async (ptoId: number) => {
  const response = await api.get(`/pto/${ptoId}/enum/vehicle_type`);
  return Object.values(response.data);
};
export const useVehicleFiltersQuery = (ptoId: number) =>
  useQuery(['getVehicleFilterOptions'], () => getVehicleFilterOptions(ptoId));

//   TOKEN_STATUS
export const getTokenFilterOptions = async (ptoId: number) => {
  const response = await api.get(`/pto/${ptoId}/enum/token_status`);
  return Object.keys(response.data);
};
export const useTokenFiltersQuery = (ptoId: number) =>
  useQuery(['getTokenFilterOptions'], () => getTokenFilterOptions(ptoId));

//   PAYEMNT_WINDOW_STATUS
export const getPaymentWindowStatusFilterOptions = async (ptoId: number) => {
  const response = await api.get(`/pto/${ptoId}/enum/payment_window_status`);
  return Object.keys(response.data);
};
export const usePaymentWindowStatusFiltersQuery = (ptoId: number) =>
  useQuery(['getTokenFilterOptions'], () => getPaymentWindowStatusFilterOptions(ptoId));

//   TERMINAL STATUS
export const getTerminalStatusFilterOptions = async (ptoId: number) => {
  const response = await api.get(`/pto/${ptoId}/enum/terminal_status`);
  return response.data;
};
export const useTerminalStatusFiltersQuery = (ptoId: number) =>
  useQuery(['getTerminalStatusFilterOptions'], () =>
    getTerminalStatusFilterOptions(ptoId),
  );

//   TERMINAL TYPE
export const getTerminalTypeFilterOptions = async (ptoId: number) => {
  const response = await api.get(`/pto/${ptoId}/enum/terminal_type`);
  return response.data;
};
export const useTerminalTypeFiltersQuery = (ptoId: number) =>
  useQuery(['getTerminalTypeFilterOptions'], () => getTerminalTypeFilterOptions(ptoId));

type TransactionStatus = Record<string, string>;

//   TRANSACTION STATUS
export const getTransactionStatusFilterOptions = async (ptoId: number) => {
  const response = await api.get<TransactionStatus>(
    `/pto/${ptoId}/enum/transaction_status`,
  );

  return Object.values(response.data);
};
export const useTransactionStatusFiltersQuery = (ptoId: number) =>
  useQuery(['getTransactionStatusFilterOptions'], () =>
    getTransactionStatusFilterOptions(ptoId),
  );

type TransactionType = Record<string, string>;

//   TRANSACTION TYPE
export const getTransactionTypeFilterOptions = async (ptoId: number) => {
  const response = await api.get<TransactionType>(`/pto/${ptoId}/enum/transaction_type`);
  return Object.values(response.data);
};
export const useTransactionTypeFiltersQuery = (ptoId: number) =>
  useQuery(['getTransactionTypeFilterOptions'], () =>
    getTransactionTypeFilterOptions(ptoId),
  );

type CardIssuer = Record<string, string>;

//   CARD ISSUER
export const getCardIssuerFilterOptions = async (ptoId: number) => {
  const response = await api.get<CardIssuer>(`/pto/${ptoId}/enum/card_issuer`);
  return Object.values(response.data);
};
export const useCardIssuerFiltersQuery = (ptoId: number) =>
  useQuery(['getCardIssuerFilterOptions'], () => getCardIssuerFilterOptions(ptoId));

type CardSchema = Record<string, string>;

//   CARD SCHEMA
export const getCardSchemaFilterOptions = async (ptoId: number) => {
  const response = await api.get<CardSchema>(`/pto/${ptoId}/enum/card_schema`);
  return Object.values(response.data);
};
export const useCardSchemaFiltersQuery = (ptoId: number) =>
  useQuery(['getCardSchemaFilterOptions'], () => getCardSchemaFilterOptions(ptoId));

type CardOrigin = Record<string, string>;

//   CARD ORIGIN
export const getCardOriginFilterOptions = async (ptoId: number) => {
  const response = await api.get<CardOrigin>(`/pto/${ptoId}/enum/card_origin`);
  return Object.values(response.data);
};
export const useCardOriginFiltersQuery = (ptoId: number) =>
  useQuery(['getCardOriginFilterOptions'], () => getCardOriginFilterOptions(ptoId));

type LineEnum = Record<string, string>;

//   LINES
export const getLinesFilterOptions = async (ptoId: number) => {
  const response = await api.get<LineEnum>(`/pto/${ptoId}/enum/lines`);
  return Object.values(response.data);
};
export const useLinesFiltersQuery = (ptoId: number) =>
  useQuery(['getLinesFilterOptions'], () => getLinesFilterOptions(ptoId));

type PaymentDeviceEnum = Record<string, string>;

//   PAYMENT DEVICES
export const getPaymentDevicesFilterOptions = async (ptoId: number) => {
  const response = await api.get<PaymentDeviceEnum>(`/pto/${ptoId}/enum/payment_device`);
  return Object.values(response.data);
};
export const usePaymentDevicesFiltersQuery = (ptoId: number) =>
  useQuery(['getPaymentDevicesFilterOptions'], () =>
    getPaymentDevicesFilterOptions(ptoId),
  );

type PaymentType = Record<string, string>;

//   PAYMENT DEVICES
export const getPaymentTypesFilterOptions = async (ptoId: number) => {
  const response = await api.get<PaymentType>(`/pto/${ptoId}/enum/payment_type`);
  return Object.values(response.data);
};
export const usePaymentTypesFiltersQuery = (ptoId: number) =>
  useQuery(['getPaymentTypesFilterOptions'], () => getPaymentTypesFilterOptions(ptoId));

type CardProductsType = Record<string, string>;

//   CARD PRODUCTS
export const getCardProductsFilterOptions = async (ptoId: number) => {
  const response = await api.get<CardProductsType>(`/pto/${ptoId}/enum/card_product`);
  return Object.values(response.data);
};
export const useCardProductsFiltersQuery = (ptoId: number) =>
  useQuery(['getCardProductsFilterOptions'], () => getCardProductsFilterOptions(ptoId));
