import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import AppLink from 'components/appLink/appLink';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { AppTableProps, AppTable } from 'components/appTable';
import { useMemo } from 'react';
import { Transaction } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useTranslation } from 'react-i18next';

interface LatestTransactionProps {
  data: Transaction[];
}

export const LatestTransaction: React.FC<LatestTransactionProps> = ({ data }) => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.transactionsPage' });

  const LATEST_TRANSACTION_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'date',
        headerName: t('table.date'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction>) => {
          const { row } = params;
          return (
            <Box>
              <Typography component="div" fontWeight={'bold'}>
                {formatDateToDay(row.date_terminal, 'DD.MM.YYYY')}
              </Typography>
              <Typography component="div">
                {formatDateToDay(row.date_terminal, 'hh:mm:ss')}
              </Typography>
            </Box>
          );
        },
      },
      {
        field: 'amount',
        headerName: t('table.amountAndCurrency'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;
          return (
            <Box>
              <Typography component="div" fontWeight={'bold'}>
                {row.amount}
              </Typography>
              <Typography component="div">{row.currency}</Typography>
            </Box>
          );
        },
      },
      {
        field: 'status',
        headerName: t('table.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;

          return (
            <AppStatusHighlight displayCircle={false} status={row.status}>
              {row.status}
            </AppStatusHighlight>
          );
        },
      },
      { field: 'type', headerName: t('table.opType'), flex: 1 },
      {
        field: 'approvalCode',
        headerName: t('table.approvalCode'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;

          return <>{row.approval_code}</>;
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<Transaction>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/transaction/${row.id}`} isGreyLink>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );
  return (
    <AppTable
      rows={data ?? []}
      columns={LATEST_TRANSACTION_COLUMNS}
      appTitle={t('tableTitle')}
      hideHeaderRow
    />
  );
};
