// import { ROOT_URL } from 'pages/appRoutesConst';
import { useCallback, useEffect } from 'react';
// import { useNavigate } from 'react-router';

const useDomEvents = () => {
  // const navigate = useNavigate();

  const navigateToLogin = useCallback((event) => {
    // navigate(ROOT_URL);
  }, []);

  useEffect(() => {
    document.addEventListener('build', (event) => navigateToLogin(event));

    return () => {
      document.removeEventListener('build', (event) => navigateToLogin(event), false);
    };
  }, []);
};

export default useDomEvents;
