import {
  Avatar,
  ClickAwayListener,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SvgIconTypeMap,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useState } from 'react';
import { Lock, Info, Logout } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { useTranslation } from 'react-i18next';
interface MenuItem {
  label: string;
  onClick: () => void;
  icon: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>> & {
    muiName: string;
  };
}

const menuItems = [
  {
    label: 'changePassword',
    icon: <Lock />,
    onClick: () => {
      console.log('onClick');
    },
  },
  {
    label: 'helpAndFeedback',
    icon: <Info />,
    onClick: () => {
      console.log('onClick');
    },
  },
  {
    label: 'logout',
    icon: <Logout />,
    onClick: () => {
      console.log('onClick');
    },
  },
];

export const UserAvatar = () => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation('common', { keyPrefix: 'components.avatar' });
  return (
    <>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setIsPopperOpen(!isPopperOpen);
        }}
        sx={{ p: 0 }}
      >
        <Avatar sx={{ bgcolor: 'primary.dark' }} alt="Avatar">
          <PersonIcon color="secondary" />
        </Avatar>
      </IconButton>
      <Popper
        style={{ zIndex: 10 }}
        open={isPopperOpen}
        placement="bottom-start"
        disablePortal={false}
        anchorEl={anchorEl}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (isPopperOpen) setIsPopperOpen(false);
          }}
        >
          <Paper sx={{ width: 320, maxWidth: '100%' }}>
            <MenuList>
              {menuItems.map((menuItem, index) => (
                <MenuItem key={index}>
                  <ListItemIcon>{menuItem.icon}</ListItemIcon>
                  <ListItemText>{t(menuItem.label)}</ListItemText>
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
