import { TimePeriod } from 'api/endpoints/taps';

export const DAYS_OPTIONS: { value: TimePeriod; label: string }[] = [
  { value: TimePeriod['TODAY'], label: 'enums.dayOptions.today' },
  { value: TimePeriod['YESTERDAY'], label: 'enums.dayOptions.yesterday' },
  { value: TimePeriod['THIS_WEEK'], label: 'enums.dayOptions.lastSevenDays' },
  { value: TimePeriod['THIS_MONTH'], label: 'enums.dayOptions.thisMonth' },
];

export const WIDGET_DAYS_OPTIONS: { value: TimePeriod; label: string }[] = [
  { value: TimePeriod['TODAY'], label: 'enums.dayOptions.today' },
  { value: TimePeriod['YESTERDAY'], label: 'enums.dayOptions.yesterday' },
  { value: TimePeriod['THIS_WEEK'], label: 'enums.dayOptions.lastSevenDays' },
  { value: TimePeriod['THIS_MONTH'], label: 'enums.dayOptions.thisMonth' },
  { value: TimePeriod['LAST_90_DAYS'], label: 'enums.dayOptions.lastNinetyDays' },
  { value: TimePeriod['THIS_YEAR'], label: 'enums.dayOptions.thisYear' },
];
