import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
import GridLayout, { Layout } from 'react-grid-layout';
import { Card, styled } from '@mui/material';
import { ReactElement, useMemo } from 'react';

const DragableContainer = styled(Card)(({ theme: { palette } }) => ({
  height: '100%',
  width: '100%',
  background: palette.background.paper,
}));

interface DragableGridProps {
  containers: { element: ReactElement }[];
  onResizeStop?: (
    id: string,
    position: { w: number; x: number; y: number; h: number },
  ) => void;
  onDragStop?: (
    id: string,
    position: { w: number; x: number; y: number; h: number },
  ) => void;
}

const DragableGrid: React.FC<DragableGridProps> = ({
  containers,
  onResizeStop,
  onDragStop,
}) => {
  const layout = useMemo(() => {
    return containers.map((container, index) => ({
      i: `${container.element.props.children.props.id}`,
      ...container.element.props.children.props.position,
    }));
  }, [containers]);

  return (
    <>
      {layout.length > 0 ? (
        <GridLayout
          onDragStop={async (layout, oldItem, newItem) => {
            if (onDragStop && newItem.moved) {
              onDragStop(newItem.i, {
                w: newItem.w,
                x: newItem.x,
                y: newItem.y,
                h: newItem.h,
              });
            }
          }}
          onResizeStop={async (layout, oldItem, newItem) => {
            if (onResizeStop) {
              onResizeStop(newItem.i, {
                w: newItem.w,
                x: newItem.x,
                y: newItem.y,
                h: newItem.h,
              });
            }
          }}
          className="layout"
          layout={layout}
          cols={5}
          rowHeight={30}
          width={1200}
        >
          {containers.map((cont, index) => (
            <DragableContainer key={layout[index].i}>{cont.element}</DragableContainer>
          ))}
          {/* <DragableContainer key="a">a</DragableContainer> */}
        </GridLayout>
      ) : (
        <></>
      )}
    </>
  );
};

export default DragableGrid;
