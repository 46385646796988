import { Card, CardProps, Collapse, styled, Typography, useTheme } from '@mui/material';
import { ReactElement } from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import AppButton from 'components/appButton';

const TitleRow = styled('div')(({ theme: { spacing, palette } }) => ({
  padding: spacing(2, 3),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${palette.border.darkGrey}`,
}));

const ButtonsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

const ButtonWrapper = styled('div')(({ theme: { spacing } }) => ({
  ':not(:last-of-type)': {
    marginRight: spacing(2),
  },
}));

const IconWrapper = styled('div')(({ theme: { spacing } }) => ({
  marginRight: spacing(2),
  display: 'flex',
}));

const CollapseWrapper = styled(Collapse)(({ theme: { spacing, palette } }) => ({
  backgroundColor: palette.error.main,
  color: palette.common.white,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: spacing(1.5, 3),
  '& .MuiCollapse-wrapperInner': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const SlimTitle = styled(Typography)(({ theme: { palette, spacing } }) => ({
  color: palette.text.lightGrey.main,
  marginLeft: spacing(1),
})) as typeof Typography;

// eslint-disable-next-line @typescript-eslint/ban-types
interface AppDataContainerProps extends CardProps {
  titleRow: {
    title: string;
    slimTitle?: string;
    buttons?: ReactElement[];
  };
  collapseContent?: {
    checked: boolean;
    content: [string, string];
    button?: {
      label: string;
      onClick: () => void;
    };
  };
}

export const AppDataContainer: React.FC<AppDataContainerProps> = ({
  children,
  titleRow,
  collapseContent = {
    checked: false,
  },
  sx,
}) => {
  const { spacing } = useTheme();
  const { title, buttons, slimTitle } = titleRow;
  return (
    <Card sx={{ width: '100%', ...sx }}>
      <TitleRow>
        <Typography fontWeight="bold" variant="h6">
          {title}
          {slimTitle && <SlimTitle component="span">{slimTitle}</SlimTitle>}
        </Typography>

        <ButtonsContainer>
          {buttons &&
            buttons.map((button, index) => (
              <ButtonWrapper key={index}>{button}</ButtonWrapper>
            ))}
        </ButtonsContainer>
      </TitleRow>
      {collapseContent?.content?.length === 2 && (
        <CollapseWrapper in={collapseContent?.checked}>
          <IconWrapper>
            <WarningIcon />
          </IconWrapper>
          <Typography
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              marginRight: spacing(3),
            }}
          >
            {collapseContent?.content.map((text, index) => (
              <Typography key={index} variant="h5" color="white" component="div">
                {text}
              </Typography>
            ))}
          </Typography>
          {collapseContent?.button && (
            <div style={{ width: 'fitContent' }}>
              <AppButton
                onClick={collapseContent.button.onClick}
                variant="contained"
                color="secondary"
                size="small"
              >
                {collapseContent.button.label}
              </AppButton>
            </div>
          )}
        </CollapseWrapper>
      )}
      {children}
    </Card>
  );
};
