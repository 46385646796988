import { api } from '../index';
import { SearchGroup } from 'types/commonTypes';
import { useQuery } from 'react-query';

const getSearchedTermsCollection = async (ptoId: number, text: string) => {
  const response = await api.get<SearchGroup>(`pto/${ptoId}/search/${text}`);
  return response.data;
};

export const useSearchTermsQuery = (
  ptoId: number,
  searchedText: string,
  canBeSendQuery: boolean,
) =>
  useQuery(
    ['getSearchedTerms', searchedText, canBeSendQuery],
    () => getSearchedTermsCollection(ptoId, searchedText),
    {
      enabled: searchedText.length >= 3 && canBeSendQuery,
    },
  );
