/* eslint-disable no-useless-escape */
import { Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { exportTapsData, TapsFilter, useTapsCollectionsQuery } from 'api/endpoints/taps';
import AppLink from 'components/appLink/appLink';
import { AppTableProps, AppTable } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { TapStatus } from 'components/tapStatus';
import { TransportIcon } from 'components/transportIcon';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TapInterface } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import useFilters from 'utils/hooks/filters/useFilters';
import CircleIcon from '@mui/icons-material/Circle';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { VEHICLE_DETAIL_URL } from './appRoutes/appRoutesConst';
import { useAppSelector } from 'stores/store';

export const TapsPage: React.FC = () => {
  const { ptoId } = useAppSelector((state) => state.user);
  const { t } = useTranslation('common', { keyPrefix: 'pages.tapsPage' });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });
  const { filters, filterValues } = useFilters<TapsFilter>({
    filters: ['DATE_RANGE', 'VEHICLE_TYPE', 'LINE'],
    namePairs: [
      { filterName: 'DATE_RANGE', queryname: ['date_from', 'date_to'] },
      { filterName: 'VEHICLE_TYPE', queryname: 'vehicle_type' },
      { filterName: 'LINE', queryname: 'line' },
    ],
  });
  // TODO: refactor
  const { data } = useTapsCollectionsQuery(ptoId, {
    ...filterValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    date_from: filterValues.date_from
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatDateToDay(filterValues.date_from, 'YYYY-MM-DD')
      : '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    date_to: filterValues.date_to
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatDateToDay(filterValues.date_to, 'YYYY-MM-DD')
      : '',
  });
  const TAPS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'time',
        headerName: t('table.time'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <div>
              <Typography component="div">
                {formatDateToDay(row.time, 'DD.MM.YYYY')}
              </Typography>
              <Typography component="div">
                {formatDateToDay(row.time, 'hh.mm.ss')}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'type',
        headerName: t('table.type'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return <TapStatus label={tEnums(`tapStatus.${row.type}`)} status={row.type} />;
        },
      },
      {
        field: 'vehicle',
        headerName: t('table.vehicle'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;
          return (
            <Typography
              sx={{
                gap: 1,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
              }}
            >
              <TransportIcon type={row.fleet.type} />
              <AppLink
                to={`/${VEHICLE_DETAIL_URL.replace(/\/:id$/, '')}/${row.fleet.id}`}
              >
                {row.fleet.id}
              </AppLink>
            </Typography>
          );
        },
      },
      {
        field: 'line',
        headerName: t('table.line'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <Typography
              sx={{
                gap: 1,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
              }}
            >
              <CircleIcon sx={{ color: params.row.line.color, marginRight: 1 }} />
              <AppLink to={`/line/${row.line.id}`}>{row.line.name}</AppLink>
            </Typography>
          );
        },
      },
      {
        field: 'terminal',
        headerName: t('table.terminal'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;
          return (
            <AppLink to={`/terminal/${row.terminal_detail_id}`}>
              {row.terminal_detail_id}
            </AppLink>
          );
        },
      },
      {
        field: 'token_id',
        headerName: t('table.tokenId'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return <AppLink to={`/token/${row.token_id}`}>{row.token_id}</AppLink>;
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/tap/${row.id}`}>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );
  return (
    <div>
      <AppBreadcrumbs />
      {filters}
      <AppTable
        rows={data?.data ?? []}
        columns={TAPS_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        exportMenuItems={[
          {
            onClick: async () => await exportTapsData(ptoId, 'xlxs'),
            label: 'XLXS',
          },
          {
            onClick: async () => await exportTapsData(ptoId, 'csv'),
            label: 'CSV',
          },
        ]}
      />
    </div>
  );
};
