import DragableGrid from 'components/layouts/gridLayout';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WidgetContainer } from 'components/widgetContainer';
import { getWidget, updateWidget, useWidgetsQuery } from 'api/endpoints/widgets';
import { useAppSelector } from 'stores/store';
import { alpha, Box, Dialog, Typography, useTheme } from '@mui/material';
import { CreateWidget } from 'components/widgetContainer/dialogs/createWidget';
import AppButton from 'components/appButton';
import { Widget } from 'types/commonTypes';
import { TimePeriod } from 'api/endpoints/taps';

const DashboardPage = () => {
  const [isCreateWidgetModalOpen, setIsCreateWidgetModalOpen] = useState(false);
  const [number, setNumber] = useState(1);
  const { ptoId } = useAppSelector((state) => state.user);
  const { t } = useTranslation('common', {
    keyPrefix: 'pages.dashboard',
  });
  const widgets = useWidgetsQuery(ptoId, number);
  const theme = useTheme();

  const closeCreateWidgetModal = () => setIsCreateWidgetModalOpen(false);

  const onResizeStop = useCallback(
    async (id: string, position: Widget['position']) => {
      const parsedId = parseInt(id);
      const foundWidget = widgets.data?.find((widget) => widget.id === parsedId);
      if (foundWidget) {
        await updateWidget(ptoId, parsedId, {
          type: foundWidget.type,
          position,
          name: foundWidget.name,
          settings: foundWidget.settings,
        });
      }
    },
    [widgets],
  );

  const getsingleWidget = async (widgetId: number, timePeriod: TimePeriod) => {
    const response = await getWidget(ptoId, widgetId, timePeriod);
    if (response.status > 199 && response.status < 300) {
      return response.data;
    }
  };

  const widgetsInContainers = useMemo(() => {
    return widgets.data?.map((widget, index) => ({
      element: (
        <React.Fragment key={index}>
          <WidgetContainer
            {...widget}
            updateCallback={() => setNumber(number + 1)}
            onTimeSelectChange={(time) => {
              const data = getsingleWidget(widget.id, time);
              widget.data[index] === data;
            }}
          />
        </React.Fragment>
      ),
    }));
  }, [widgets.data]);

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">{t('pageTitle')}</Typography>
        <AppButton
          variant="contained"
          onClick={() => setIsCreateWidgetModalOpen(true)}
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.common.white,
            padding: theme.spacing(2, 8),
            ':hover': {
              backgroundColor: alpha(theme.palette.secondary.main, 0.8),
            },
          }}
        >
          {t('createWidget')}
        </AppButton>
      </Box>

      <Dialog open={isCreateWidgetModalOpen}>
        <CreateWidget
          createCallBack={() => setNumber(number + 1)}
          handleClose={closeCreateWidgetModal}
        />
      </Dialog>

      <DragableGrid
        containers={widgetsInContainers || []}
        onResizeStop={onResizeStop}
        onDragStop={onResizeStop}
      />
    </div>
  );
};

export default DashboardPage;
