import { clearCustomer, useCustomerDetailQuery } from 'api/endpoints/customer';
import AppButton from 'components/appButton';
import { AppDataContainer } from 'components/appDataContainer';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { TwoColumnContainer } from 'components/appDataContainer/twoColumnContainer';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDateToDay } from 'utils/funcs/formatDate';
import { useMemo, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import HeatMap from 'components/maps/HeatMap';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { PaymentWindow, Token } from 'types/commonTypes';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {
  ACTIVITY_URL,
  JOURNEYS_URL,
  PAYMENTS_URL,
  PAYMENT_WINDOWS_URL,
  PAYMENT_WINDOW_DETAIL_URL,
  PEOPLE_URL,
  TAPS_URL,
  TOKENS_URL,
  TOKEN_DETAIL_URL,
} from 'pages/appRoutes/appRoutesConst';
import { TranslateFunc } from 'components/appDataContainer/utility';
import { usePaymentWindowsCollectionsQuery } from 'api/endpoints/paymentWindows';
import { useAppSelector } from 'stores/store';
import { AppTable, AppTableProps } from 'components/appTable';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import AppLink from 'components/appLink/appLink';
import { PaymentWindowStatus } from 'components/paymentWindowStatus';
import { useTokensCollectionsQuery } from 'api/endpoints/tokens';
import { CustomerStatus } from 'types/enumTypes';

const LAST_X_DAYS = 30;

const customeRenderFunctions = (translateFunc: TranslateFunc) => {
  return {
    last_tap: (value: string) => formatDateToDay(value, 'YYYY-MM-DD / hh:mm:ss'),
    reg_date: (value: string) => formatDateToDay(value, 'YYYY-MM-DD / hh:mm:ss'),
    status: (value: string) => (
      <AppStatusHighlight status={value as CustomerStatus}>
        {translateFunc(value)}
      </AppStatusHighlight>
    ),
  };
};

export const CustomerDetailPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.customerDetailPage' });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums.customerStatus' });
  const { t: tPaymentWindows } = useTranslation('common', {
    keyPrefix: 'pages.paymentWindowsPage',
  });
  const { t: tTokens } = useTranslation('common', { keyPrefix: 'pages.tokensPage' });

  const { palette } = useTheme();
  const { ptoId } = useAppSelector((state) => state.user);
  const { id: customerId } = useParams();
  const { data } = useCustomerDetailQuery(ptoId, customerId || '');
  const { data: paymentWindowsData } = usePaymentWindowsCollectionsQuery(ptoId);
  const { data: tokensData } = useTokensCollectionsQuery(ptoId);
  const navigate = useNavigate();
  const [showWarning, setShowWarning] = useState(data?.debt?.in_debt ?? false);

  const handleClearDebt = async () => {
    const response = await clearCustomer(ptoId, customerId ?? '');
    if (response.status > 199 && response.status < 300) setShowWarning(false);
  };

  const TOKENS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      { field: 'id', headerName: tTokens('table.id') },
      {
        field: 'status',
        headerName: tTokens('table.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Token>) => {
          const { row } = params;
          return (
            <AppStatusHighlight status={row.status}>{row.status}</AppStatusHighlight>
          );
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<Token>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/token/${row.id}`} isGreyLink={true}>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  const PAYMENT_WINDOWS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'amount',
        headerName: tPaymentWindows('table.amountAndCurrency'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          return (
            <Typography
              fontWeight={'bold'}
              color={palette.text.darkGrey.main}
            >{`${params.row.amount} ${params.row.currency}`}</Typography>
          );
        },
      },
      {
        field: 'status',
        headerName: tPaymentWindows('table.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          const { row } = params;
          return <PaymentWindowStatus status={row.status} />;
        },
      },
      {
        field: 'tokenID',
        headerName: tPaymentWindows('table.id'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          return (
            <AppLink to={`/${TOKEN_DETAIL_URL.replace(/\/:id$/, '')}/${params.row.id}`}>
              {params.row.id}
            </AppLink>
          );
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<PaymentWindow>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink
                  to={`/${PAYMENT_WINDOW_DETAIL_URL.replace(/\/:id$/, '')}/${row.id}`}
                  isGreyLink
                >
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <Box sx={{ marginBottom: 2 }}>
        <AppBreadcrumbs
          customBreadCrumbs={[
            { index: 1, breadCrumbLabel: `${data?.name ?? ''}`, route: '/' },
          ]}
        />
      </Box>
      <AppDataContainer
        collapseContent={{
          checked: showWarning,
          content: [
            t('customerDebt'),
            `${data?.debt?.total || ''} ${data?.debt?.currency || ''}`,
          ],
          button: { label: t('payDebt'), onClick: () => handleClearDebt() },
        }}
        titleRow={{
          title: t('pageTitle'),
          // buttons: [
          //   <AppButton
          //     variant="contained"
          //     color="error"
          //     size="small"
          //     startIcon={<DeleteIcon />}
          //     key={0}
          //   >
          //     {t('deleteCustomer')}
          //   </AppButton>,
          //   <AppButton
          //     variant="contained"
          //     color="secondary"
          //     size="small"
          //     startIcon={<EditIcon />}
          //     key={1}
          //   >
          //     {t('editCustomer')}
          //   </AppButton>,
          // ],
        }}
      >
        <TwoColumnContainer
          renderData={customeRenderFunctions(tEnums)}
          columns={[
            ['name', 'status', 'birthDate', 'discount', 'address'],
            ['email', 'phone', 'last_tap', 'reg_date', 'id'],
          ]}
          dataObj={data ?? {}}
          translateFunc={(k: string): string => {
            return t(k);
          }}
        />
      </AppDataContainer>
      <Box
        sx={{
          marginTop: 8,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 5,
        }}
      >
        <AppDataContainer
          titleRow={{
            title: t('tapsCard.title'),
            slimTitle: t('tapsCard.historyRange', { days: LAST_X_DAYS }),
            buttons: [
              <AppButton
                onClick={() =>
                  navigate(`/${ACTIVITY_URL}/${TAPS_URL}`, { replace: true })
                }
                variant="contained"
                color="secondary"
                size="small"
                key={0}
              >
                {t('tapsCard.details')}
              </AppButton>,
            ],
          }}
        >
          {/* TODO: change to received data */}
          <HeatMap heatPoints={[{ lat: 37.782, lng: -122.443, weight: 2 }]} />
        </AppDataContainer>
        <AppDataContainer
          titleRow={{
            title: t('journeysCard.title'),
            slimTitle: t('journeysCard.historyRange', { days: LAST_X_DAYS }),
            buttons: [
              <AppButton
                onClick={() =>
                  navigate(`/${ACTIVITY_URL}/${JOURNEYS_URL}`, { replace: true })
                }
                variant="contained"
                color="secondary"
                size="small"
                key={0}
              >
                {t('journeysCard.details')}
              </AppButton>,
            ],
          }}
        ></AppDataContainer>
      </Box>
      <Box
        sx={{
          marginTop: 8,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 5,
        }}
      >
        <AppDataContainer
          collapseContent={{
            checked: showWarning,
            content: [
              t('customerDebt'),
              `${data?.debt?.total || ''} ${data?.debt?.currency || ''}`,
            ],
            button: { label: t('payDebt'), onClick: () => handleClearDebt() },
          }}
          titleRow={{
            title: t('paymentWindowCard.title'),
            buttons: [
              <AppButton
                onClick={() =>
                  navigate(`/${PAYMENTS_URL}/${PAYMENT_WINDOWS_URL}`, { replace: true })
                }
                variant="contained"
                color="secondary"
                size="small"
                key={0}
              >
                {t('paymentWindowCard.details')}
              </AppButton>,
            ],
          }}
        >
          <AppTable
            rows={paymentWindowsData?.data ?? []}
            columns={PAYMENT_WINDOWS_COLUMNS}
            appTitle={''}
            showSettings={false}
          />
        </AppDataContainer>
        <AppDataContainer
          titleRow={{
            title: t('tokensCard.title'),
            buttons: [
              <AppButton
                onClick={() =>
                  navigate(`/${PEOPLE_URL}/${TOKENS_URL}`, { replace: true })
                }
                variant="contained"
                color="secondary"
                size="small"
                key={0}
              >
                {t('tokensCard.details')}
              </AppButton>,
            ],
          }}
        >
          <AppTable
            rows={tokensData?.data ?? []}
            columns={TOKENS_COLUMNS}
            appTitle={''}
            showSettings={false}
          />
        </AppDataContainer>
      </Box>
    </div>
  );
};
