import {
  DialogTitle,
  Typography,
  IconButton,
  Box,
  useTheme,
  MenuItem,
  alpha,
} from '@mui/material';
import AppButton from 'components/appButton';
import AppTextField from 'components/form/textField';
import { useState } from 'react';
import { WidgetState } from '..';
import CloseIcon from '@mui/icons-material/Close';
import { createWidget } from 'api/endpoints/widgets';
import { useAppSelector } from 'stores/store';
import AppSelect from 'components/form/select';
import { useTranslation } from 'react-i18next';
import { TimePeriod } from 'api/endpoints/taps';
import { cloneDeep } from 'lodash';
import { WidgetTypes } from 'types/enumTypes';

const DEFAULT_WIDGET_POSITION = { x: 0, y: 999, w: 10, h: 10 };

interface CreateWidgetProps {
  handleClose: () => void;
  createCallBack?: () => void;
}
export const CreateWidget: React.FC<CreateWidgetProps> = ({
  handleClose,
  createCallBack,
}) => {
  const { ptoId } = useAppSelector((state) => state.user);
  const { t } = useTranslation('common', { keyPrefix: 'components.createWidget' });
  const { t: tEnum } = useTranslation('common', { keyPrefix: 'enums' });
  const [createWidgetState, setCreateWidgetState] = useState<
    Omit<WidgetState<{ time_period?: TimePeriod }>, 'id'>
  >({
    type: WidgetTypes['LATEST_TRANSACTIONS'],
    position: DEFAULT_WIDGET_POSITION,
    name: '',
    settings: {
      time_period: TimePeriod['TODAY'],
    },
  });
  const theme = useTheme();
  const { spacing } = theme;

  const createW = async () => {
    const updatedState = cloneDeep(createWidgetState);
    if (updatedState.type !== WidgetTypes['NETWORK_LOAD']) {
      delete updatedState.settings.time_period;
    }
    const response = await createWidget(ptoId, updatedState);
    if (response) {
      if (createCallBack) createCallBack();
      handleClose();
    }
  };

  return (
    <>
      {' '}
      <DialogTitle
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="h5">{t('widgetTitle')}</Typography>
        <IconButton key="Settings" onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Box
        sx={{
          padding: spacing(3),
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
        }}
      >
        <AppSelect
          fullWidth
          label={t('chooseWidgetType')}
          onChange={(e) =>
            setCreateWidgetState({
              ...createWidgetState,
              type: e.target.value as WidgetTypes,
            })
          }
          value={createWidgetState.type}
        >
          {Object.entries(WidgetTypes).map((entry, index) => (
            <MenuItem key={index} value={entry[1]}>
              {tEnum(`widgetTypes.${entry[1]}`)}
            </MenuItem>
          ))}
        </AppSelect>
        {createWidgetState.type === WidgetTypes['NETWORK_LOAD'] && (
          <AppSelect
            fullWidth
            label={t('chooseTimePeriod')}
            onChange={(e) =>
              setCreateWidgetState({
                ...createWidgetState,
                settings: {
                  ...createWidgetState.settings,
                  time_period: e.target.value as TimePeriod,
                },
              })
            }
            value={createWidgetState.settings.time_period}
          >
            {Object.entries(TimePeriod).map((entry, index) => (
              <MenuItem key={index} value={entry[1]}>
                {tEnum(`dayOptions.${entry[1].toLowerCase()}`)}
              </MenuItem>
            ))}
          </AppSelect>
        )}
        <AppTextField
          label={t('customName')}
          value={createWidgetState.name}
          onChange={(e) =>
            setCreateWidgetState({ ...createWidgetState, name: e.target.value })
          }
        />

        <Box sx={{ margin: 'auto' }}>
          <AppButton
            sx={{
              backgroundColor: theme.palette.secondary.main,
              color: theme.palette.common.white,
              padding: theme.spacing(2, 8),
              ':hover': {
                backgroundColor: alpha(theme.palette.secondary.main, 0.8),
              },
            }}
            onClick={() => createW()}
            variant="contained"
          >
            {t('confirm')}
          </AppButton>
        </Box>
      </Box>
    </>
  );
};
