import * as React from 'react';
import FormField from '../formField';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AppTextField from '../textField';
import { formatDateToDay } from 'utils/funcs/formatDate';

interface DateRPickerProps {
  value: string;
  onChange: (...event: any[]) => void;
  label: string;
}

export const AppDatePicker: React.FC<DateRPickerProps> = ({
  value,
  onChange,
  label,
  ...rest
}) => {
  return (
    <FormField label={label} error={false} errorText={''} required={false}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        sx={{
          BackgroundColor: 'red',
        }}
      >
        <DatePicker
          value={value}
          onChange={(value, keyboardInputValue) => {
            let newValue = '';
            if (value) newValue = formatDateToDay(value, 'DD/MM/YYYY');
            onChange(newValue);
          }}
          renderInput={(params) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            params.inputProps.value = value;
            params.error = false;
            return <AppTextField {...params} value={value} {...rest} />;
          }}
        />
      </LocalizationProvider>
    </FormField>
  );
};
