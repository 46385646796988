import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Badge,
  Box,
  Card,
  ClickAwayListener,
  IconButton,
  Popper,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { useState } from 'react';
import { formatDateToDay, formatToBeforeDays } from 'utils/funcs/formatDate';
import {
  NotificationFilter,
  useNotificationCollectionsQuery,
} from '../../../api/endpoints/notifications';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Notification } from '../../../types/commonTypes';
import AppLink from '../../appLink/appLink';
import { NOTIFICATION_URL } from '../../../pages/appRoutes/appRoutesConst';
import { useTranslation } from 'react-i18next';

const ReadMarker = styled('div')(({ theme: { palette, spacing } }) => ({
  backgroundColor: palette.primary.dark,
  width: '16px',
  height: '16px',
  borderRadius: '50%',
}));

const TypographyWrapper = styled('div')(({ theme: { palette, spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(1),
}));

const NotificationItemWrapper = styled('div')(({ theme: { spacing, palette } }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: spacing(1.25, 1.875),
  maxWidth: spacing(43.75),
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: palette.primary.main,
  },
  transition: ' 0.3s',
}));

const AllNotification = styled('div')(({ theme: { spacing, palette, typography } }) => ({
  color: palette.primary.dark,
  fontSize: spacing(2),
  fontWeight: typography.fontWeightMedium,
  borderTop: `1px solid ${palette.border.darkGrey}`,
  padding: spacing(2.5, 1.875),
  '&:hover': {
    cursor: 'pointer',
  },
}));

const NotificationItem: React.FC<{ item: Notification }> = ({ item }) => {
  const { content, date_opened, read } = item;
  const theme = useTheme();
  return (
    <NotificationItemWrapper>
      <TypographyWrapper>
        <Typography
          color="text.darkGrey.main"
          fontSize={theme.spacing(1.75)}
          fontWeight="bold"
        >
          {content}
        </Typography>
        {/*<Typography*/}
        {/*  color="text.darkGrey.main"*/}
        {/*  fontSize={theme.spacing(1.75)}*/}
        {/*  fontWeight="normal"*/}
        {/*>*/}
        {/* Notification Hash ansjdaksjndkasdnkjasjn1318923123u89*/}
        {/*</Typography>*/}
        <Typography
          color={theme.palette.primary.dark}
          fontSize={theme.spacing(1.5)}
          fontWeight="normal"
        >
          {formatToBeforeDays(date_opened)}
        </Typography>
      </TypographyWrapper>

      {!read && (
        <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
          <ReadMarker />
        </Box>
      )}
    </NotificationItemWrapper>
  );
};

export const NotificationBell = () => {
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { data, isLoading } = useNotificationCollectionsQuery(0);
  const { t } = useTranslation('common', { keyPrefix: 'pages.notificationPage' });

  const [unreadNotificationCount, setUnreadNotificationCount] = useState(
    data?.data?.filter((item) => item.read).length,
  );

  const theme = useTheme();

  return (
    <>
      <IconButton
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setIsPopperOpen(!isPopperOpen);
        }}
      >
        <Badge badgeContent={unreadNotificationCount} color="error">
          <NotificationsIcon color="secondary" />
        </Badge>
      </IconButton>

      <Popper
        style={{ zIndex: 10 }}
        open={isPopperOpen}
        placement="bottom-start"
        disablePortal={false}
        anchorEl={anchorEl}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (isPopperOpen) setIsPopperOpen(false);
          }}
        >
          <Card
            sx={{
              width: '100%',
              padding: 1,
            }}
          >
            <Box
              sx={{
                maxHeight: theme.spacing(50),
                overflowY: 'auto',
              }}
            >
              {isLoading ? (
                <Skeleton width={theme.spacing(43.75)} count={3} />
              ) : (
                data?.data?.map((notification, index) => (
                  <NotificationItem key={index} item={notification} />
                ))
              )}
            </Box>
            <AppLink to={`${NOTIFICATION_URL}`} style={{ textDecoration: 'none' }}>
              <AllNotification>{t('seeAllNotification')}</AllNotification>
            </AppLink>
          </Card>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
