import { useTheme } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

const AppLink: React.FC<LinkProps & { isExternal?: boolean; isGreyLink?: boolean }> = ({
  children,
  isExternal,
  isGreyLink,
  ...rest
}) => {
  const theme = useTheme();

  if (isExternal && !!rest.to) {
    return (
      <a
        href={rest.to as string}
        target={'_blank'}
        rel="noreferrer"
        style={{ color: theme.palette.text.darkBlue.main }}
      >
        {children}
      </a>
    );
  }
  return (
    <Link
      style={{
        color: isGreyLink
          ? theme.palette.text.darkGrey.main
          : theme.palette.text.darkBlue.main,
      }}
      {...rest}
    >
      {children}
    </Link>
  );
};

export default AppLink;
