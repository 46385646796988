import { styled } from '@mui/material';
import { NAVBAR_FIELDS, StandardNavItem } from './standardNavItem';

interface StandardNavigationProps {
  languageSelect: JSX.Element;
  isCollapsed: boolean;
  handleToggleCollapsed: () => void;
}

const NavItemsWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: spacing(2),
  flexWrap: 'wrap',
}));

export const StandardNavigation: React.FC<StandardNavigationProps> = ({
  isCollapsed = false,
  handleToggleCollapsed,
}) => {
  return (
    <>
      <NavItemsWrapper>
        {NAVBAR_FIELDS.map((field, index) => (
          <StandardNavItem
            handleToggleCollapsed={handleToggleCollapsed}
            isCollapsed={isCollapsed}
            key={index}
            field={field}
          />
        ))}
      </NavItemsWrapper>
    </>
  );
};
