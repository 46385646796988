import {
  ColumnConfig,
  DataObj,
  RenderData,
  renderKey,
  renderValue,
  TranslateFunc,
} from '../utility';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface TwoColumnContainerProps {
  columns: [ColumnConfig];
  dataObj: DataObj;
  translateFunc: TranslateFunc;
  renderData?: RenderData;
}

export const OneColumnContainer: React.FC<TwoColumnContainerProps> = ({
  columns,
  dataObj,
  translateFunc,
  renderData,
}) => {
  return (
    <TableContainer component={Paper} sx={{ boxShadow: 'unset' }}>
      <Table sx={{ minWidth: 380 }}>
        <TableBody>
          {columns.map((column, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {renderKey(column, translateFunc)}
              </TableCell>
              <TableCell>{renderValue(column, dataObj, renderData)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
