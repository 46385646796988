import { api } from '../index';
import { Fleet } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from 'utils/funcs/downloadFile';

export interface FleetFilter {
  pto_id: number;
  terminal_id: number;
  vehicle_type: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

const getFleetsCollection = async (ptoId: number, params: Partial<FleetFilter>) => {
  const response = await api.get<ApiResponse<Fleet[]>>(`/pto/${ptoId}/fleet`, { params });
  return response.data;
};

export const useFleetsCollectionsQuery = (ptoId: number, filterParams = {}) => {
  return useQuery(['getFleets', ptoId, filterParams], () => {
    return getFleetsCollection(ptoId, filterParams);
  });
};

export const exportFleetsData = async (
  ptoId: number,
  type: FileType,
  filterParams = {},
) => {
  return await getFile(
    `/pto/${ptoId}/fleet/export/${type}`,
    type,
    'Customer',
    filterParams,
  );
};

const getFleetDetail = async (
  ptoId: number,
  fleetId: string,
  params: Partial<FleetFilter>,
) => {
  const response = await api.get<Fleet>(`/pto/${ptoId}/fleet/${fleetId}`, { params });
  return response.data;
};

export const useFleetDetailQuery = (ptoId: number, fleetId: string, filterParams = {}) =>
  useQuery(['getFleetDetail', ptoId, fleetId, filterParams], () =>
    getFleetDetail(ptoId, fleetId, filterParams),
  );
