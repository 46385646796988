import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface User {
  isAuthenticated: boolean;
  ptoId: number;
}

const initialState: User = {
  isAuthenticated: true,
  ptoId: 1,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserPto: (state, action: PayloadAction<number>) => ({
      ...state,
      ptoId: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
// export const {} = userSlice.actions;
export const { setUserPto } = userSlice.actions;

export default userSlice.reducer;
