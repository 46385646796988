import { Card, IconButton, Typography, useTheme } from '@mui/material';
import { DataGridPro, GridColumns } from '@mui/x-data-grid-pro';
import { csCZ, deDE, enUS } from '@mui/x-data-grid/locales';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SettingsIcon from '@mui/icons-material/Settings';
import MapIcon from '@mui/icons-material/Map';
import TableChartIcon from '@mui/icons-material/TableChart';
import AppMenuButton from 'components/appMenuButton';

export interface AppTableProps {
  rows: readonly {
    [key: string]: any;
  }[];
  columns: GridColumns;
  rowsPerPage?: number[];
  initalPage?: number;
  appTitle: string;
  // TODO: change component type
  mapComponent?: any;
  showSettings?: boolean;
  showExportButton?: boolean;
  exportMenuItems?: { onClick: () => void; label: string }[];
  hideHeaderRow?: boolean;
}

export const AppTable = ({
  rows,
  columns,
  rowsPerPage = [5, 10, 20],
  initalPage = 1,
  appTitle,
  mapComponent,
  showSettings = true,
  showExportButton = true,
  hideHeaderRow = false,
  exportMenuItems,
}: AppTableProps) => {
  const [pageSize, setPageSize] = useState(rowsPerPage[0]);
  const [localeText, setLocaleText] = useState(enUS);
  const [isMapDisplayed, setIsMapDisplayed] = useState(false);
  const {
    t,
    i18n: { language },
  } = useTranslation('common', { keyPrefix: 'components.table' });
  const theme = useTheme();

  useEffect(() => {
    if (language === 'en') setLocaleText(enUS);
    if (language === 'cs') setLocaleText(csCZ);
    if (language === 'de') setLocaleText(deDE);
  }, [language]);
  return (
    <Card sx={{ width: '100%' }}>
      {!hideHeaderRow && (
        <Typography
          component="div"
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '8px',
          }}
        >
          <Typography component="div" sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h5" component="div">
              {appTitle}
            </Typography>
            {mapComponent && (
              <IconButton onClick={() => setIsMapDisplayed(!isMapDisplayed)}>
                {isMapDisplayed ? <TableChartIcon /> : <MapIcon />}
              </IconButton>
            )}
          </Typography>
          <div>
            {showSettings && (
              <IconButton>
                <SettingsIcon />
              </IconButton>
            )}
            {showExportButton && exportMenuItems && exportMenuItems?.length > 0 && (
              <AppMenuButton
                startIcon={<DownloadIcon />}
                endIcon={<KeyboardArrowDownIcon />}
                color="secondary"
                size="small"
                variant="contained"
                menuItems={exportMenuItems}
              >
                {t('export')}
              </AppMenuButton>
            )}
          </div>
        </Typography>
      )}
      {isMapDisplayed ? (
        mapComponent
      ) : (
        <DataGridPro
          localeText={localeText.components.MuiDataGrid.defaultProps.localeText}
          columns={columns as unknown as GridColumns}
          rows={rows}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={rowsPerPage}
          pagination
          autoHeight
          initialState={{
            pagination: {
              page: initalPage,
            },
          }}
          sx={{
            borderRadius: '0px',
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: 'none',
            color: theme.palette.text.darkGrey.main,
          }}
        />
      )}
    </Card>
  );
};
