import { api } from '../index';
import { PaymentWindow, PaymentWindowDetail } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from 'utils/funcs/downloadFile';

export interface PaymentWindowFilter {
  pto_id: number;
  token_id: string;
  customer_name: string;
  payment_window_status: string;
  date_cleared: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

const getPaymentWindowsCollection = async (
  ptoId: number,
  params: Partial<PaymentWindowFilter>,
) => {
  const response = await api.get<ApiResponse<PaymentWindow[]>>(
    `/pto/${ptoId}/paywindow`,
    { params },
  );
  return response.data;
};

export const usePaymentWindowsCollectionsQuery = (ptoId: number, filterParams = {}) =>
  useQuery(['getPaymentWindows', ptoId, filterParams], () =>
    getPaymentWindowsCollection(ptoId, filterParams),
  );

export const exportPaymentWindowsData = async (
  ptoId: number,
  type: FileType,
  filterParams = {},
) => {
  return await getFile(
    `/pto/${ptoId}/paywindow/export/${type}`,
    type,
    'PaymentWindows',
    filterParams,
  );
};

const getPaymentWindowDetail = async (ptoId: number, fleetId: string) => {
  const response = await api.get<PaymentWindowDetail>(
    `/pto/${ptoId}/paywindow/${fleetId}`,
  );
  return response.data;
};

export const usePaymentWindowDetailQuery = (ptoId: number, paymentWindowId: string) =>
  useQuery(['getPaymentWindowDetail', ptoId, paymentWindowId], () =>
    getPaymentWindowDetail(ptoId, paymentWindowId),
  );
