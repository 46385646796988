import { TransportType } from 'types/commonTypes';
import TramIcon from '@mui/icons-material/Tram';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import PedalBikeIcon from '@mui/icons-material/PedalBike';

export const TransportIcon: React.FC<{ type: TransportType }> = ({ type }) => {
  let icon = <DirectionsBusIcon />;

  if (type === 'metro') icon = <TramIcon />;
  if (type === 'tram') icon = <DirectionsTransitIcon />;
  if (type === 'bicycle') icon = <PedalBikeIcon />;

  return icon;
};
