import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  CustomerFilter,
  exportCustomersData,
  useCustomerCollectionsQuery,
} from 'api/endpoints/customer';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { AppTable, AppTableProps } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Customer } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import useFilters from 'utils/hooks/filters/useFilters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AppLink from 'components/appLink/appLink';
import { useAppSelector } from 'stores/store';

export const CustomersPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.customersPage' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { filters, filterValues } = useFilters<CustomerFilter>({
    filters: ['CUSTOMER_NAME', 'CUSTOMER_STATUS'],
    namePairs: [
      { filterName: 'CUSTOMER_NAME', queryname: 'name' },
      { filterName: 'CUSTOMER_STATUS', queryname: 'customer_status' },
    ],
  });
  const { data } = useCustomerCollectionsQuery(ptoId, filterValues);

  const CUSTOMER_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      { field: 'name', headerName: t('table.name'), flex: 1 },
      {
        field: 'birthdate',
        headerName: t('table.birthDate'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Customer>) => {
          const { row } = params;

          return <>{formatDateToDay(row.reg_date, 'DD.MM.YYYY')}</>;
        },
      },
      {
        field: 'status',
        headerName: t('table.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Customer>) => {
          const { row } = params;
          return (
            <AppStatusHighlight status={row.status}>{row.status}</AppStatusHighlight>
          );
        },
      },
      {
        field: 'last_tap',
        headerName: t('table.last_tap'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Customer>) => {
          const { row } = params;

          return <>{formatDateToDay(row.reg_date, 'DD.MM.YYYY - hh:mm')}</>;
        },
      },
      {
        field: 'reg_date',
        headerName: t('table.reg_date'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Customer>) => {
          const { row } = params;

          return <>{formatDateToDay(row.reg_date, 'DD.MM.YYYY - hh:mm')}</>;
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<Customer>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/customer/${row.id}`} isGreyLink>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <AppBreadcrumbs />
      {filters}
      <AppTable
        rows={data?.data ?? []}
        columns={CUSTOMER_COLUMNS}
        showSettings={false}
        exportMenuItems={[
          {
            onClick: async () => await exportCustomersData(ptoId, 'xlxs', filterValues),
            label: 'XLXS',
          },
          {
            onClick: async () => await exportCustomersData(ptoId, 'csv', filterValues),
            label: 'CSV',
          },
        ]}
        appTitle={t('tableTitle')}
      />
    </div>
  );
};
