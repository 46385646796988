import { api } from '../index';
import { ClearResponse, Customer } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from 'utils/funcs/downloadFile';
import { CustomerStatus } from 'types/enumTypes';

export interface CustomerFilter {
  pto_id: number;
  name: string;
  customer_status: CustomerStatus;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

const getCustomerCollection = async (ptoId: number, params: Partial<CustomerFilter>) => {
  const response = await api.get<ApiResponse<Customer[]>>(`/pto/${ptoId}/customer`, {
    params,
  });
  return response.data;
};

export const useCustomerCollectionsQuery = (ptoId: number, filterParams = {}) =>
  useQuery(['getCustomers', ptoId, filterParams], () =>
    getCustomerCollection(ptoId, filterParams),
  );

const getCustomerDetail = async (ptoId: number, customerId: string) => {
  const response = await api.get<Customer>(`/pto/${ptoId}/customer/${customerId}`);
  return response.data;
};

export const useCustomerDetailQuery = (ptoId: number, customerId: string) =>
  useQuery(['getCustomerDetail', ptoId, customerId], () =>
    getCustomerDetail(ptoId, customerId),
  );

export const exportCustomersData = async (
  ptoId: number,
  type: FileType,
  filterParams = {},
) => {
  return await getFile(
    `/pto/${ptoId}/customer/export/${type}`,
    type,
    'Customer',
    filterParams,
  );
};

export const clearCustomer = async (ptoId: number, customerId: string) => {
  const response = await api.patch<ClearResponse>(
    `/pto/${ptoId}/customer/clear/${customerId}`,
  );
  return response;
};
