import { styled, Select as MuiSelect, SelectProps, InputAdornment } from '@mui/material';
import FormField from '../formField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AppInputBase from '../appInputBase';

const StyledSelect = styled(MuiSelect)(({ theme: { typography, spacing } }) => ({
  '&.MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    ...typography.h6,
    fontWeight: 'bold',
  },
  '&.MuiInputBase-root': {
    padding: 0,
  },
  '& .MuiInputBase-input': {
    padding: spacing(1.2, 1.5),
  },
}));

const StyledSelectAsButton = styled(MuiSelect, {
  shouldForwardProp: (prop) => prop !== 'size',
})<{ size: SelectSize }>(({ size, theme: { typography, palette, spacing } }) => ({
  '&.MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    ...typography.h6,
    fontWeight: 'bold',
  },
  '&.MuiInputBase-root': {
    padding: 0,
  },
  '& .MuiInputBase-input': {
    backgroundColor: palette.primary.light,
    border: 0,
    padding: size ? spacing(0, 1) : spacing(1, 1.5),
  },
}));

type SelectVariant = 'standard' | 'asButton';
type SelectSize = 'small' | 'normal';

const AppSelect: React.FC<
  SelectProps & { errorText?: string; selectVariant?: SelectVariant; size?: SelectSize }
> = ({
  label,
  error,
  errorText,
  required,
  children,
  selectVariant = 'standard',
  size = 'small',
  startAdornment,
  ...rest
}) => {
  return (
    <FormField label={label} required={required} error={error} errorText={errorText}>
      {selectVariant === 'asButton' ? (
        <StyledSelectAsButton
          size={size}
          IconComponent={KeyboardArrowDownIcon}
          fullWidth
          input={<AppInputBase />}
          error={error}
          {...rest}
        >
          {children}
        </StyledSelectAsButton>
      ) : (
        <StyledSelect
          IconComponent={KeyboardArrowDownIcon}
          fullWidth
          input={<AppInputBase startAdornment={startAdornment} />}
          error={error}
          {...rest}
        >
          {children}
        </StyledSelect>
      )}
    </FormField>
  );
};

export default AppSelect;
