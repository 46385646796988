import { api } from '../index';
import { JourneyDetail, JourneyInterface } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from '../../utils/funcs/downloadFile';

export interface JourneysFilter {
  date_from: string;
  date_to: string;
  vehicle_type: string;
  line: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

const getJourneysCollection = async (ptoId: number, params: Partial<JourneysFilter>) => {
  const response = await api.get<ApiResponse<JourneyInterface[]>>(
    `/pto/${ptoId}/journey`,
    {
      params,
    },
  );
  return response.data;
};

export const useJourneysCollectionsQuery = (ptoId: number, filterParams = {}) =>
  useQuery(['getJourneys', ptoId, filterParams], () =>
    getJourneysCollection(ptoId, filterParams),
  );

const getJourneyDetail = async (ptoId: number, journeyId: string) => {
  const response = await api.get<JourneyDetail>(`/pto/${ptoId}/journey/${journeyId}`);
  return response.data;
};

export const useJourneyDetailQuery = (ptoId: number, journeyId: string) =>
  useQuery(['getJourneyDetail', ptoId, journeyId], () =>
    getJourneyDetail(ptoId, journeyId),
  );

export const exportJourneysData = async (
  ptoId: number,
  type: FileType,
  filterParams = {},
) => {
  return await getFile(
    `/pto/${ptoId}/journey/export/${type}`,
    type,
    'Journeys',
    filterParams,
  );
};
