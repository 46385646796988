import { Box, Typography, useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { useCustomerCollectionsQuery } from 'api/endpoints/customer';
import { useJourneyDetailQuery } from 'api/endpoints/journeys';
import { exportTapsData, useTapsCollectionsQuery } from 'api/endpoints/taps';
import { AppDataContainer } from 'components/appDataContainer';
import { TwoColumnContainer } from 'components/appDataContainer/twoColumnContainer';
import AppLink from 'components/appLink/appLink';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { AppTable, AppTableProps } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { PaymentWindowStatus } from 'components/paymentWindowStatus';
import { TapStatus } from 'components/tapStatus';
import { TransportIcon } from 'components/transportIcon';
import dayjs from 'dayjs';
import { VEHICLE_DETAIL_URL } from 'pages/appRoutes/appRoutesConst';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'stores/store';
import { Customer, JourneyDetail, TapInterface } from 'types/commonTypes';
import {
  calculateDifferenceBetweenDates,
  convertDayJsToIso,
  createDayJsFromCustomFormat,
  formatDateToDay,
} from 'utils/funcs/formatDate';
import useFilters from 'utils/hooks/filters/useFilters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import CircleIcon from '@mui/icons-material/Circle';

const customeRenderFunctions = () => {
  return {
    duration: (value: string, data: Record<string, any>) => {
      const { start_date, date_end } = data as JourneyDetail;
      const startDate = createDayJsFromCustomFormat(start_date, 'YYYY-MM-DD hh:mm:ss');
      const endDate = createDayJsFromCustomFormat(date_end, 'YYYY-MM-DD hh:mm:ss');

      const difference = calculateDifferenceBetweenDates(startDate, endDate);
      return (
        <Typography>
          test
          {/* {calculateDifferenceBetweenDates(date_start, date_end, 'minute')} */}
        </Typography>
      );
    },

    start_date: (value: string) =>
      value ? formatDateToDay(value, 'DD.MM.YYYY hh:mm') : ' -',
    end_date: (value: string) =>
      value ? formatDateToDay(value, 'DD.MM.YYYY hh:mm') : ' -',
    customer: (value: string, data: Record<string, any>) => {
      const { customer } = data as JourneyDetail;
      return customer?.name ? customer.name : ' -';
    },
    paymentWindowId: (value: string, data: Record<string, any>) => {
      const { payment_window } = data as JourneyDetail;
      return payment_window?.id ? <Typography> {payment_window.id} </Typography> : ' -';
    },
    paymentWindowStatus: (value: string, data: Record<string, any>) => {
      const { payment_window } = data as JourneyDetail;
      return payment_window?.status ? (
        <PaymentWindowStatus showCircle={false} status={payment_window?.status} />
      ) : (
        ' -'
      );
    },
  };
};

export const JourneyDetailPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.journeyDetailPage' });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });
  const { id: journeyId } = useParams();
  const { ptoId } = useAppSelector((state) => state.user);
  const { data } = useJourneyDetailQuery(ptoId, journeyId || '');
  const { data: tapsData } = useTapsCollectionsQuery(ptoId);
  const { spacing } = useTheme();

  const TAPS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'time',
        headerName: t('table.time'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <div>
              <Typography component="div">
                {formatDateToDay(row.time, 'DD.MM.YYYY')}
              </Typography>
              <Typography component="div">
                {formatDateToDay(row.time, 'hh.mm.ss')}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'type',
        headerName: t('table.type'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return <TapStatus label={tEnums(`tapStatus.${row.type}`)} status={row.type} />;
        },
      },
      {
        field: 'vehicle',
        headerName: t('table.vehicle'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;
          return (
            <Typography
              sx={{
                gap: 1,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
              }}
            >
              <TransportIcon type={row.fleet.type} />
              <AppLink
                to={`/${VEHICLE_DETAIL_URL.replace(/\/:id$/, '')}/${row.fleet.id}`}
              >
                {row.fleet.id}
              </AppLink>
            </Typography>
          );
        },
      },
      {
        field: 'line',
        headerName: t('table.line'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <Typography
              sx={{
                gap: 1,
                display: 'flex',
                flexDirection: 'row',
                alignContent: 'center',
              }}
            >
              <CircleIcon sx={{ color: params.row.line.color, marginRight: 1 }} />
              <AppLink to={`/line/${row.line.id}`}>{row.line.name}</AppLink>
            </Typography>
          );
        },
      },
      {
        field: 'terminal',
        headerName: t('table.terminal'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;
          return (
            <AppLink to={`/terminal/${row.terminal_detail_id}`}>
              {row.terminal_detail_id}
            </AppLink>
          );
        },
      },
      {
        field: 'token_id',
        headerName: t('table.tokenId'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return <AppLink to={`/token/${row.token_id}`}>{row.token_id}</AppLink>;
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<TapInterface>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/tap/${row.id}`}>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <>
      <AppBreadcrumbs />
      <AppDataContainer
        titleRow={{
          title: t('pageTitle'),
        }}
        sx={{ marginY: spacing(4) }}
      >
        <TwoColumnContainer
          renderData={customeRenderFunctions()}
          columns={[
            ['duration', 'start_date', 'end_date'],
            ['token_id', 'customer'],
          ]}
          dataObj={data ?? {}}
          translateFunc={(k: string): string => {
            return t(k);
          }}
        />
        <TwoColumnContainer
          renderData={customeRenderFunctions()}
          columns={[['paymentWindowId', 'paymentWindowStatus'], ['id']]}
          dataObj={data ?? {}}
          translateFunc={(k: string): string => {
            return t(k);
          }}
        />
      </AppDataContainer>
      <AppTable
        rows={tapsData?.data ?? []}
        columns={TAPS_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        exportMenuItems={[
          {
            onClick: async () => await exportTapsData(ptoId, 'xlxs'),
            label: 'XLXS',
          },
          {
            onClick: async () => await exportTapsData(ptoId, 'csv'),
            label: 'CSV',
          },
        ]}
      />
    </>
  );
};
