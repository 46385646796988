import { api } from '../index';
import { TapInterface } from 'types/commonTypes';
import { useQuery } from 'react-query';
import { ApiResponse } from 'types/utilityInterfaces';
import { FileType, getFile } from 'utils/funcs/downloadFile';

export interface TapsFilter {
  date_from: string;
  date_to: string;
  vehicle_type: string;
  line: string;
  order_type: 'ASC' | 'DESC';
  order_column: string;
}

export enum TimePeriod {
  THIS_MONTH = 'THIS_MONTH',
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  THIS_WEEK = 'THIS_WEEK',
  LAST_90_DAYS = 'LAST_90_DAYS',
  THIS_YEAR = 'THIS_YEAR',
}

interface TapGraphItem {
  day: string;
  tap_count: number;
}
interface TapsOverview {
  summary: {
    all_taps: number;
    customers: number;
  };
  graph: TapGraphItem[];
}

const getTapsCollection = async (ptoId: number, params: Partial<TapsFilter>) => {
  const response = await api.get<ApiResponse<TapInterface[]>>(`/pto/${ptoId}/tap`, {
    params,
  });
  return response.data;
};

export const useTapsCollectionsQuery = (ptoId: number, filterParams = {}) =>
  useQuery(['getTaps', ptoId, filterParams], () =>
    getTapsCollection(ptoId, filterParams),
  );

const getTapsOverview = async (
  ptoId: number,
  timePeriod: TimePeriod,
  terminals: number[],
) => {
  const response = await api.get<TapsOverview>(
    `/pto/${ptoId}/tap/overview/${timePeriod}`,
    {
      params: { terminals },
    },
  );
  return response.data;
};

export const useTapsOverviewQuery = (
  ptoId: number,
  timePeriod: TimePeriod,
  terminals: number[],
) =>
  useQuery(['getTaps', ptoId, timePeriod, terminals], () =>
    getTapsOverview(ptoId, timePeriod, terminals),
  );

const getTapDetail = async (
  ptoId: number,
  tapId: string,
  params: Partial<TapsFilter>,
) => {
  const response = await api.get<TapInterface>(`/pto/${ptoId}/tap/${tapId}`, {
    params,
  });
  return response.data;
};

export const useTapDetailQuery = (ptoId: number, tapId: string, filterParams = {}) =>
  useQuery(['getTapDetail', ptoId, tapId, filterParams], () =>
    getTapDetail(ptoId, tapId, filterParams),
  );

export const exportTapsData = async (
  ptoId: number,
  type: FileType,
  filterParams = {},
) => {
  return await getFile(`/pto/${ptoId}/tap/export/${type}`, type, 'Taps', filterParams);
};
