import { api } from 'api';

export type FileType = 'xlxs' | 'csv' | 'pdf';

export const getFile = async (
  route: string,
  type: FileType,
  name: string,
  filterParams?: Record<string, any>,
) => {
  try {
    const { data } = await api.get(route, {
      responseType: 'blob',
      params: filterParams,
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${name}.${type}`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    console.error(e);
  }
};
