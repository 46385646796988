import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  exportTokensData,
  TokensFilter,
  useTokensCollectionsQuery,
} from 'api/endpoints/tokens';
import AppLink from 'components/appLink/appLink';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { AppTableProps, AppTable } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Token } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import useFilters from 'utils/hooks/filters/useFilters';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export const TokensPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.tokensPage' });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });
  const ptoId = 1;
  const { filters, filterValues } = useFilters<TokensFilter>({
    filters: ['TOKEN_ID', 'CUSTOMER_NAME', 'TOKEN_STATUS'],
    namePairs: [
      { filterName: 'TOKEN_ID', queryname: 'token_id' },
      { filterName: 'CUSTOMER_NAME', queryname: 'customer_name' },
      { filterName: 'TOKEN_STATUS', queryname: 'token_status' },
    ],
  });
  const { data } = useTokensCollectionsQuery(ptoId, filterValues);
  const TOKENS_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      { field: 'id', headerName: t('table.id') },
      {
        field: 'name',
        headerName: t('table.name'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Token>) => {
          const { row } = params;

          return (
            <>
              {row.name ? (
                <AppLink replace={true} to={`/customer/${row.id}`}>
                  {row.name}
                </AppLink>
              ) : (
                '-'
              )}
            </>
          );
        },
      },
      {
        field: 'status',
        headerName: t('table.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Token>) => {
          const { row } = params;
          return (
            <AppStatusHighlight status={row.status}>
              {tEnums(`customerStatus.${row.status}`)}
            </AppStatusHighlight>
          );
        },
      },
      {
        field: 'last_tap',
        headerName: t('table.last_tap'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Token>) => {
          const { row } = params;

          return <>{formatDateToDay(row.last_tap, 'DD.MM.YYYY - hh:mm')}</>;
        },
      },
      {
        field: 'reg_date',
        headerName: t('table.reg_date'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Token>) => {
          const { row } = params;

          return <>{formatDateToDay(row.reg_date, 'DD.MM.YYYY - hh:mm')}</>;
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<Token>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/token/${row.id}`} isGreyLink>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <AppBreadcrumbs />
      {filters}
      <AppTable
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        rows={data?.data ?? []}
        columns={TOKENS_COLUMNS}
        appTitle={t('tableTitle')}
        showSettings={false}
        exportMenuItems={[
          {
            onClick: async () => await exportTokensData(ptoId, 'xlxs', filterValues),
            label: 'XLXS',
          },
          {
            onClick: async () => await exportTokensData(ptoId, 'csv', filterValues),
            label: 'CSV',
          },
        ]}
      />
    </div>
  );
};
