import { Box, Typography } from '@mui/material';
import { AppBarChart } from 'components/charts/barChart';
import { useRef } from 'react';

interface DataGraphContainerProps {
  dataBoxes: { value: number; label: string }[];
}

export const DataGraphContainer: React.FC<DataGraphContainerProps> = ({ dataBoxes }) => {
  const ref = useRef(null);

  return (
    <Box sx={{ width: '100%' }} ref={ref}>
      <AppBarChart
        barAreaHeight={300}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        barAreaWidth={ref?.current?.offsetWidth * 1}
        data={dataBoxes.map((d, index) => ({ value: d.value, name: d.label })) ?? []}
      />
    </Box>
  );
};
