import { styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface FormFieldProps {
  label: React.ReactNode;
  error: boolean | undefined;
  errorText?: string;
  required: boolean | undefined;
  optional?: boolean;
}

const TopInfoWrapper = styled('div', { shouldForwardProp: (prop) => prop !== 'label' })<{
  label: FormFieldProps['label'];
}>(({ label, theme: { spacing } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: label ? spacing(0.7) : 0,
}));

const FormField: React.FC<FormFieldProps> = ({
  children,
  label,
  error,
  errorText,
  optional,
}) => {
  const theme = useTheme();
  const { t } = useTranslation('common', {
    keyPrefix: 'components.formFields',
  });

  return (
    <div>
      <TopInfoWrapper label={label}>
        {label && (
          <Typography color={theme.palette.text.lightGrey.main}>{label}</Typography>
        )}
        {optional && (
          <Typography color={theme.palette.text.lightGrey.main}>
            {t('optional')}
          </Typography>
        )}
      </TopInfoWrapper>
      {children}
      {error && errorText && <Typography color="error">{errorText}</Typography>}
    </div>
  );
};

export default FormField;
