import { Box, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import {
  TransactionsFilter,
  useTransactionsCollectionsQuery,
} from 'api/endpoints/transactions';
import AppLink from 'components/appLink/appLink';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { AppTable, AppTableProps } from 'components/appTable';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Transaction } from 'types/commonTypes';
import { formatDateToDay } from 'utils/funcs/formatDate';
import useFilters from 'utils/hooks/filters/useFilters';
import * as React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { TimeSource } from 'types/enumTypes';
import { useAppSelector } from 'stores/store';

export const TransactionsPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.transactionsPage' });
  const { ptoId } = useAppSelector((state) => state.user);
  const { filters, filterValues } = useFilters<TransactionsFilter>({
    filters: [
      'APPROVAL_CODE',
      'PAN',
      'AMOUNT_RANGE',
      // 'CURRENCY',
      'DATE_RANGE',
      // 'LOCATION',
      'PAYMENT_TERMINAL_NAME',
    ],
    advancedFilters: [
      'VARIABLE_SYMBOL',
      'TRANSACTION_STATUS',
      'TRANSACTION_TYPE',
      'TRANSACTION_ID',
      'TIME_SOURCE',
      'CARD_ISSUER',
      'CARD_SCHEMA',
      'PAYMENT_DEVICE',
      'PAYMENT_TYPE',
      'CARD_ORIGIN',
      'CARD_PRODUCT',
    ],
    namePairs: [
      { filterName: 'APPROVAL_CODE', queryname: 'approval_code' },
      { filterName: 'PAN', queryname: 'pan' },
      { filterName: 'AMOUNT_RANGE', queryname: ['amount_from', 'amount_to'] },
      // { filterName: 'CURRENCY', queryname: 'curency' },
      // TODO: has to be fixed
      { filterName: 'DATE_RANGE', queryname: ['date_from', 'date_to'] },
      // { filterName: 'LOCATION', queryname: 'location' },
      { filterName: 'PAYMENT_TERMINAL_NAME', queryname: 'terminal' },
      // { filterName: 'VARIABLE_SYMBOL', queryname: 'token_status' },
      { filterName: 'TRANSACTION_STATUS', queryname: 'status' },
      { filterName: 'TRANSACTION_TYPE', queryname: 'transaction_type' },
      { filterName: 'TRANSACTION_ID', queryname: 'transaction_id' },
      { filterName: 'TIME_SOURCE', queryname: 'use_date_terminal' },
      { filterName: 'CARD_ISSUER', queryname: 'card_issuer' },
      { filterName: 'CARD_SCHEMA', queryname: 'card_schema' },
      { filterName: 'PAYMENT_DEVICE', queryname: 'payment_device' },
      { filterName: 'PAYMENT_TYPE', queryname: 'payment_type' },
      { filterName: 'CARD_ORIGIN', queryname: 'card_origin' },
      { filterName: 'CARD_PRODUCT', queryname: 'card_product' },
    ],
    options: { showSaveFilter: true },
  });

  const { data } = useTransactionsCollectionsQuery(ptoId, {
    ...filterValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    date_from: filterValues.date_from
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatDateToDay(filterValues.date_from, 'YYYY-MM-DD')
      : '',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    date_to: filterValues.date_to
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        formatDateToDay(filterValues.date_to, 'YYYY-MM-DD')
      : '',
    use_date_terminal:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filterValues.use_date_terminal === TimeSource['TERMINAL'] ? false : true,
  });

  const TRANSACTION_COLUMNS: AppTableProps['columns'] = useMemo(
    () => [
      {
        field: 'date',
        headerName: t('table.date'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction>) => {
          const { row } = params;
          return (
            <div>
              <Typography component="div" fontWeight={'bold'}>
                {formatDateToDay(row.date_terminal, 'DD.MM.YYYY')}
              </Typography>
              <Typography component="div">
                {formatDateToDay(row.date_terminal, 'hh:mm:ss')}
              </Typography>
            </div>
          );
        },
      },
      {
        field: 'amount',
        headerName: t('table.amountAndCurrency'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;
          return (
            <Box>
              <Typography sx={{ marginRight: 0.5 }} component="span" fontWeight={'bold'}>
                {row.amount}
              </Typography>
              <Typography component="span">{row.currency}</Typography>
            </Box>
          );
        },
      },
      {
        field: 'status',
        headerName: t('table.status'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;

          return (
            <AppStatusHighlight status={row.status}>{row.status}</AppStatusHighlight>
          );
        },
      },
      { field: 'type', headerName: t('table.opType'), flex: 1 },
      {
        field: 'approvalCode',
        headerName: t('table.approvalCode'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;

          return <>{row.approval_code}</>;
        },
      },
      {
        field: 'paymentWindow',
        headerName: t('table.paymentWindow'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;

          return <AppLink to="">{row.payment_window_id}</AppLink>;
        },
      },
      { field: 'id', headerName: t('table.transactionId'), flex: 1 },
      {
        field: 'tokenID',
        headerName: t('table.id'),
        flex: 1,
        renderCell: (params: GridRenderCellParams<Transaction, Transaction>) => {
          const { row } = params;

          return <>{row.token_id}</>;
        },
      },
      {
        field: 'goToDetail',
        headerName: '',
        renderCell: (params: GridRenderCellParams<Transaction>) => {
          const { row } = params;

          return (
            <>
              {
                <AppLink to={`/transaction/${row.id}`} isGreyLink>
                  <ArrowForwardIosIcon />
                </AppLink>
              }
            </>
          );
        },
      },
    ],
    [],
  );

  return (
    <div>
      <AppBreadcrumbs />
      {/* <AppDateRangeSelect /> */}
      {filters}
      <AppTable
        rows={data?.data ?? []}
        columns={TRANSACTION_COLUMNS}
        appTitle={t('tableTitle')}
      />
    </div>
  );
};
