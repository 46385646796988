import { styled, TextField as MuiTextField, TextFieldProps } from '@mui/material';
import FormField from '../formField';

const StyledTextField = styled(MuiTextField)(
  ({ theme: { typography, palette, spacing } }) => ({
    '& .MuiInputBase-root': {
      ...typography.h6,
      fontWeight: 'bold',
      padding: spacing(1.5, 2),
      borderRadius: '8px',

      '& input': {
        padding: 0,
      },
    },
    '&.MuiTextField-root': {
      backgroundColor: 'transparent',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.border.darkGrey,
    },
    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.border.secondary,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: palette.border.secondary,
      borderWidth: '1px',
    },
  }),
);

const AppTextField: React.FC<TextFieldProps & { errorText?: string }> = ({
  label,
  error,
  errorText,
  required,
  ...rest
}) => {
  return (
    <FormField label={label} required={required} error={error} errorText={errorText}>
      <StyledTextField fullWidth error={error} {...rest} />
    </FormField>
  );
};

export default AppTextField;
