export enum CustomerStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DENY = 'Deny',
  DENY_LIST = 'Deny list',
}

export enum WidgetTypes {
  LATEST_TRANSACTIONS = 'latest_transactions',
  LATEST_TAPS = 'latest_taps',
  NETWORK_LOAD = 'network_load',
  CARDS = 'cards',
  DEBT = 'debt',
  STATES_OF_TRANSACTIONS = 'states_of_transactions',
}

export enum TimeSource {
  SERVER = 'server',
  TERMINAL = 'terminal',
}

export enum TokenStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  DENY = 'Deny',
}

export enum PaymentWindowStatus {
  OPEN = 'Open',
  CLOSED = 'Closed',
  DEBT = 'Debt',
}
