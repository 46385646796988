import { styled, Typography } from '@mui/material';
import { Cell, Legend, Pie, PieChart } from 'recharts';
import CircleIcon from '@mui/icons-material/Circle';

const GRAPH_COLORS = [
  '#1AA6F6',
  '#3DC86A',
  '#FDC638',
  '#FB515B',
  '#9B73EC',
  '#177E8A',
  '#F0BAC9',
  '#3C3487',
  '#D0D0D0',
  '#383F47',
  '#D60076',
  '#F69F63',
  '#CEAC47',
  '#CD6DDA',
  '#2DECC3',
  '#59DBFC',
  '#1348D1',
];

const LegendItem = styled('div')(() => ({}));
const LegendWrapper = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: spacing(2),
}));

export interface GraphData {
  name: string;
  value: number;
}

interface DonutChartProps {
  data: GraphData[];
}

export const AppDonutChart: React.FC<DonutChartProps> = ({ data }) => {
  return (
    <PieChart width={600} height={300}>
      <Legend
        height={100}
        width={250}
        verticalAlign="middle"
        align="right"
        content={(props) => {
          return (
            <LegendWrapper
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                flexGrow: 1,
              }}
            >
              {props.payload?.map((entry: any, index) => (
                <LegendItem
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                  key={`item-${index}`}
                >
                  <CircleIcon sx={{ color: entry.color, marginRight: 1 }} />
                  <Typography
                    sx={{ marginRight: 2 }}
                    variant="h6"
                  >{`${entry?.payload?.percent?.toFixed(2)} %`}</Typography>
                  <Typography
                    color="text.lightGrey.main"
                    variant="subtitle1"
                    component="span"
                  >
                    {entry.value}
                  </Typography>
                </LegendItem>
              ))}
            </LegendWrapper>
          );
        }}
      />
      <Pie
        data={data}
        cx={200}
        cy={150}
        innerRadius={60}
        outerRadius={100}
        dataKey="value"
      >
        {data.map((_entry, index) => (
          <Cell key={`cell-${index}`} fill={GRAPH_COLORS[index % GRAPH_COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};
