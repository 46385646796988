import { Box, styled, useTheme } from '@mui/material';
import {
  ColumnConfig,
  DataObj,
  RenderData,
  renderKey,
  renderValue,
  TranslateFunc,
} from '../utility';

const Column = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
}));

interface TwoColumnContainerProps {
  columns: [ColumnConfig, ColumnConfig];
  dataObj: DataObj;
  translateFunc: TranslateFunc;
  renderData?: RenderData;
  isBgGrey?: boolean;
  isBorderBottom?: boolean;
}

export const TwoColumnContainer: React.FC<TwoColumnContainerProps> = ({
  columns,
  dataObj,
  translateFunc,
  renderData,
  isBgGrey,
  isBorderBottom,
}) => {
  const { palette } = useTheme();

  return (
    <Box
      sx={{
        p: 3,
        display: 'grid',
        gap: 1,
        gridTemplateColumns: 'repeat(2, 1fr)',
        backgroundColor: isBgGrey
          ? palette.background.lightGrey.light
          : palette.text.white,
        borderBottom: isBorderBottom ? '1px solid #E0E0E0' : 'none',
      }}
    >
      {columns.map((column, index) => (
        <Column key={index}>
          <Box
            sx={{
              marginRight: 3,
              color: palette.text.lightGrey.main,
            }}
          >
            {renderKey(column, translateFunc)}
          </Box>
          <Box>{renderValue(column, dataObj, renderData)}</Box>
        </Column>
      ))}
    </Box>
  );
};
