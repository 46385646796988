import { Collapse, styled, Typography } from '@mui/material';
import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { NavLink } from 'react-router-dom';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NavbarField } from '../authenticatedLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HomeIcon from '@mui/icons-material/Home';
import MapIcon from '@mui/icons-material/Map';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import {
  ROOT_URL,
  NAVBAR_LABELS,
  PEOPLE_URL,
  CUSTOMERS_URL,
  TOKENS_URL,
  DENY_LIST_URL,
  ACTIVITY_URL,
  JOURNEYS_URL,
  TAPS_URL,
  PAYMENTS_URL,
  TRANSACTIONS_URL,
  PAYMENT_WINDOWS_URL,
  CLEARING_URL,
  NETWORK_URL,
  TERMINALS_URL,
  FLEET_URL,
  STOPS_URL,
  LINES_URL,
  SETTINGS_URL,
  FARES_URL,
  TRANSACTIONS_SETTINGS_URL,
  GENERAL_URL,
} from 'pages/appRoutes/appRoutesConst';

export const NAVBAR_FIELDS: NavbarField[] = [
  {
    icon: <HomeIcon />,
    url: ROOT_URL,
    label: 'dashboard',
  },
  {
    icon: <AccountCircleIcon />,
    label: NAVBAR_LABELS.PEOPLE_LABEL,
    url: PEOPLE_URL,
    children: [
      {
        url: `${CUSTOMERS_URL}`,
        label: NAVBAR_LABELS.CUSTOMERS_LABEL,
      },
      {
        url: TOKENS_URL,
        label: NAVBAR_LABELS.TOKENS_LABEL,
      },
      {
        url: DENY_LIST_URL,
        label: NAVBAR_LABELS.DENY_LIST_LABEL,
      },
    ],
  },
  {
    icon: <MapIcon />,
    label: NAVBAR_LABELS.ACTIVITY_LABEL,
    url: `${ACTIVITY_URL}`,
    children: [
      {
        url: JOURNEYS_URL,
        label: NAVBAR_LABELS.JOURNEYS_LABEL,
      },
      {
        url: TAPS_URL,
        label: NAVBAR_LABELS.TAPS_LABEL,
      },
    ],
  },
  {
    icon: <CreditCardIcon />,
    label: NAVBAR_LABELS.PAYMENTS_LABEL,
    url: PAYMENTS_URL,
    children: [
      {
        url: TRANSACTIONS_URL,
        label: NAVBAR_LABELS.TRANSACTIONS_LABEL,
      },
      {
        url: PAYMENT_WINDOWS_URL,
        label: NAVBAR_LABELS.PAYMENT_WINDOWS_LABEL,
      },
      {
        url: CLEARING_URL,
        label: NAVBAR_LABELS.CLEARING_LABEL,
      },
    ],
  },
  {
    icon: <LanguageIcon />,
    label: NAVBAR_LABELS.NETWORK_LABEL,
    url: NETWORK_URL,
    children: [
      {
        url: TERMINALS_URL,
        label: NAVBAR_LABELS.TERMINALS_LABEL,
      },
      {
        url: FLEET_URL,
        label: NAVBAR_LABELS.FLEET_LABEL,
      },
      {
        url: STOPS_URL,
        label: NAVBAR_LABELS.STOPS_LABEL,
      },
      {
        url: LINES_URL,
        label: NAVBAR_LABELS.LINES_LABEL,
      },
    ],
  },
  // {
  //   icon: <SettingsIcon />,
  //   label: NAVBAR_LABELS.SETTINGS_LABEL,
  //   url: SETTINGS_URL,
  //   children: [
  //     {
  //       url: FARES_URL,
  //       label: NAVBAR_LABELS.FARES_LABEL,
  //     },
  //     {
  //       url: TRANSACTIONS_SETTINGS_URL,
  //       label: NAVBAR_LABELS.TRANSACTIONS_SETTINGS_LABEL,
  //     },
  //     {
  //       url: GENERAL_URL,
  //       label: NAVBAR_LABELS.GENERAL_LABEL,
  //     },
  //   ],
  // },
];

type NavButtonProps = { isActive: boolean };

const NavButton = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<NavButtonProps>(({ theme: { palette }, isActive }) => ({
  ':first-letter': {
    textTransform: 'capitalize',
  },
  color: isActive ? palette.text.lightGrey.dark : palette.text.lightGrey.main,
  textDecoration: 'none',
}));

const NavItemsGroup = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>(({ theme: { palette }, isActive }) => ({
  backgroundColor: isActive ? palette.primary.light : 'transparent',
}));

interface NavItem {
  url?: string;
  label: string;
}
const RenderNavItem: React.FC<NavItem> = ({ url = '/', label }) => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' });
  return (
    <NavLink style={{ textDecoration: 'none' }} to={url}>
      {({ isActive }) => {
        return (
          <NavButton sx={{ padding: '16px' }} isActive={isActive}>
            <Typography>{t(label)}</Typography>
          </NavButton>
        );
      }}
    </NavLink>
  );
};

interface StandardNavItemProps {
  field: NavbarField;
  isCollapsed: boolean;
  handleToggleCollapsed: () => void;
}

export const StandardNavItem: React.FC<StandardNavItemProps> = ({
  field,
  isCollapsed = false,
  handleToggleCollapsed,
}) => {
  const { t } = useTranslation('common', { keyPrefix: 'navigation' });
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();

  const parentOrSomeChildIsActive = useMemo(
    () =>
      (field.children &&
        field.children.some(
          (child) => `/${field.url}/${child.url}` === location.pathname,
        )) ||
      field.url === location.pathname ||
      `/${field.url}` === location.pathname,
    [location.pathname],
  );

  return (
    <NavItemsGroup isActive={parentOrSomeChildIsActive}>
      <Typography
        sx={{
          padding: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: isCollapsed ? 'center' : 'space-between',
          flexDirection: 'row',
          cursor: 'pointer',
          backgroundColor: parentOrSomeChildIsActive ? 'primary.main' : 'transparent',
        }}
        component={'div'}
        onClick={() => {
          if (!field?.children) return;
          setIsExpanded(!isExpanded);
        }}
      >
        <Typography
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <div onClick={handleToggleCollapsed}>
            {
              <Typography sx={{ '& :first-child': { verticalAlign: 'middle' } }}>
                {field.icon}
              </Typography>
            }
          </div>
          {!isCollapsed && (
            <>
              <Typography component="div" sx={{ ml: 2 }}></Typography>
              {!field.children ? (
                <RenderNavItem url={field.url} label={field.label} />
              ) : (
                t(field.label)
              )}
            </>
          )}
        </Typography>
        {!isCollapsed && (
          <>
            {field.children && (
              <div>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
            )}
          </>
        )}
      </Typography>

      {!isCollapsed && (
        <>
          {field.children && (
            <Collapse in={isExpanded}>
              <Typography component="div" sx={{ paddingX: 1 }}>
                {field.children.map((child, index) => {
                  const compositeUrl = `${field.url}/${child.url}`;
                  return (
                    <RenderNavItem key={index} url={compositeUrl} label={child.label} />
                  );
                })}
              </Typography>
            </Collapse>
          )}
        </>
      )}
    </NavItemsGroup>
  );
};
