import { AppStatusHighlight } from 'components/appStatusHighlight';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useTranslation } from 'react-i18next';
import { LineInterface } from 'types/commonTypes';
import { useParams } from 'react-router-dom';
import { AppDataContainer } from '../../components/appDataContainer';
import AppButton from '../../components/appButton';
import { Box, Typography } from '@mui/material';
import HeatMap from '../../components/maps/HeatMap';
import { OneColumnContainer } from '../../components/appDataContainer/oneColumnContainer';
import { TransportIcon } from '../../components/transportIcon';
import { useLineDetailQuery } from '../../api/endpoints/lines';
import CircleIcon from '@mui/icons-material/Circle';
import { useAppSelector } from '../../stores/store';
import { TranslateFunc } from '../../components/appDataContainer/utility';
import AppLink from '../../components/appLink/appLink';
import {
  ACTIVITY_URL,
  STOP_DETAIL_URL,
  TAPS_URL,
  JOURNEYS_URL,
} from '../appRoutes/appRoutesConst';
import { useNavigate } from 'react-router-dom';
import { CustomerStatus } from 'types/enumTypes';

const LAST_X_DAYS = 30;

const stopDetailUrlWithoutId = STOP_DETAIL_URL.slice(0, -4);

const customRenderFunctions = (translateFunc: TranslateFunc) => ({
  lineType: (value: string, data: Record<string, any>) => {
    const lineType = data.type as LineInterface['type'];
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
        <TransportIcon type={lineType} />
        <Typography>{lineType}</Typography>
      </Box>
    );
  },
  status: (value: string) => {
    return (
      <AppStatusHighlight status={value as CustomerStatus}>
        <Typography color={value === 'active' ? 'success.main' : 'error.main'}>
          {translateFunc(`enums.terminalStatus.${value}`)}
        </Typography>
      </AppStatusHighlight>
    );
  },
  color: (value: string, data: Record<string, any>) => {
    const color = data.color as LineInterface['color'];
    return <CircleIcon sx={{ color: color }} />;
  },
  lineId: (value: string, data: Record<string, any>) => {
    const lineId = data.id as LineInterface['id'];
    return <Typography>{lineId}</Typography>;
  },
  stops: (value: string, data: Record<string, any>) => {
    const stopsCount = data.stops_count as LineInterface['stops_count'];
    const stops = data.stops as LineInterface['stops'];
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
        {stops.map((stop, index) => (
          <Typography key={index}>
            <AppLink to={`/${stopDetailUrlWithoutId}/${stop.id}`}>{stopsCount}</AppLink>
          </Typography>
        ))}
      </Box>
    );
  },
});

export const LineDetailPage: React.FC = () => {
  const { t } = useTranslation('common', { keyPrefix: 'pages.lineDetailPage' });
  const { t: tEnums } = useTranslation('common');
  const { ptoId } = useAppSelector((state) => state.user);
  const { id: lineId } = useParams();
  const { data } = useLineDetailQuery(ptoId, lineId || '');
  const navigate = useNavigate();

  // PRIPRAVENA GPS MAPA LINKY
  // const markers = useMemo(() => {
  //   if (data?.gps) {
  //     return [{ lat: parseFloat(data?.gps.lat), lng: parseFloat(data?.gps.lng) }];
  //   }
  //   return [];
  // }, [data?.gps]);

  return (
    <div>
      <AppBreadcrumbs
        customBreadCrumbs={[
          { index: 1, breadCrumbLabel: `${data?.name ?? ''}`, route: '/' },
        ]}
      />
      <AppDataContainer
        sx={{ width: '50%' }}
        titleRow={{
          title: t('pageTitle'),
          // buttons: [
          //   <AppButton
          //     variant="contained"
          //     color="error"
          //     size="small"
          //     startIcon={<DeleteIcon />}
          //     key={0}
          //   >
          //     {t('deleteCustomer')}
          //   </AppButton>,
          //   <AppButton
          //     variant="contained"
          //     color="secondary"
          //     size="small"
          //     startIcon={<EditIcon />}
          //     key={1}
          //   >
          //     {t('editCustomer')}
          //   </AppButton>,
          // ],
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {data && (
            <OneColumnContainer
              renderData={customRenderFunctions(tEnums)}
              columns={[['lineType', 'color', 'status', 'lineId', 'stops']]}
              dataObj={data ?? {}}
              translateFunc={(k: string): string => {
                return t(k);
              }}
            />
          )}
          {/*MAPA*/}
          {/*{markers.length > 0 && <MapWithMarkers center={markers[0]} markers={markers} />}*/}
        </Box>
      </AppDataContainer>
      <Box
        sx={{
          marginTop: 8,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 5,
        }}
      >
        <AppDataContainer
          titleRow={{
            title: t('tapsCard.title'),
            slimTitle: t('tapsCard.historyRange', { days: LAST_X_DAYS }),
            buttons: [
              <AppButton
                onClick={() => navigate(`/${ACTIVITY_URL}/${TAPS_URL}`)}
                variant="contained"
                color="secondary"
                size="small"
                key={0}
              >
                {t('tapsCard.details')}
              </AppButton>,
            ],
          }}
        >
          {/*PRIPRAVENA HEAT MAPA*/}
          <HeatMap heatPoints={[{ lat: 37.782, lng: -122.443, weight: 2 }]} />
        </AppDataContainer>
        <AppDataContainer
          titleRow={{
            title: t('journeysCard.title'),
            slimTitle: t('journeysCard.historyRange', { days: LAST_X_DAYS }),
            buttons: [
              <AppButton
                onClick={() => navigate(`/${ACTIVITY_URL}/${JOURNEYS_URL}`)}
                variant="contained"
                color="secondary"
                size="small"
                key={0}
              >
                {t('journeysCard.details')}
              </AppButton>,
            ],
          }}
        />
      </Box>
    </div>
  );
};
