import { InputBase, styled } from '@mui/material';

const AppInputBase = styled(InputBase)(({ theme: { palette, spacing, typography } }) => ({
  '&.MuiInputBase-root': {
    borderRadius: '8px',
    border: `1px solid ${palette.border.darkGrey}`,
    padding: spacing(1.5, 2),
  },
  '& .MuiInputBase-input': {
    ...typography.h6,
    fontWeight: 'bold',
    backgroundColor: 'transparent',

    '&:focus': {
      outline: 0,
      borderColor: palette.border.secondary,
    },
    '&:hover': {
      borderColor: palette.border.secondary,
    },
  },
}));

export default AppInputBase;
