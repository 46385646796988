import {
  Box,
  Card,
  Collapse,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from '@mui/material';
import AppButton from 'components/appButton';
import AppSelect from 'components/form/select';
import AppTextField from 'components/form/textField';
import { useEffect, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  getCardIssuerFilterOptions,
  getCardOriginFilterOptions,
  getCardProductsFilterOptions,
  getCardSchemaFilterOptions,
  getCustomerFilterOptions,
  getLinesFilterOptions,
  getPaymentDevicesFilterOptions,
  getPaymentTypesFilterOptions,
  getPaymentWindowStatusFilterOptions,
  getTerminalStatusFilterOptions,
  getTerminalTypeFilterOptions,
  getTokenFilterOptions,
  getTransactionStatusFilterOptions,
  getTransactionTypeFilterOptions,
  getVehicleFilterOptions,
} from 'api/endpoints/enums';
import { QueryFunction, QueryKey, useQueries } from 'react-query';
import { AppDateRangeSelect } from 'components/form/appDateRangeSelect';
import { DateRange } from '@mui/x-date-pickers-pro';
import { isArray } from 'lodash';
import { AppDatePicker } from 'components/form/appDatePicker';
import dayjs from 'dayjs';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import SettingsIcon from '@mui/icons-material/Settings';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { AmountField } from 'components/form/amountField';
import { AppToggleButton } from 'components/form/toggleButton';
import {
  CustomerStatus,
  PaymentWindowStatus,
  TimeSource,
  TokenStatus,
} from 'types/enumTypes';
import {
  deleteFilter,
  FilterObject,
  getFilters,
  saveFilters,
} from 'api/endpoints/filters';
import DeleteIcon from '@mui/icons-material/Delete';
import { SaveFilterDialog } from './saveFilterDialog';
import { userSlice } from 'stores/slices/userSlice';
import { store, useAppSelector } from 'stores/store';

interface UseFiltersOptions {
  showSaveFilter?: boolean;
}
interface FilterProps<T = void> {
  filters: FilterTypes[];
  advancedFilters?: FilterTypes[];
  namePairs?: NamePair<T, FilterTypes>[];
  options?: UseFiltersOptions;
}

interface FilterOptionsLists {
  vehicleTypes: string[];
  status: CustomerStatus[];
  customerStatuses: CustomerStatus[];
  tokenStatuses: TokenStatus[];
  paymentWindowStatuses: PaymentWindowStatus[];
  lines: string[];
  transactionTypes: string[];
  transactionStatuses: string[];
  cardIssuers: string[];
  cardSchemas: string[];
  cardOrigins: string[];
  paymentDevices: string[];
  paymentTypes: string[];
  cardProducts: string[];
}

type QueryObject = {
  queryKey: QueryKey;
  queryFn: QueryFunction;
};

type LoadQueries = {
  [K in `${FilterTypes}`]+?: QueryObject;
};

type PossibleFieldValues =
  | undefined
  | string
  | [number, number]
  | DateRange<Date>
  | Date
  | boolean;

type FieldValues = ControllerRenderProps<
  // Field values
  Record<Partial<FilterTypes>, PossibleFieldValues>,
  // Field names
  FilterTypes
>;

type NamePair<T, R> = {
  queryname: keyof T | (keyof T)[];
  filterName: R;
};

export type FilterTypes =
  | 'VEHICLE_TYPE'
  | 'LINE'
  | 'CUSTOMER_NAME'
  | 'STATUS'
  | 'CUSTOMER_STATUS'
  | 'TOKEN_STATUS'
  | 'PAYMENT_WINDOW_STATUS'
  | 'TOKEN_ID'
  | 'DATE_RANGE'
  | 'DATE'
  | 'APPROVAL_CODE'
  | 'PAN'
  | 'AMOUNT_RANGE'
  | 'CURRENCY'
  | 'LOCATION'
  | 'PAYMENT_TERMINAL_NAME'
  | 'PAYMENT_WINDOW_STATUS'
  | 'TERMINAL_ID'
  | 'VARIABLE_SYMBOL'
  | 'TRANSACTION_STATUS'
  | 'TRANSACTION_TYPE'
  | 'TRANSACTION_ID'
  | 'TIME_SOURCE'
  | 'CARD_ISSUER'
  | 'CARD_SCHEMA'
  | 'PAYMENT_DEVICE'
  | 'PAYMENT_TYPE'
  | 'CARD_ORIGIN'
  | 'CARD_PRODUCT';

const loadFilterOptions = (filters: FilterProps['filters'], ptoId: number) => {
  const loadVehicleTypes: QueryObject = {
    queryKey: ['vehicleTypes', { ptoId }],
    queryFn: () => getVehicleFilterOptions(ptoId),
  };

  const loadCustomerStatuses: QueryObject = {
    queryKey: ['customerStatuses', { ptoId }],
    queryFn: () => getCustomerFilterOptions(ptoId),
  };
  const loadTokenTypes: QueryObject = {
    queryKey: ['tokenStatuses', { ptoId }],
    queryFn: () => getTokenFilterOptions(ptoId),
  };
  const loadTerminalStatus: QueryObject = {
    queryKey: ['terminalStatus', { ptoId }],
    queryFn: () => getTerminalStatusFilterOptions(ptoId),
  };
  const loadTerminalTypes: QueryObject = {
    queryKey: ['terminalType', { ptoId }],
    queryFn: () => getTerminalTypeFilterOptions(ptoId),
  };
  const loadPayWindowStatuses: QueryObject = {
    queryKey: ['paymentWindowStatuses', { ptoId }],
    queryFn: () => getPaymentWindowStatusFilterOptions(ptoId),
  };
  const loadTransactionStatus: QueryObject = {
    queryKey: ['transactionStatuses', { ptoId }],
    queryFn: () => getTransactionStatusFilterOptions(ptoId),
  };
  const loadTransactionType: QueryObject = {
    queryKey: ['transactionTypes', { ptoId }],
    queryFn: () => getTransactionTypeFilterOptions(ptoId),
  };
  const loadCardIssuer: QueryObject = {
    queryKey: ['cardIssuers', { ptoId }],
    queryFn: () => getCardIssuerFilterOptions(ptoId),
  };
  const loadCardSchema: QueryObject = {
    queryKey: ['cardSchemas', { ptoId }],
    queryFn: () => getCardSchemaFilterOptions(ptoId),
  };
  const loadCardProducts: QueryObject = {
    queryKey: ['cardProducts', { ptoId }],
    queryFn: () => getCardProductsFilterOptions(ptoId),
  };
  const loadLines: QueryObject = {
    queryKey: ['lines', { ptoId }],
    queryFn: ({ queryKey }) => getLinesFilterOptions(ptoId),
  };
  const loadPaymentDevices: QueryObject = {
    queryKey: ['paymentDevices', { ptoId }],
    queryFn: () => getPaymentDevicesFilterOptions(ptoId),
  };
  const loadPaymentTypes: QueryObject = {
    queryKey: ['paymentTypes', { ptoId }],
    queryFn: () => getPaymentTypesFilterOptions(ptoId),
  };
  const loadCardOrigins: QueryObject = {
    queryKey: ['cardOrigins', { ptoId }],
    queryFn: () => getCardOriginFilterOptions(ptoId),
  };
  const loadFunctions: LoadQueries = {
    VEHICLE_TYPE: loadVehicleTypes,
    TRANSACTION_STATUS: loadTransactionStatus,
    TRANSACTION_TYPE: loadTransactionType,
    CARD_ISSUER: loadCardIssuer,
    CARD_SCHEMA: loadCardSchema,
    CARD_ORIGIN: loadCardOrigins,
    CARD_PRODUCT: loadCardProducts,
    LINE: loadLines,
    PAYMENT_DEVICE: loadPaymentDevices,
    PAYMENT_TERMINAL_NAME: loadPaymentTypes,
    CUSTOMER_STATUS: loadCustomerStatuses,
    TOKEN_STATUS: loadTokenTypes,
    PAYMENT_WINDOW_STATUS: loadPayWindowStatuses,
  };

  const callableFunctions: QueryObject[] = [];
  const loadFunctionsAsArray = Object.entries(loadFunctions);
  const filteredLoadFunctions = loadFunctionsAsArray.filter(([key, value]) => {
    return filters.includes(key as FilterTypes);
  });

  filteredLoadFunctions.forEach((keyValuePair) =>
    callableFunctions.push(keyValuePair[1]),
  );

  return {
    callableFunctions,
    queryKeys: callableFunctions.map((func) => func.queryKey[0] as string),
  };
};

// Default state for filter. This can be changed bu customers prefference in future.
const composeDefaultFormState = (filters: FilterProps['filters']) => {
  const defaultState: Record<Partial<FilterTypes>, PossibleFieldValues> = {
    VEHICLE_TYPE: '',
    LINE: '',
    CUSTOMER_NAME: '',
    STATUS: '',
    CUSTOMER_STATUS: '',
    TOKEN_STATUS: '',
    PAYMENT_WINDOW_STATUS: '',
    TOKEN_ID: '',
    DATE_RANGE: [new Date(), new Date()],
    APPROVAL_CODE: '',
    PAN: '',
    AMOUNT_RANGE: [0, 0],
    CURRENCY: '',
    LOCATION: '',
    PAYMENT_TERMINAL_NAME: '',
    TERMINAL_ID: '',
    VARIABLE_SYMBOL: '',
    TRANSACTION_STATUS: '',
    TRANSACTION_TYPE: '',
    TRANSACTION_ID: '',
    TIME_SOURCE: TimeSource['SERVER'],
    CARD_ISSUER: '',
    CARD_SCHEMA: '',
    PAYMENT_DEVICE: '',
    PAYMENT_TYPE: '',
    CARD_ORIGIN: '',
    CARD_PRODUCT: '',
    DATE: dayjs().format('DD/MM/YYYY'),
  };
  const objKeys = Object.keys(defaultState) as FilterTypes[];

  // Create default state only for required filters
  objKeys.forEach(
    (defStateKey) => !filters.includes(defStateKey) && delete defaultState[defStateKey],
  );

  return defaultState;
};

const renderCorrectFilterField = (
  filter: FilterTypes,
  filterLists: FilterOptionsLists,
  field: FieldValues,
) => {
  const { t } = useTranslation('common', { keyPrefix: 'components.filters' });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });

  switch (filter) {
    case 'AMOUNT_RANGE':
      return (
        <AmountField
          {...field}
          label={t(`labels.${filter}`)}
          value={field.value as [number, number]}
          currency="CZK"
        />
      );
    case 'DATE_RANGE':
      return (
        <AppDateRangeSelect
          {...field}
          label={t(`labels.${filter}`)}
          value={isArray(field.value) ? (field.value as DateRange<Date>) : [null, null]}
        />
      );
    case 'DATE':
      return (
        <AppDatePicker
          {...field}
          label={t(`labels.${filter}`)}
          value={
            field?.value && typeof field?.value === 'string'
              ? field.value
              : dayjs().format('DD/MM/YYYY')
          }
        />
      );
    case 'CARD_ISSUER':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseCardIssuer')}
          </MenuItem>
          {filterLists.cardIssuers.map((transStatus, index) => (
            <MenuItem key={index} value={transStatus}>
              {transStatus}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'CARD_ORIGIN':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseCardOrigin')}
          </MenuItem>
          {filterLists.cardOrigins.map((transStatus, index) => (
            <MenuItem key={index} value={transStatus}>
              {tEnums(`cardOrigin.${transStatus.toLowerCase()}`)}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'CARD_PRODUCT':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseCardProduct')}
          </MenuItem>
          {filterLists.cardProducts.map((cardProduct, index) => (
            <MenuItem key={index} value={cardProduct}>
              {tEnums(`cardProduct.${cardProduct.toLowerCase()}`)}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'CARD_SCHEMA':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseCardSchema')}
          </MenuItem>
          {filterLists.cardSchemas.map((transStatus, index) => (
            <MenuItem key={index} value={transStatus}>
              {transStatus}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'TIME_SOURCE':
      return (
        <AppToggleButton
          {...field}
          value={field.value as string}
          label={t(`labels.${filter}`)}
          errorText={''}
          options={Object.entries(TimeSource).map((timeSource) => ({
            value: timeSource[1],
            label: tEnums(`timeSource.${timeSource[1] as string}`),
          }))}
        />
      );
    case 'TRANSACTION_STATUS':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          startAdornment={
            <InputAdornment position="end">
              <CheckCircleIcon />
            </InputAdornment>
          }
          {...field}
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseTransactionStatus')}
          </MenuItem>
          {filterLists.transactionStatuses.map((transStatus, index) => (
            <MenuItem key={index} value={transStatus}>
              {transStatus}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'TRANSACTION_TYPE':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          startAdornment={
            <InputAdornment position="end">
              <SettingsIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseTransactionType')}
          </MenuItem>
          {filterLists.transactionTypes.map((transType, index) => (
            <MenuItem key={index} value={transType}>
              {transType}
            </MenuItem>
          ))}
        </AppSelect>
      );

    // case 'TERMINAL_ID':
    //   return (
    //     <AppSelect displayEmpty label={t(`labels.${filter}`)} {...field}           startAdornment={
    //     <InputAdornment position="end">
    //     <PointOfSaleIcon />
    //   </InputAdornment>
    // }></AppSelect>
    //   );
    // case 'LOCATION':
    //   return (
    //     <AppSelect displayEmpty label={t(`labels.${filter}`)} {...field}></AppSelect>
    //   );
    case 'LINE':
      return (
        <AppSelect displayEmpty label={t(`labels.${filter}`)} {...field}>
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseLine')}
          </MenuItem>
          {filterLists.lines.map((s, index) => (
            <MenuItem key={index} value={s}>
              {s}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'PAYMENT_DEVICE':
      return (
        <AppSelect
          displayEmpty
          label={t(`labels.${filter}`)}
          {...field}
          startAdornment={
            <InputAdornment position="end">
              <CreditCardIcon />
            </InputAdornment>
          }
        >
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.choosePaymentDevice')}
          </MenuItem>
          {filterLists.paymentDevices.map((s, index) => (
            <MenuItem key={index} value={s}>
              {tEnums(`paymentDevice.${s.toLowerCase()}`)}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'PAYMENT_TYPE':
      return (
        <AppSelect displayEmpty label={t(`labels.${filter}`)} {...field}>
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.choosePaymentType')}
          </MenuItem>
          {filterLists.paymentTypes.map((s, index) => (
            <MenuItem key={index} value={s}>
              {tEnums(`paymentType.${s.toLowerCase()}`)}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'PAYMENT_WINDOW_STATUS':
      return (
        <AppSelect displayEmpty label={t(`labels.${filter}`)} {...field}>
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseStatus')}
          </MenuItem>
          {filterLists.paymentWindowStatuses.map((s, index) => (
            <MenuItem key={index} value={s}>
              {tEnums(`paymentWindowStatusFilter.${s.toLowerCase()}`)}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'STATUS':
      return (
        <AppSelect displayEmpty label={t(`labels.${filter}`)} {...field}>
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseStatus')}
          </MenuItem>
          {filterLists.status.map((s, index) => (
            <MenuItem key={index} value={s}>
              {s}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'CUSTOMER_STATUS':
      return (
        <AppSelect displayEmpty label={t(`labels.${filter}`)} {...field}>
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseStatus')}
          </MenuItem>
          {filterLists.customerStatuses.map((s, index) => (
            <MenuItem key={index} value={s}>
              {tEnums(`customerStatusFilter.${s.toLowerCase()}`)}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'TOKEN_STATUS':
      return (
        <AppSelect displayEmpty label={t(`labels.${filter}`)} {...field}>
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseStatus')}
          </MenuItem>
          {filterLists.tokenStatuses.map((s, index) => (
            <MenuItem key={index} value={s}>
              {tEnums(`tokenStatusFilter.${s.toLowerCase()}`)}
            </MenuItem>
          ))}
        </AppSelect>
      );
    case 'VEHICLE_TYPE':
      return (
        <AppSelect displayEmpty label={t(`labels.${filter}`)} {...field}>
          <MenuItem key={-1} value={''}>
            {t('emptyOptions.chooseVehicleType')}
          </MenuItem>
          {filterLists.vehicleTypes.map((vehicleType, index) => (
            <MenuItem key={index} value={vehicleType}>
              {tEnums(`vehicleTypeFilter.${vehicleType.toLowerCase()}`)}
            </MenuItem>
          ))}
        </AppSelect>
      );
    default:
      return <AppTextField label={t(`labels.${filter}`)} {...field} ref={null} />;
  }
};

const findIndexOfQueryKey = (queryKeyArray: QueryKey[], key: string) => {
  console.log(queryKeyArray.flat(), 'queryKeyArray');
  return queryKeyArray.flat().findIndex((value) => value === key);
};

const initialFilterOptionsLists = {
  vehicleTypes: [],
  status: [],
  customerStatuses: [],
  tokenStatuses: [],
  paymentWindowStatuses: [],
  lines: [],
  transactionTypes: [],
  transactionStatuses: [],
  cardIssuers: [],
  cardSchemas: [],
  cardOrigins: [],
  cardProducts: [],
  paymentDevices: [],
  paymentTypes: [],
};

const defaultOptions: UseFiltersOptions = {
  showSaveFilter: false,
};

function useFilters<T>({ filters, advancedFilters, namePairs, options }: FilterProps<T>) {
  const { ptoId } = useAppSelector((state) => state.user);
  const [filterOptionsAreLoading, setFilterOptionsAreLoading] = useState(false);
  const { t } = useTranslation('common', { keyPrefix: 'components.filters' });
  const [isAdvancedFilterCollapsed, setIsAdvancedFilterCollapsed] = useState(true);
  const [isCreateFilterDialogOpen, setIsCreateFilterDialogOpen] = useState(false);
  const { callableFunctions, queryKeys } = loadFilterOptions(
    [...filters, ...(advancedFilters || [])],
    ptoId,
  );
  const filterOptions = useQueries(callableFunctions);
  const [savedFilters, setSavedFilters] = useState<FilterObject[]>([]);

  const closeCreateFilterDialog = () => setIsCreateFilterDialogOpen(false);

  const loadSavedFilters = async () => {
    const data = await getFilters();
    setSavedFilters(data);
  };

  const deleteFilterFunc = async (filterId: number) => {
    const response = await deleteFilter(filterId);
    if (response.status > 199 && response.status < 300) {
      const savedFiltersWithoutDeletedFilter = savedFilters.filter(
        (filter) => filter.id !== filterId,
      );
      setSavedFilters(savedFiltersWithoutDeletedFilter);
    }
  };

  useEffect(() => {
    if (options?.showSaveFilter === true) {
      loadSavedFilters();
    }
  }, []);

  const { control, reset, watch, setValue } = useForm({
    defaultValues: composeDefaultFormState([...filters, ...(advancedFilters || [])]),
  });
  const filterValues = watch();

  useEffect(() => {
    if (filterOptions.some((option) => option.isLoading !== filterOptionsAreLoading)) {
      setFilterOptionsAreLoading(!filterOptionsAreLoading);
    }
  }, [filterOptions]);

  const filterOptionsListsMemoized = useMemo(() => {
    if (filterOptions.some((option) => option.isLoading !== false)) {
      return initialFilterOptionsLists;
    } else {
      const flattenQueryKeys = queryKeys.flat() as (keyof FilterOptionsLists)[];
      const newFilterOptions = { ...initialFilterOptionsLists };
      flattenQueryKeys.map(
        (queryKey) =>
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          (newFilterOptions[queryKey] =
            filterOptions[findIndexOfQueryKey(queryKeys, queryKey)].data),
      );
      return newFilterOptions;
    }
  }, [filterOptions]);

  // If pairs provided, refactor keys of filterValues to be same as in namePairs
  const refactorFilterValues = (
    filterValuesBeforeRefactor: typeof filterValues,
    namePairs: {
      filterName: keyof typeof filterValues;
      queryname: keyof T | (keyof T)[];
    }[],
  ) => {
    const refactoredFilter: Partial<T> = {};

    if (namePairs.length > 0) {
      namePairs.map((namePair) => {
        if (namePair?.queryname && namePair?.filterName) {
          // TODO: Refactor
          if (
            isArray(namePair?.queryname) &&
            namePair?.queryname.length > 0 &&
            isArray(filterValuesBeforeRefactor[namePair.filterName])
          ) {
            namePair?.queryname.map((q, index) => {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              refactoredFilter[q] =
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                filterValuesBeforeRefactor[namePair.filterName][index];
            });
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            refactoredFilter[namePair.queryname] =
              filterValuesBeforeRefactor[namePair.filterName];
          }
        }
      });
      return refactoredFilter;
    } else {
      return filterValuesBeforeRefactor;
    }
  };

  const filtersToRender = (
    <>
      <SaveFilterDialog
        isDialogOpen={isCreateFilterDialogOpen}
        handleClose={closeCreateFilterDialog}
        onSubmit={async (name: string) => {
          const response = await saveFilters(filterValues, name);
          if (response.status > 199 && response.status < 300) {
            const filters = [...savedFilters];
            filters.push(response.data);
            setSavedFilters(filters);
          }
          closeCreateFilterDialog();
        }}
      />
      <Card sx={{ p: [3, 2], marginY: 2 }}>
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between' }}>
          <Typography component="div">{t('title')}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {(options?.showSaveFilter ?? false) && (
              <>
                <Box>
                  <AppButton
                    size="small"
                    startIcon={<SaveIcon />}
                    color="secondary"
                    onClick={() => setIsCreateFilterDialogOpen(true)}
                  >
                    {t('saveFilter')}
                  </AppButton>
                </Box>
                <Box>
                  <AppButton
                    size="small"
                    startIcon={<CancelIcon />}
                    color="secondary"
                    onClick={() => {
                      reset({}, { keepDefaultValues: true });
                    }}
                  >
                    {t('reset')}
                  </AppButton>
                </Box>
                <AppSelect size="small" selectVariant="asButton">
                  {savedFilters.length > 0 ? (
                    savedFilters.map((savedFilter, index) => (
                      <MenuItem
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                        onClick={() => {
                          Object.entries(savedFilter.filter).forEach((entry) =>
                            setValue(entry[0] as FilterTypes, entry[1]),
                          );
                        }}
                        key={index}
                      >
                        {`Filter ${savedFilter.id}: ${savedFilter.name}`}
                        <IconButton
                          onClick={async (e) => {
                            e.stopPropagation();
                            deleteFilterFunc(savedFilter.id);
                          }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>{t('noSavedFilters')}</MenuItem>
                  )}
                </AppSelect>
              </>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: '1fr',
            columnGap: 1,
            rowGap: 1,
          }}
        >
          {filters.map((filter, index) => {
            return (
              <Box key={index}>
                <Controller
                  control={control}
                  name={filter}
                  render={({ field, formState }) =>
                    renderCorrectFilterField(filter, filterOptionsListsMemoized, field)
                  }
                />
              </Box>
            );
          })}
        </Box>
        {advancedFilters && (
          <div>
            <Typography
              component={'span'}
              sx={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                marginY: 2,
              }}
              onClick={() => setIsAdvancedFilterCollapsed(!isAdvancedFilterCollapsed)}
            >
              <KeyboardArrowDownIcon
                sx={{
                  transition: '0.45s ease',
                  transform: isAdvancedFilterCollapsed
                    ? 'rotate(0deg)'
                    : 'rotate(-180deg)',
                }}
              />{' '}
              <Typography component={'span'}>{t('advancedFiltering')}</Typography>
            </Typography>
          </div>
        )}
        <Collapse in={!isAdvancedFilterCollapsed}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gridTemplateRows: '1fr',
              columnGap: 1,
              rowGap: 1,
            }}
          >
            {advancedFilters &&
              advancedFilters.map((advancedFilter, index) => {
                return (
                  <Box key={index}>
                    <Controller
                      control={control}
                      name={advancedFilter}
                      render={({ field }) =>
                        renderCorrectFilterField(
                          advancedFilter,
                          filterOptionsListsMemoized,
                          field,
                        )
                      }
                    />
                  </Box>
                );
              })}
          </Box>
        </Collapse>
      </Card>
    </>
  );
  return {
    filters: filtersToRender,
    filterValues: namePairs
      ? refactorFilterValues(filterValues, namePairs)
      : filterValues,
  };
}

export default useFilters;
