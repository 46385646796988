const myStorage = window.localStorage;

interface LoadFromLocalStorageParams {
  key: string;
  value: any;
}
interface SaveToLocalStorageParams {
  key: string;
}

const saveToLocalStorage = ({ key, value }: LoadFromLocalStorageParams) =>
  myStorage.setItem(key, value);

const loadFromLocalStorage = ({ key }: SaveToLocalStorageParams) =>
  myStorage.getItem(key);

const removeFromLocalStorage = ({ key }: SaveToLocalStorageParams) =>
  myStorage.removeItem(key);

export { saveToLocalStorage, loadFromLocalStorage, removeFromLocalStorage };
