import { MenuItem, Box, Typography, styled } from '@mui/material';
import { TimePeriod, useTapsOverviewQuery } from 'api/endpoints/taps';
import { AppDataContainer } from 'components/appDataContainer';
import { DataGraphContainer } from 'components/appDataContainer/dataGraphContainer';
import { SimpleAppSelect } from 'components/form/select/simpleAppSelect';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'stores/store';
import { DAYS_OPTIONS } from 'utils/const/enums';
import { formatDateToDay } from 'utils/funcs/formatDate';

const ImportantDataBox = styled('div')(({ theme: { spacing } }) => ({
  marginBottom: spacing(3),
}));

const ImportantDataBoxSmallText = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.text.lightGrey.main,
}));

const ImportantDataBoxLargeText = styled(Typography)(({ theme: { typography } }) => ({
  ...typography.h3,
}));

interface TapsOverviewProps {
  terminalId: number[];
}

export const TapsOverview: React.FC<TapsOverviewProps> = ({ terminalId }) => {
  const ptoId = useAppSelector((state) => state.user.ptoId);
  const [days, setDays] = useState(DAYS_OPTIONS[DAYS_OPTIONS.length - 1].value);
  const { t } = useTranslation('common', { keyPrefix: 'components.tapsOverview' });
  const { t: tEnums } = useTranslation('common');
  const { data } = useTapsOverviewQuery(ptoId, days, terminalId);

  return (
    <AppDataContainer
      titleRow={{
        title: t('title'),
        buttons: [
          <SimpleAppSelect
            onChange={(e) => setDays(e.target.value as TimePeriod)}
            value={days}
            key="selectPeriod"
          >
            {DAYS_OPTIONS.map((option, index) => (
              <MenuItem key={index} value={option.value}>
                {tEnums(`${option.label}`)}
              </MenuItem>
            ))}
          </SimpleAppSelect>,
        ],
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: 3,
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ marginY: 'auto', flex: 1, display: 'flex', flexDirection: 'column' }}>
          <ImportantDataBox>
            <ImportantDataBoxLargeText>
              {data?.summary.all_taps}
            </ImportantDataBoxLargeText>
            <ImportantDataBoxSmallText> {t('taps')}</ImportantDataBoxSmallText>
          </ImportantDataBox>
          <ImportantDataBox>
            <ImportantDataBoxLargeText>
              {data?.summary.customers}
            </ImportantDataBoxLargeText>
            <ImportantDataBoxSmallText>{t('customers')}</ImportantDataBoxSmallText>
          </ImportantDataBox>
        </Box>
        <Box sx={{ width: '400px' }}>
          {data?.graph && data?.graph.length > 0 && (
            <DataGraphContainer
              dataBoxes={data.graph.map((graphItem) => ({
                value: graphItem.tap_count,
                label: formatDateToDay(graphItem.day, 'DD.MM.YYYY'),
              }))}
            />
          )}
        </Box>
      </Box>
    </AppDataContainer>
  );
};
