import { AppDataContainer } from 'components/appDataContainer';
import { AppBreadcrumbs } from 'components/breadcrumbs/BreadCrumbs';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { OneColumnContainer } from 'components/appDataContainer/oneColumnContainer';
import { Box } from '@mui/material';
import { useTerminalDetailQuery } from 'api/endpoints/terminals';
import { AppStatusHighlight } from 'components/appStatusHighlight';
import { DataObj, TranslateFunc } from 'components/appDataContainer/utility';
import MapWithMarkers from 'components/maps/MapWithMarkers';
import { TapsOverview } from 'components/tapsOverview';
import { useAppSelector } from 'stores/store';
import { CustomerStatus } from 'types/enumTypes';

const customRenderFunctions = (translateFunc: TranslateFunc) => ({
  status: (key: string) => (
    <AppStatusHighlight status={key as CustomerStatus}>
      {translateFunc(`customerStatus.${key}`)}
    </AppStatusHighlight>
  ),
  gps: (key: string, data: DataObj) => <>{`${data?.gps?.lat}, ${data?.gps?.lng}`}</>,
});

export const TerminalDetailPage: React.FC = () => {
  const ptoId = useAppSelector((state) => state.user.ptoId);
  const { id: terminalId } = useParams();
  const { t } = useTranslation('common', { keyPrefix: 'pages.terminalDetailPage' });
  const { t: tEnums } = useTranslation('common', { keyPrefix: 'enums' });
  const { data } = useTerminalDetailQuery(ptoId, terminalId || '');

  const markers = useMemo(() => {
    if (data?.gps) {
      return [{ lat: parseFloat(data?.gps.lat), lng: parseFloat(data?.gps.lng) }];
    }
    return [];
  }, [data?.gps]);
  return (
    <div>
      <Box sx={{ marginBottom: 2 }}>
        <AppBreadcrumbs />
      </Box>
      <AppDataContainer
        sx={{ marginBottom: 3 }}
        titleRow={{
          title: t('pageTitle'),
          // NOT NEEDED RIGTH NOW
          // buttons: [
          //   <AppButton
          //     variant="contained"
          //     color="error"
          //     size="small"
          //     startIcon={<DeleteIcon />}
          //     key={0}
          //   >
          //     {t('deleteTerminal')}
          //   </AppButton>,
          //   <AppButton
          //     variant="contained"
          //     color="secondary"
          //     size="small"
          //     startIcon={<EditIcon />}
          //     key={1}
          //   >
          //     {t('editTerminal')}
          //   </AppButton>,
          // ],
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <OneColumnContainer
            renderData={customRenderFunctions(tEnums)}
            columns={[
              ['id', 'status', 'type', 'location', 'address', 'gps', 'description'],
            ]}
            dataObj={data ?? {}}
            translateFunc={(k: string): string => {
              return t(k);
            }}
          />
          {markers.length > 0 && <MapWithMarkers center={markers[0]} markers={markers} />}
        </Box>
      </AppDataContainer>
      <TapsOverview terminalId={terminalId ? [parseInt(terminalId)] : []} />
    </div>
  );
};
